import { Box, Stack } from "@mui/material";
import { type ReactNode } from "react";
import Logo from "./Logo";

export default function Global({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          minWidth: ["calc(100% - 2rem)", "15rem"],
          maxWidth: ["calc(100% - 2rem)", "auto"],
          height: ["calc(100% - 2rem)", "auto"],
          p: 2,
        }}
      >
        <Stack spacing={2} alignItems="center" sx={{ pt: 2 }}>
          <Box
            component={Logo}
            sx={{ fontSize: "7rem", color: "primary.900", width: "600px"}}
          />
          {children}
        </Stack>
      </Box>
    </Box>
  );
}
