/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Abladestelle } from '../models/Abladestelle';
import type { AbladestelleDaten } from '../models/AbladestelleDaten';
import type { AbladestellenListe } from '../models/AbladestellenListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AbladestellenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Abladestellen.
   * @returns AbladestellenListe Die Seite der Abladestellen.
   * @throws ApiError
   */
  public sucheAbladestellen({
    search,
    page,
    limit,
    sort,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<AbladestellenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/abladestellen',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Abladestelle Die angelegte Abladestelle
   * @throws ApiError
   */
  public createAbladestelle({
    requestBody,
  }: {
    requestBody: AbladestelleDaten,
  }): CancelablePromise<Abladestelle> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/abladestellen',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get Abladestelle by ID
   * Retrieves the details of an Abladestelle by its ID
   * @returns Abladestelle Die Abladestelle
   * @throws ApiError
   */
  public getAbladestelle({
    id,
  }: {
    id: string,
  }): CancelablePromise<Abladestelle> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/abladestellen/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Update Abladestelle by ID
   * Updates an existing Abladestelle by its ID
   * @returns Abladestelle Die aktualisierte Abladestelle
   * @throws ApiError
   */
  public updateAbladestelle({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: AbladestelleDaten,
  }): CancelablePromise<Abladestelle> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/abladestellen/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Delete Abladestelle by ID
   * Deletes an existing Abladestelle by its ID
   * @returns Abladestelle Die gelöschte Abladestelle
   * @throws ApiError
   */
  public deleteAbladestelle({
    id,
  }: {
    id: string,
  }): CancelablePromise<Abladestelle> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/abladestellen/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
