// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type AuthUrl } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useDatevAuthUrl(): (state: string) => Promise<AuthUrl> {
  const getAPI = useGetApi();
  return useCallback(
    async (state) => (await getAPI()).externesysteme.datevAuthUrl({ state }),
    [getAPI]
  );
}
