import {
  Box,
  Button,
  capitalize,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import DataTable from "../data/DataTable";
import { useGetApi } from "../../client";
import { useSucheBaustellen, useSucheBenutzer } from "../../client/queries";

export type TaetigkeitsberichtFilter = {
  search?: string;
};

export type Taetigkeitsbericht = {
  id: string;
  mitarbeiterId: string;
  baustelleId: string;
  datum: string;
  arbeitszeitNetto?: number;
  bemerkung?: string;
};

export type Props = Omit<DataRequest<TaetigkeitsberichtFilter>, "filter"> &
  Partial<Pick<DataRequest<TaetigkeitsberichtFilter>, "filter">>;

export default function TaetigkeitsberichtDataTable({ ...input }: Props) {
  const request = useDataRequest<TaetigkeitsberichtFilter>({
    filter: {},
    ...input,
  });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <TaetigkeitsberichtResults {...request} />
    </Stack>
  );
}

function FilterComp(request: DataRequestState<TaetigkeitsberichtFilter>) {
  const { t } = useT("taetigkeitsbericht");
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neuer-taetigkeitsbericht");
            }}
          >
            {capitalize(t("create-activity-report"))}
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neuer-taetigkeitsbericht");
            }}
          >
            {capitalize(t("create-activity-report"))}
          </Button>
        </>
      )}
    </Box>
  );
}

function TaetigkeitsberichtResults({
  ...request
}: DataRequestState<TaetigkeitsberichtFilter>) {
  return <Results request={request} />;
}
function Results({
  request,
}: {
  request: DataRequestState<TaetigkeitsberichtFilter>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const ladeTaetigkeitsberichte: DataLoader<
    TaetigkeitsberichtFilter,
    Taetigkeitsbericht
  > = useCallback(
    async (params) =>
      (await getApi()).taetigkeitsberichte.sucheTaetigkeitsberichte({
        ...params,
      }),
    [getApi]
  );

  return (
    <DataTable
      columns={useColumns()}
      request={request}
      queryKey={["taetigkeitsberichte"]} //TODO: Richtigen QueryKey verwenden
      loadData={ladeTaetigkeitsberichte}
      onRowClick={(row) => {
        navigate("/taetigkeitsberichte/" + row.id);
      }}
    />
  );
}
function useColumns(): Array<GridColDef<Taetigkeitsbericht>> {
  const { t, capitalize } = useT("taetigkeitsbericht");
  const baustellen = useSucheBaustellen();
  const benutzer = useSucheBenutzer();

  function bekommeMitarbeiterNamen(benutzerId: string) {
    const user = benutzer.items.find(
      (mitarbeiter) => mitarbeiter.id === benutzerId
    );
    if (user) {
      return [user.name, user.vorname].filter(Boolean).join(", ");
    }
    return "";
  }

  function bekommeBaustellenNamen(baustelleId: string) {
    const baustelle = baustellen.items.find(
      (baustelle) => baustelle.id === baustelleId
    );
    if (baustelle) {
      return baustelle.name;
    }
    return "";
  }

  return useMemo(
    () => [
      {
        field: "mitarbeiterId",
        headerName: capitalize(t("activity-report-user")),
        renderCell: (params) => (
          <>{bekommeMitarbeiterNamen(params.row.mitarbeiterId)}</>
        ),
        flex: 1,
      },
      {
        field: "baustelleId",
        headerName: capitalize(t("activity-report-construction")),
        renderCell: (params) => (
          <>{bekommeBaustellenNamen(params.row.baustelleId)}</>
        ),
        flex: 1,
      },
      {
        field: "datum",
        headerName: capitalize(t("activity-report-date")),
        flex: 1,
      },
      {
        field: "arbeitszeitNetto",
        headerName: capitalize(t("activity-report-time-net")),
        flex: 1,
      },
      {
        field: "bemerkung",
        headerName: capitalize(t("activity-report-remark")),
        flex: 1,
      },
    ],
    [t, capitalize]
  );
}
