// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Rechnung } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useDeleteRechnung(): (rechnungId: string) => Promise<Rechnung> {
  const getAPI = useGetApi();
  return useCallback(
    async (rechnungId) =>
      (await getAPI()).rechnungen.deleteRechnung({ rechnungId }),
    [getAPI]
  );
}
