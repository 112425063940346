/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Benutzer } from '../models/Benutzer';
import type { BenutzerDaten } from '../models/BenutzerDaten';
import type { BenutzerListe } from '../models/BenutzerListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BenutzerService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns BenutzerListe Liste der Benutzer
   * @throws ApiError
   */
  public sucheBenutzer({
    search,
    page,
    limit,
    sort,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<BenutzerListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/benutzer',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Benutzer der angelegte Benutzer
   * @throws ApiError
   */
  public createBenutzer({
    requestBody,
  }: {
    requestBody: BenutzerDaten,
  }): CancelablePromise<Benutzer> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/benutzer',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Benutzer Benutzer
   * @throws ApiError
   */
  public getBenutzer({
    benutzerId,
  }: {
    benutzerId: string,
  }): CancelablePromise<Benutzer> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/benutzer/{benutzerId}',
      path: {
        'benutzerId': benutzerId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Benutzer der aktualisierte Benutzer
   * @throws ApiError
   */
  public updateBenutzer({
    benutzerId,
    requestBody,
  }: {
    benutzerId: string,
    requestBody: BenutzerDaten,
  }): CancelablePromise<Benutzer> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/benutzer/{benutzerId}',
      path: {
        'benutzerId': benutzerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Benutzer der gelöschte Benutzer
   * @throws ApiError
   */
  public deleteBenutzer({
    benutzerId,
  }: {
    benutzerId: string,
  }): CancelablePromise<Benutzer> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/benutzer/{benutzerId}',
      path: {
        'benutzerId': benutzerId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
