import {
    Box,
    Button,
    Stack, useMediaQuery, useTheme,
} from "@mui/material";
import {useCallback, useMemo} from "react";
import { useNavigate } from "react-router";
import {GridColDef} from "@mui/x-data-grid";
import {DataLoader, DataRequest, DataRequestState, useDataRequest, useDebouncedFilter} from "../data";
import {useT} from "../../i18n";
import SearchField from "../SearchField";
import DataTable from "../data/DataTable";
import {useGetApi} from "../../client";
import {Benutzer} from "../../api";

export type BenutzerFilter = {
    search?: string;
};


export type Props = Omit<
    DataRequest<BenutzerFilter>,
    "filter"
> &
    Partial<Pick<DataRequest<BenutzerFilter>, "filter">>;

export default function BenutzerDataTable({
                                               ...input
                                           }: Props) {
    const request = useDataRequest<BenutzerFilter>({ filter: {}, ...input });

    return (
        <Stack spacing={2}>
            <FilterComp {...request} />
            <BenutzerResults {...request} />
        </Stack>
    );
}

function FilterComp(request: DataRequestState<BenutzerFilter>) {
    const [{ search }, setField] =
        useDebouncedFilter(request);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%' }}>
            {isMobile ?
            <Stack direction="row">
                <SearchField
                    value={search ?? ""}
                    onChange={(s) => setField("search", s)}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginLeft: 2}}
                    onClick={()=>{navigate('/neu-benutzer')}}
                >
                    Benutzer anlegen
                </Button>
            </Stack> :
                <>
                    <SearchField
                        value={search ?? ""}
                        onChange={(s) => setField("search", s)}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                        onClick={()=>{navigate('/neu-benutzer')}}
                    >
                        Benutzer anlegen
                    </Button>
                </>
             }
        </Box>
    );
}

function BenutzerResults({
                             ...request
                         }: DataRequestState<BenutzerFilter>) {
    return <Results request={request} />;
}
function Results({
                     request,
                 }: {
    request: DataRequestState<BenutzerFilter>;
}) {
    const navigate = useNavigate();
    const getApi = useGetApi();
    const loadBenutzer: DataLoader<BenutzerFilter, Benutzer> = useCallback(
        async (params) =>
            (await getApi()).benutzer.sucheBenutzer({ ...params }),
        [getApi]
    );
    return (
        <DataTable
            columns={useColumns()}
            request={request}
            queryKey={["benutzer"]} //TODO: Richtigen QueryKey verwenden
            loadData={loadBenutzer}
            onRowClick={((row)=>{navigate('/benutzer/' + row.id)})}
        />
    );
}
function useColumns(): Array<GridColDef<Benutzer>> {
    const { t } = useT("customer");

    return useMemo(
        () => [
            {
                field: "personalNummer",
                headerName: t("number"),
            },
            {
                field: "name",
                headerName: t("name"),
                flex: 1
            },
            {
                field: "vorname",
                headerName: t("firstname"),
                flex: 1
            },
            {
                field: "email",
                headerName: t("email"),
                flex: 1,
            },
            {
                field: "strasse",
                headerName: t("street"),
                flex: 1,
            },
            {
                field: "plz",
                headerName: t("plz"),
                flex: 1,
            },
            {
                field: "ort",
                headerName: t("city"),
                flex: 1,
            }
        ],
        [t]
    );
}