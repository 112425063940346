/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FuhrparkeintragAnbauteil } from '../models/FuhrparkeintragAnbauteil';
import type { FuhrparkeintragAnbauteilDaten } from '../models/FuhrparkeintragAnbauteilDaten';
import type { FuhrparkeintragAnbauteilPage } from '../models/FuhrparkeintragAnbauteilPage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FuhrparkeintraganbauteileService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Fuhrparkeintrag Anbauteile zu einem bestimmten Fuhrparkeintrag.
   * @returns FuhrparkeintragAnbauteilPage Die Seite der Fuhrparkeintrag Anbauteile.
   * @throws ApiError
   */
  public sucheFuhrparkeintragAnbauteil({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    search,
    page,
    limit,
    sort,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<FuhrparkeintragAnbauteilPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/fuhrparkeintraganbauteil',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns FuhrparkeintragAnbauteil das angelegte Fuhrparkeintrag Anbauteil
   * @throws ApiError
   */
  public createFuhrparkeintragAnbauteil({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    requestBody: FuhrparkeintragAnbauteilDaten,
  }): CancelablePromise<FuhrparkeintragAnbauteil> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/fuhrparkeintraganbauteil',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Bekomme ein FuhrparkeintragAnbauteil
   * @returns FuhrparkeintragAnbauteil Das FuhrparkeintragAnbauteil
   * @throws ApiError
   */
  public getFuhrparkeintragAnbauteil({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    fuhrparkeintragAnbauteilId,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    fuhrparkeintragAnbauteilId: string,
  }): CancelablePromise<FuhrparkeintragAnbauteil> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/fuhrparkeintraganbauteil/{fuhrparkeintragAnbauteilId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
        'fuhrparkeintragAnbauteilId': fuhrparkeintragAnbauteilId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Aktualisiere einen FuhrparkeintragAnbauteil
   * @returns FuhrparkeintragAnbauteil Das aktualisierte Fuhrparkeintrag Anbauteil
   * @throws ApiError
   */
  public updateFuhrparkeintragAnbauteil({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    fuhrparkeintragAnbauteilId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    fuhrparkeintragAnbauteilId: string,
    requestBody: FuhrparkeintragAnbauteilDaten,
  }): CancelablePromise<FuhrparkeintragAnbauteil> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/fuhrparkeintraganbauteil/{fuhrparkeintragAnbauteilId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
        'fuhrparkeintragAnbauteilId': fuhrparkeintragAnbauteilId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns FuhrparkeintragAnbauteil das gelöschte Fuhrparkeintrag Anbauteil
   * @throws ApiError
   */
  public deleteFuhrparkeintragAnbauteil({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    fuhrparkeintragAnbauteilId,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    fuhrparkeintragAnbauteilId: string,
  }): CancelablePromise<FuhrparkeintragAnbauteil> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/fuhrparkeintraganbauteil/{fuhrparkeintragAnbauteilId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
        'fuhrparkeintragAnbauteilId': fuhrparkeintragAnbauteilId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
