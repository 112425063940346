import { Form, Formik, FormikHelpers} from "formik";
import Layout from "../../components/Layout";
import {capitalize, DialogActions, DialogContent, Paper, Typography} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import {useTranslation} from "react-i18next";
import {useMutation, useQueryClient} from "react-query";
import {useCreateAusgangsrechnung} from "../../client/hooks";
import {AusgangsrechnungDaten} from "../../api";
import ErtraegeFieldsContent from "../../components/ausgangsrechnungen/AusgangsrechnungenFieldsContent";

export default function ErstelleAusgangsrechnung() {
    const { t } = useTranslation("ausgangsrechnung");
    const {mutateAsync} = useSaveAusgangsrechnung();
    let schema = yup.object().shape({
        baustellenId: yup.string(),
        kunde: yup.object({ id: yup.string(),name:yup.string()}),
        rechnungsNummer: yup.string(),
        buchungsart: yup.string(),
        bemerkung: yup.string(),
        lfsNr: yup.string(),
        faelligkeitsDatum: yup.string(),
        gesamtPreis: yup
            .string()
            .test("is-decimal", "Invalid saldo", (value) => {
                if (!value) return true; // Allow empty value
                const parsedValue = parseFloat(value.replace(",", "."));
                return !isNaN(parsedValue) && parsedValue >= 0;
            }),
    });
    const navigate = useNavigate();
    const onSubmit = useCallback(
        async (
            values: {
                faelligkeitsDatum: string,
                rechnungsNummer: string,
                buchungsart:string,
                lfsNr:string,
                bemerkung: string,
                baustellenId:string,
                kunde: { id: string },
                gesamtPreis: string},
            formikHelpers: FormikHelpers<{
                faelligkeitsDatum: string,
                rechnungsNummer: string,
                buchungsart:string,
                lfsNr:string,
                bemerkung: string,
                baustellenId:string,
                kunde: { id: string, name:string },
                gesamtPreis: string}>
        ) => {
            const { setSubmitting, setStatus } = formikHelpers;
            let gesamtPreis = values.gesamtPreis
                ? parseFloat(values.gesamtPreis.replace(",", "."))
                : 0.0;
            let datum = values.faelligkeitsDatum ? values.faelligkeitsDatum+"T00:00:00Z" : "";
            const ausgangsrechnung = await mutateAsync({
                baustellenId: values.baustellenId,
                kundenId: values.kunde.id,
                gesamtPreis: gesamtPreis,
                rechnungsNummer:values.rechnungsNummer,
                buchungsart:values.buchungsart,
                faelligkeitsDatum:datum,
                lfsNr:values.lfsNr,
                bemerkung:values.bemerkung,
                art:"AR"});
            navigate(`/ausgangsrechnungen`);
            setStatus(undefined);
            try {
            } catch (error: any) {
                setStatus(error.message);
            } finally {
                setSubmitting(false);
            }
        },
        [navigate,mutateAsync]
    );
    const getCurrentDate = () => {
        const date = new Date();
        return date.toISOString().split('T')[0];
    };
    return (
        <Layout title={t("outgoing-invoice")} back="/ausgangsrechnungen" >
            <Paper elevation={1} sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}>
                <Formik
                    initialValues={{
                        faelligkeitsDatum: getCurrentDate(),
                        lfsNr:"",
                        rechnungsNummer: "",
                        baustellenId:"",
                        kunde: { id: "", name: "" },
                        gesamtPreis: "",
                        buchungsart:"Ertrag",
                        bemerkung:""
                    }}
                    validationSchema={schema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, isValidating, isValid, dirty }) => (
                    <>
                        <Typography variant="h5" >{t("outgoing-invoice")}</Typography>
                        <DialogContent>
                            <Form id="new-ausgangsrechnung">
                                <ErtraegeFieldsContent buchungsart={"Ertrag"}/>
                            </Form>
                        </DialogContent>
                        <DialogActions>
                            <SubmitButton
                                form="new-ausgangsrechnung"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                loading={isSubmitting}
                                disabled={isValidating || !isValid ||!dirty}
                            >
                                {capitalize(t("save"))}
                            </SubmitButton>
                        </DialogActions>
                    </>
                    )}
                </Formik>
            </Paper>
        </Layout>
    );
}
function useSaveAusgangsrechnung() {
    const createAusgangsrechnung = useCreateAusgangsrechnung();
    const queryClient = useQueryClient();

    return useMutation(
        (input:AusgangsrechnungDaten) => {
            return createAusgangsrechnung(input);
        },
        {
            onSuccess: async () => {
                queryClient.invalidateQueries([
                    "ausgangsrechnungen",
                ]);
            },
        }
    );
}