// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ApiError, type EntsorgerInfo } from "../../api";
import { useGetEntsorger as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useEntsorger(
  entsorgerId: string,
  options: Omit<
    UseQueryOptions<EntsorgerInfo, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): EntsorgerInfo {
  const getter = useGetter();
  return useQuery(
    ["entsorger", entsorgerId],
    () => getter(entsorgerId),
    options
  ).data!;
}
