import {
  Box,
  Button,
  capitalize,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { FastField, FieldArray, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../i18n";
import FahrzeugSelect from "../../fuhrpark/fahrzeug/FahrzeugSelect";
import React, { useEffect, useState } from "react";
import { FuhrparkeintragAnbauteil, HerkunftTyp } from "../../../api";
import { useGetFahrzeugKostenFuerDatum } from "../../../client/hooks";
import AddIcon from "@mui/icons-material/Add";
import FuhrparkeintragAnbauteileFieldsContent from "./fuhrparkeintraganbauteile/FuhrparkeintragAnbauteileFieldsContent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { boolean } from "yup";

interface ExtendedTaetigkeitsberichtDaten {
  mitarbeiterId: string;
  baustelleId: string;
  datum: string;
  arbeitszeitNetto: number;
  stundensatz: number;
  lohnkosten: number;
  bemerkung: string;
  fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
}

interface ExtendedFuhrparkeintragDaten {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeugId: string;
  fahrzeugHerkunft: string;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: FuhrparkeintragAnbauteil[];
}

export default function FuhrparkeintragFieldsContent({
  isNew,
  index,
  fahrzeugHerkunft,
  taetigkeitsberichtDatum,
  dauerVorhanden,
  isValidating,
  isValid,
  fuhrparkeintrag,
}: {
  isNew: boolean;
  index: number;
  fahrzeugHerkunft: HerkunftTyp;
  taetigkeitsberichtDatum?: string;
  dauerVorhanden: number;
  isValidating: boolean;
  isValid: boolean;
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
}) {
  const { t } = useT("taetigkeitsbericht");

  const { setFieldValue } = useFormikContext();

  const [anchorElFahrzeuganlegenInfo, setAnchorElFahrzeuganlegenInfo] =
    React.useState<HTMLElement | null>(null);
  const handlePopoverOpenFahrzeuganlegenInfo = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElFahrzeuganlegenInfo(event.currentTarget);
  };

  const handlePopoverCloseFahrzeuganlegenInfo = () => {
    setAnchorElFahrzeuganlegenInfo(null);
  };

  const openFahrzeuganlegenInfo = Boolean(anchorElFahrzeuganlegenInfo);

  const bekommeFahrzeugKosten = useGetFahrzeugKostenFuerDatum();

  const [ueberstiegeneNutzungsdauer, setUeberstiegeneNutzungsdauer] =
    useState<boolean>(false);

  const [fahrzeugIdField] = useField(`fuhrparkeintraege.${index}.fahrzeugId`);
  const [fahrzeugNutzungsdauerField] = useField(
    `fuhrparkeintraege.${index}.fahrzeugNutzungsdauer`
  );
  const [fahrzeugStundenpreisField] = useField(
    `fuhrparkeintraege.${index}.fahrzeugStundenPreis`
  );

  const calculateFahrzeugkosten = () => {
    setFieldValue(
      `fuhrparkeintraege.${index}.fahrzeugKosten`,
      (
        fahrzeugNutzungsdauerField.value * fahrzeugStundenpreisField.value
      ).toFixed(2)
    );
  };

  const calculateGesamtKosten = () => {
    var anbauteilGesamt = 0;
    fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
      (fuhrparkeintragAnbauteil) => {
        anbauteilGesamt += Number(fuhrparkeintragAnbauteil.anbauteilKosten);
      }
    );

    var fahrzeugGesamt =
      fahrzeugNutzungsdauerField.value * fahrzeugStundenpreisField.value;
    setFieldValue(
      `fuhrparkeintraege.${index}.anbauteilGesamtKosten`,
      anbauteilGesamt.toFixed(2)
    );
    setFieldValue(
      `fuhrparkeintraege.${index}.fuhrparkGesamtKosten`,
      (fahrzeugGesamt + anbauteilGesamt).toFixed(2)
    );
  };

  useEffect(() => {
    calculateFahrzeugkosten();
    calculateGesamtKosten();
  }, [
    fahrzeugNutzungsdauerField.value,
    fahrzeugStundenpreisField.value,
    fuhrparkeintrag.fuhrparkeintragAnbauteile,
  ]);

  useEffect(() => {
    var gesamtAnbauteileNutzungsdauer = 0;
    fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
      (fuhrparkeintragAnbauteil) => {
        gesamtAnbauteileNutzungsdauer +=
          fuhrparkeintragAnbauteil.anbauteilNutzungsdauer;
      }
    );
    setUeberstiegeneNutzungsdauer(
      fahrzeugNutzungsdauerField.value < gesamtAnbauteileNutzungsdauer
    );
  }, [
    fahrzeugNutzungsdauerField.value,
    fuhrparkeintrag.fuhrparkeintragAnbauteile,
  ]);

  useEffect(() => {
    const fetchAndSetFahrzeugStundenPreis = async () => {
      if (fahrzeugIdField.value && isNew) {
        try {
          if (taetigkeitsberichtDatum) {
            taetigkeitsberichtDatum = new Date(
              taetigkeitsberichtDatum
            ).toISOString();
          } else {
            taetigkeitsberichtDatum = new Date().toISOString();
          }

          const fahrzeugStundenPreis = await bekommeFahrzeugKosten(
            fahrzeugIdField.value,
            { date: taetigkeitsberichtDatum }
          );

          setFieldValue(
            `fuhrparkeintraege.${index}.fahrzeugStundenPreis`,
            fahrzeugStundenPreis.kostenStunde.toFixed(2)
          );
        } catch (error) {
          setFieldValue(`fuhrparkeintraege.${index}.fahrzeugStundenPreis`, 0);
        }
      }
    };

    fetchAndSetFahrzeugStundenPreis();

    if (!fahrzeugIdField.value && isNew && dauerVorhanden) {
      setFieldValue(
        `fuhrparkeintraege.${index}.fahrzeugNutzungsdauer`,
        dauerVorhanden
      );
    }
  }, [fahrzeugIdField.value, index, setFieldValue]);

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 5.5fr .5fr 1fr 2fr 2fr 2fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-duration"))}
          name={`fuhrparkeintraege.${index}.fahrzeugNutzungsdauer`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          required
        />
        <FastField
          component={FahrzeugSelect}
          name={`fuhrparkeintraege.${index}.fahrzeugId`}
          label={capitalize(t("carpool-entry-vehicle"))}
          required
          fahrzeugHerkunft={fahrzeugHerkunft}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => window.open("/neues-fahrzeug")}
          aria-owns={openFahrzeuganlegenInfo ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpenFahrzeuganlegenInfo}
          onMouseLeave={handlePopoverCloseFahrzeuganlegenInfo}
        >
          <AddIcon />
        </Button>
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: "none" }}
          open={openFahrzeuganlegenInfo}
          anchorEl={anchorElFahrzeuganlegenInfo}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverCloseFahrzeuganlegenInfo}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>
            {capitalize(t("create-vehicle-info"))}
          </Typography>
        </Popover>
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-price"))}
          name={`fuhrparkeintraege.${index}.fahrzeugStundenPreis`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          required
        />
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-vehicle-costs"))}
          name={`fuhrparkeintraege.${index}.fahrzeugKosten`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          disabled={true}
        />
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-attachment-part-total-costs"))}
          name={`fuhrparkeintraege.${index}.anbauteilGesamtKosten`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          disabled={true}
        />
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-vehicle-total-costs"))}
          name={`fuhrparkeintraege.${index}.fuhrparkGesamtKosten`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          disabled={true}
        />
      </Box>

      {ueberstiegeneNutzungsdauer ? (
        <Box>
          <Typography color={"#cc6600"} sx={{ fontSize: "10pt" }}>
            {capitalize(t("warning-attachment-usage-over-carpool"))}
          </Typography>
        </Box>
      ) : (
        <></>
      )}

      <FieldArray name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile`}>
        {({ push, remove }) => (
          <>
            {fuhrparkeintrag.fuhrparkeintragAnbauteile.length === 0 ? (
              <Box
                display={"grid"}
                sx={{
                  gridTemplateColumns: ["1fr", "1fr"],
                  gap: 2,
                  marginTop: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    push({
                      anbauteilId: "",
                      anbauteilNutzungsdauer: 0,
                      anbauteilAnzahl: 1,
                      anbauteilStundenPreis: 0,
                    });
                  }}
                  disabled={!fuhrparkeintrag.fahrzeugId}
                >
                  <Typography sx={{ p: 0.5 }}>
                    {capitalize(t("create-attachment-part-info"))}
                  </Typography>
                  <AddCircleIcon />
                </Button>
              </Box>
            ) : (
              <></>
            )}
            {fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
              (fuhrparkeintragAnbauteil, indexAnbauteil) => (
                <Box key={indexAnbauteil} sx={{ marginTop: 2 }}>
                  <Box
                    display={"grid"}
                    sx={{
                      gridTemplateColumns: ["1fr", "10fr .1fr"],
                      gap: 2,
                    }}
                  >
                    <Box
                      id={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${indexAnbauteil}`}
                    >
                      <FuhrparkeintragAnbauteileFieldsContent
                        dauerVorhanden={dauerVorhanden}
                        fahrzeugId={fuhrparkeintrag.fahrzeugId}
                        index={index}
                        anbauteilIndex={indexAnbauteil}
                        isNew={!fuhrparkeintragAnbauteil.id}
                        taetigkeitsberichtDatum={taetigkeitsberichtDatum}
                        push={push}
                        remove={remove}
                        newestIndex={
                          fuhrparkeintrag.fuhrparkeintragAnbauteile.length - 1
                        }
                        fuhrparkeintragAnbauteil={fuhrparkeintragAnbauteil}
                      />
                    </Box>
                  </Box>
                </Box>
              )
            )}
          </>
        )}
      </FieldArray>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-remark"))}
          name={`fuhrparkeintraege.${index}.bemerkung`}
          type={"box"}
        />
      </Box>
    </Stack>
  );
}
