import { Box, capitalize, Divider, Stack, Typography } from "@mui/material";
import { FastField, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../i18n";
import BaustellenSelect from "../baustellen/BaustellenSelect";
import BenutzerSelect from "../benutzer/BenutzerSelect";
import { useEffect } from "react";
import { useGetBenutzer } from "../../client/hooks";

export default function TaetigkeitsberichtFieldsContent({
  isNew,
}: {
  isNew: boolean;
}) {
  const { t } = useT("taetigkeitsbericht");
  const { setFieldValue } = useFormikContext();
  const [mitarbeiterIdField] = useField("mitarbeiterId");
  const [stundenField] = useField("arbeitszeitNetto");
  const [stundensatzField] = useField("stundensatz");

  const searchBenutzer = useGetBenutzer();

  const calculateLohnkosten = () => {
    setFieldValue(
      "lohnkosten",
      (stundensatzField.value * stundenField.value).toFixed(2)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const benutzer = await searchBenutzer(mitarbeiterIdField.value);
      await setFieldValue("stundensatz", benutzer.berechnungsSatz);
    };
    if (isNew && mitarbeiterIdField.value) {
      fetchData();
    }
  }, [mitarbeiterIdField.value]);

  useEffect(() => {
    calculateLohnkosten();
  }, [stundenField.value, stundensatzField.value]);

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={BenutzerSelect}
          name="mitarbeiterId"
          label={capitalize(t("activity-report-user"))}
          required
        />
        <FastField
          component={BaustellenSelect}
          name="baustelleId"
          label={capitalize(t("activity-report-construction"))}
          required
        />
        <FastField
          component={TextField}
          label={capitalize(t("activity-report-date"))}
          name="datum"
          type={"date"}
          InputLabelProps={{ shrink: true }}
          required
        />
      </Box>
      <Typography sx={{ paddingTop: 2 }}>
        {capitalize(t("working-hours"))}
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("activity-report-time-net"))}
          name="arbeitszeitNetto"
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
        />
        <FastField
          component={TextField}
          label={capitalize(t("activity-report-hourly-rate"))}
          name="stundensatz"
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
        />
        <FastField
          component={TextField}
          label={capitalize(t("activity-report-personal-cost"))}
          name="lohnkosten"
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          disabled={true}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("activity-report-remark"))}
          name="bemerkung"
          type={"box"}
        />
      </Box>
    </Stack>
  );
}
