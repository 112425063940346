import {Paper, Typography} from "@mui/material";
import Layout from "../../components/Layout";
import {useT} from "../../i18n";
import BenutzerDataTable from "../../components/benutzer/BenutzerDataTable";


export default function BenutzerListe() {
    const {t } = useT("customer");
    return (
        <Layout>
            <Paper elevation={1} sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}>
                <Typography variant="h5" >Benutzer</Typography>
                <BenutzerDataTable />
            </Paper>
        </Layout>
    );
}