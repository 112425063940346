import { useCallback } from "react";
import { useTranslation as useI18nTranslation } from "react-i18next";

/**
 * Turn the first letter of a string to uppercase. This may seem trivial,
 * but the rules for capitalization are different in different languages.
 *
 * @deprecated use `useT()` instead.
 *
 * TODO: remove the deprecation and the export from `index.tsx` once all
 * direct imports of this file have been replaced with `useT()`.
 */
export function useCapitalize(): (s: string) => string {
  const {
    i18n: { language },
  } = useI18nTranslation();
  return useCallback((s) => capitalize(s, language), [language]);
}

function capitalize(s: string, locale?: string): string {
  if (!s.length) return s;
  if (s.length === 1) return s.toLocaleUpperCase(locale);
  return s[0].toLocaleUpperCase(locale) + s.slice(1);
}
