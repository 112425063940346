import { Form, Formik, FormikHelpers } from "formik";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../../components/SubmitButton";
import { useTranslation } from "react-i18next";

interface EntferneAnbauteilFahrzeugZuweisungProps {
  onClose: () => void;
  zugewiesenesAnbauteilId: string;
  anbauteilId: string;
}

export default function EntferneAnbauteilFahrzeugZuweisung({
  onClose,
  anbauteilId,
  zugewiesenesAnbauteilId,
}: EntferneAnbauteilFahrzeugZuweisungProps) {
  const { t } = useTranslation("fuhrpark");

  const { id: fahrzeugId = "" } = useParams<{
    id: string;
  }>();

  //const {mutateAsync} = useAssignAnbauteilFahrzeugZu(fahrzeugId, anbauteilId);
  let schema = yup.object().shape({
    kostenStunde: yup
      .number()
      .min(0)
      .required(capitalize(t("assignment-required-cost-hour"))),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(async () => {
    onClose();
  }, [navigate, onClose]);
  return (
    <Paper
      elevation={1}
      sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
    >
      <Formik
        initialValues={{
          kostenStunde: 0.0,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValidating, isValid, dirty }) => (
          <>
            <Typography variant="h5">
              {t("remove-attachment-part-assigned")}
            </Typography>
            <DialogContent>
              <Form id="entferne-anbauteil-zuweisung">
                {capitalize(t("sure-remove-attachment-part-assigned"))}
              </Form>
            </DialogContent>
            <DialogActions>
              <SubmitButton
                form="entferne-anbauteil-zuweisung"
                type="submit"
                variant="contained"
                color="secondary"
                loading={isSubmitting}
                disabled={isValidating || !isValid || !dirty}
              >
                {capitalize(t("remove"))}
              </SubmitButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Paper>
  );
}
