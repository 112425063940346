import { createId } from ".";
import { localStoragePrefix } from "../config";

const localStorageKey = `${localStoragePrefix}deviceId`;

function getDeviceId() {
  let id = window.localStorage.getItem(localStorageKey);

  if (!id) {
    id = createId();
    window.localStorage.setItem(localStorageKey, id);
  }

  return id;
}

/**
 * Each devices receives a unique ID on first load. This ID is stored
 * in local storage and should remain unchanged for the rest of the
 * lifetime of the device.
 */
export const deviceId = getDeviceId();
