import {Tab, Tabs } from "@mui/material";
import {useT} from "../../i18n";
import {Outlet, useMatches } from "react-router";
import LinkBehavior from "../../components/LinkBehavior";
import Layout from "../../components/Layout";
export default function BaustellenPage(){
    return (
        <Layout title={"Baustelle"} back="/baustellen">
            <BaustellenNav />
            <Outlet />
        </Layout>
    );

}
function BaustellenNav() {
    const { t } = useT("customer");
    const tab = useActiveTab();

    return tab ? (
        <Tabs value={tab} sx={{ mb: 2, ml:5 }}>
            <Tab
                component={LinkBehavior}
                label={"Stammdaten"}
                value="stammdaten"
                href="stammdaten"
                sx={{ textTransform: 'none',fontSize: '1rem' }}
            />
            <Tab
                component={LinkBehavior}
                label={"Konto"}
                value="konto"
                href="konto"
                sx={{ textTransform: 'none', fontSize: '1rem' }}
            />
            <Tab
                component={LinkBehavior}
                label={"Vorgänge"}
                value="vorgaenge"
                href="vorgaenge"
                sx={{ textTransform: 'none', fontSize: '1rem' }}
            />
        </Tabs>
    ) : null;
}
function useActiveTab() {
    // FIXME: this is super ugly and brittle.
    // Can we get the curent tab in a more stable way?
    const matches = useMatches();
    const lastMatch = matches[matches.length - 1];
    const parts = lastMatch.pathname.split(/\//);
    return parts[parts.length - 1];
}