// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Entsorger } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useDeleteEntsorger(): (
  entsorgerId: string
) => Promise<Entsorger> {
  const getAPI = useGetApi();
  return useCallback(
    async (entsorgerId) =>
      (await getAPI()).entsorger.deleteEntsorger({ entsorgerId }),
    [getAPI]
  );
}
