// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ApiError, type Benutzer } from "../../api";
import { useGetBenutzer as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useBenutzer(
  benutzerId: string,
  options: Omit<
    UseQueryOptions<Benutzer, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): Benutzer {
  const getter = useGetter();
  return useQuery(["benutzer", benutzerId], () => getter(benutzerId), options)
    .data!;
}
