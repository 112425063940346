// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Fahrzeug, FahrzeugDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateFahrzeug(): (
  fahrzeugId: string,
  requestBody: FahrzeugDaten
) => Promise<Fahrzeug> {
  const getAPI = useGetApi();
  return useCallback(
    async (fahrzeugId, requestBody) =>
      (await getAPI()).fahrzeuge.updateFahrzeug({ fahrzeugId, requestBody }),
    [getAPI]
  );
}
