import { Form, Formik, FormikHelpers} from "formik";
import Layout from "../../components/Layout";
import {capitalize, DialogActions, DialogContent, Paper, Typography} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import {useTranslation} from "react-i18next";
import {useCreateErzeuger} from "../../client/hooks";
import {useMutation, useQueryClient} from "react-query";
import {ErzeugerDaten} from "../../api";
import ErzeugerFieldsContent from "../../components/erzeuger/ErzeugerFieldsContent";

export default function ErstelleErzeuger() {
    const { t } = useTranslation("customer");
    const{mutateAsync} = useSaveErzeuger();
    let schema = yup.object().shape({
        datum: yup.string().required(),
        nummer: yup.string().required(),
        name: yup.string().required()
    });
    const navigate = useNavigate();
    const onSubmit = useCallback(
        async (
            values: { nummer:string, name: string, datum: string},
            formikHelpers: FormikHelpers<{ nummer:string, name: string, datum: string }>
        ) => {
            const { setSubmitting, setStatus } = formikHelpers;
            const erzeuger =  await mutateAsync({ nummer:values.nummer, name:values.name, datum:values.datum+"T00:00:00.000Z"});
            navigate(`/erzeuger`);
            setStatus(undefined);
            try {
            } catch (error: any) {
                setStatus(error.message);
            } finally {
                setSubmitting(false);
            }
        },
        [navigate,mutateAsync]
    );
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    return (
        <Layout title={"Neuer Erzeuger"} back="/erzeuger" >
            <Paper elevation={1} sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}>
                <Formik
                    initialValues={{
                        datum: getCurrentDate(),
                        nummer:"",
                        name: ""
                    }}
                    validationSchema={schema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, isValidating, isValid, dirty }) => (
                    <>
                        <Typography variant="h5" >Neuer Erzeuger</Typography>
                        <DialogContent>
                            <Form id="new-erzeuger">
                                <ErzeugerFieldsContent></ErzeugerFieldsContent>
                            </Form>
                        </DialogContent>
                        <DialogActions>
                            <SubmitButton
                                form="new-erzeuger"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                loading={isSubmitting}
                                disabled={isValidating || !isValid || !dirty}
                            >
                                {capitalize(t("save"))}
                            </SubmitButton>
                        </DialogActions>
                    </>
                    )}
                </Formik>
            </Paper>
        </Layout>
    );
}
function useSaveErzeuger() {
    const createErzeuger = useCreateErzeuger();
    const queryClient = useQueryClient();

    return useMutation(
        (input:ErzeugerDaten) => {
            return createErzeuger(input);
        },
        {
            onSuccess: async () => {
                queryClient.invalidateQueries([
                    "erzeuger",
                ]);
            },
        }
    );
}