// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type BenutzerListe } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type SucheBenutzerQuery = {
  search?: string;
  page?: number;
  limit?: number;
  sort?: string;
};

export function useSucheBenutzer(): (
  query: SucheBenutzerQuery
) => Promise<BenutzerListe> {
  const getAPI = useGetApi();
  return useCallback(
    async (query = {}) => (await getAPI()).benutzer.sucheBenutzer({ ...query }),
    [getAPI]
  );
}
