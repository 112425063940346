import { Avatar } from "@mui/material";
import { Suspense } from "react";
import { useQuery } from "react-query";
import type { UserInfo } from "../api";

export default function UserAvatar({
  user,
  size = 40,
}: {
  user: UserInfo;
  size?: number;
}) {
  return (
    <Suspense fallback={<Avatar sx={{ width: size, height: size }} />}>
      <UserAvatarContent user={user} size={size} />
    </Suspense>
  );
}

function UserAvatarContent({ user, size }: { user: UserInfo; size: number }) {
  return (
    <Avatar src={usePicture(user, size)} sx={{ width: size, height: size }} />
  );
}

function usePicture(user: UserInfo, size: number) {
  return useQuery(["users", user.id, "picture", { size }], async () => {
    if (user.picture) return user.picture;
    if (!user.email) return;

    const hash = buf2hex(
      await crypto.subtle.digest(
        "SHA-256",
        new TextEncoder().encode(user.email.trim().toLowerCase())
      )
    );

    return `https://gravatar.com/avatar/${hash}?s=${size}&d=mp`;
  }).data!;
}

function buf2hex(buffer: ArrayBuffer): string {
  return [...new Uint8Array(buffer)]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("");
}
