// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type EntsorgerInfo } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useGetEntsorger(): (
  entsorgerId: string
) => Promise<EntsorgerInfo> {
  const getAPI = useGetApi();
  return useCallback(
    async (entsorgerId) =>
      (await getAPI()).entsorger.getEntsorger({ entsorgerId }),
    [getAPI]
  );
}
