// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import {
  type ZugewieseneRechnungBaustelle,
  ZugewieseneRechnungBaustelleDaten,
} from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useAssignBaustelleZuRechnung(): (
  rechnungsId: string,
  requestBody: ZugewieseneRechnungBaustelleDaten
) => Promise<ZugewieseneRechnungBaustelle> {
  const getAPI = useGetApi();
  return useCallback(
    async (rechnungsId, requestBody) =>
      (await getAPI()).rechnungen.assignBaustelleZuRechnung({
        rechnungsId,
        requestBody,
      }),
    [getAPI]
  );
}
