import { useCallback } from "react";
import { useAuth } from "react-oidc-context";

export function useGetAccessToken(): () => Promise<string | undefined> {
  // TODO: I'm not entirely sure whether this code will survive token refreshes.
  const auth = useAuth();

  return useCallback(
    async () => auth.user?.access_token,
    [auth.user?.access_token]
  );
}
