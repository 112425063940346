// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { CreateAnhang, type RechnungsAnhang } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useCreateRechnungsAnhang(): (
  formData: CreateAnhang
) => Promise<RechnungsAnhang> {
  const getAPI = useGetApi();
  return useCallback(
    async (formData) =>
      (await getAPI()).rechnungen.createRechnungsAnhang({ formData }),
    [getAPI]
  );
}
