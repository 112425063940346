import {
  Box,
  Drawer,
  LinearProgress,
  Toolbar, Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useState, type ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { drawerWidth } from "../config";
import { MainMenu } from "./MainMenu";
import Nav from "./Nav";
import SuspenseBoundary from "./SuspenseBoundary";

export default function Layout({
  title,
  path,
  back,
  children,
}: {
  title?: ReactNode;
  path?: Array<string>;
  back?: string;
  children: ReactNode;
}) {
  const theme = useTheme();
  const mobile = !useMediaQuery(
    `(min-width: ${theme.breakpoints.values.md}px)`
  );
  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);
  const pageTitle = (path ?? (typeof title === "string" ? [title] : []))
    .toReversed()
    .join(" · ");

  return (
    <>
      <Helmet title={pageTitle} titleTemplate="%s · Zeller Baustellen-Informationsprogramm">
        <meta name="theme-color" content={theme.palette.primary.dark} />
      </Helmet>
      <Box sx={{ display: "flex" }}>
        <Drawer
          variant={mobile ? "temporary" : "permanent"}
          open={mobile && open}
          onClose={close}
          PaperProps={{ sx: { width: drawerWidth, borderRight: "none" } }}
          sx={{ width: drawerWidth, zIndex: 1203 }}
        >
          <MainMenu close={close} />
        </Drawer>
        <Box sx={{ flexGrow: 1, position: "relative" }}>
          <Nav
            title={title}
            back={back}
            mobile={mobile}
            open={open}
            setOpen={setOpen}
          />
          <Toolbar />
          <SuspenseBoundary fallback={<LinearProgress />}>
            <Box component="main" sx={{ my: 2 }}>
              {children}
            </Box>
          </SuspenseBoundary>
        </Box>
      </Box>
    </>
  );
}
