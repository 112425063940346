import { createContext, useContext, type PropsWithChildren } from "react";

export type Props = PropsWithChildren<{ permissions: Array<string> }>;

type PermissionsContext = {
  permissions: Array<string>;
};

const Context = createContext<PermissionsContext | undefined>(undefined);

export default function PermissionsContextProvider({
  permissions,
  children,
}: Props) {
  const parentPermissions = usePermissions();
  return (
    <Context.Provider
      value={{ permissions: [...parentPermissions, ...permissions] }}
    >
      {children}
    </Context.Provider>
  );
}

export function usePermissions(): Array<string> {
  return useContext(Context)?.permissions ?? [];
}
