// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type FuhrparkeintragAnbauteil } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useDeleteFuhrparkeintragAnbauteil(): (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  fuhrparkeintragAnbauteilId: string
) => Promise<FuhrparkeintragAnbauteil> {
  const getAPI = useGetApi();
  return useCallback(
    async (
      taetigkeitsberichtId,
      fuhrparkeintragId,
      fuhrparkeintragAnbauteilId
    ) =>
      (await getAPI()).fuhrparkeintraganbauteile.deleteFuhrparkeintragAnbauteil(
        { taetigkeitsberichtId, fuhrparkeintragId, fuhrparkeintragAnbauteilId }
      ),
    [getAPI]
  );
}
