// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Erzeuger, ErzeugerDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateErzeuger(): (
  erzeugerId: string,
  requestBody: ErzeugerDaten
) => Promise<Erzeuger> {
  const getAPI = useGetApi();
  return useCallback(
    async (erzeugerId, requestBody) =>
      (await getAPI()).erzeuger.updateErzeuger({ erzeugerId, requestBody }),
    [getAPI]
  );
}
