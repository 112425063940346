// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type SystemInformation } from "../../api";
import { useGetUnauthenticatedAPI } from "../client";
import { useCallback } from "react";

export function useGetSystemInformation(): () => Promise<SystemInformation> {
  const getAPI = useGetUnauthenticatedAPI();
  return useCallback(
    async () => (await getAPI()).system.getSystemInformation(),
    [getAPI]
  );
}
