import { Box, Stack } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../i18n";
import AddressFieldsContent from "../fields/AddressFieldsContent";
import CommunicationFieldsContent from "../fields/CommunicationFieldsContent";

export default function LieferantenFieldsContent() {
  const { t } = useT("customer");
  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
      >
        <Field component={TextField} label="Name *" name="name" />
        <Field
          component={TextField}
          label={t("name-suffix")}
          name="namenszusatz"
        />
        <Field
          component={TextField}
          label={t("tax-number")}
          name="steuernummer"
        />
        <Field
          component={TextField}
          label={t("tax-id")}
          name="umsatzsteuerId"
        />
        <Field
          component={TextField}
          label={t("supplier-customer-number")}
          name="kundennummer"
        />
        <Field
          component={TextField}
          label={t("supplier-creditor-number")}
          name="lieferantennummer"
          required
        />
      </Box>
      <AddressFieldsContent></AddressFieldsContent>
      <CommunicationFieldsContent></CommunicationFieldsContent>
    </Stack>
  );
}
