import DeleteIcon from "@mui/icons-material/Delete";
import {
    Button,
    capitalize,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import {useMutation, useQueryClient} from "react-query";
import {bindPopover, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import {useDeleteAnbauteil, useDeleteFahrzeug} from "../../../client/hooks";
import {useT} from "../../../i18n";
import {useNavigate} from "react-router";

export default function DeleteAnbauteilButton({id}: {id: string}) {
    const { t } = useT("fuhrpark");
    const mutate = useLoescheAnbauteil(id);
    const popupState = usePopupState({
        variant: "popover",
        popupId: `anbauteil-${id}-deactivate`,
    });
    return (
        <>
            <IconButton
                {...bindTrigger(popupState)}
                aria-label="delete"
            >
                <DeleteIcon />
            </IconButton>
            <Dialog {...bindPopover(popupState)}>
                <DialogTitle id="alert-dialog-title">
                    {capitalize(t("sure-delete-attachment-part-header"))}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {capitalize(t("sure-delete-attachment-part-body"))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => popupState.close()} color="inherit">
                        {capitalize(t("abort"))}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            mutate.mutate();
                            popupState.close();
                        }}
                    >
                        {capitalize(t("delete"))}
                    </Button>
                </DialogActions>
            </Dialog>
        </>)

}
function useLoescheAnbauteil(id:string) {
    const deleteAnbauteil = useDeleteAnbauteil();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(
        () => {
            return deleteAnbauteil(id);
        },
        {
            onSuccess: async () => {
                queryClient.invalidateQueries([
                    "anbauteile",
                ]);
                queryClient.invalidateQueries([
                    "anbauteil-fahrzeug",
                ]);
            },
        }
    );
}
