// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type AllOptions } from "../../api";
import { useGetUnauthenticatedAPI } from "../client";
import { useCallback } from "react";

export function useGetOptions(): () => Promise<AllOptions> {
  const getAPI = useGetUnauthenticatedAPI();
  return useCallback(
    async () => (await getAPI()).system.getOptions(),
    [getAPI]
  );
}
