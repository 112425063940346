import { useCallback } from "react";
import { API } from "../api";
import { apiUrl, version } from "../config";
import { useGetAccessToken } from "./access-token";
import { createCorrelationId } from "./correlation";
import { deviceId } from "./device";

/**
 * Get an authenticated API client. The access token validity is validated on each call
 * to the getter, so make sure that you do not keep the result of the getter (the actual
 * client instance) around for too long.
 *
 * A proposed usage pattern looks something like this:
 *
 * ```
 * const getApi = useGetApi();
 * const doSomething = useCallback(async () => (await getApi()).doSomething(), [getApi]);
 * ```
 *
 * You should not need to call this hook directly. Use the generated hooks instead.
 */
export function useGetApi(): () => Promise<API> {
  const getAccessToken = useGetAccessToken();
  const getUnauthenticatedAPI = useGetUnauthenticatedAPI();

  return useCallback(async () => {
    const accessToken = await getAccessToken();
    return getUnauthenticatedAPI(accessToken);
  }, [getAccessToken, getUnauthenticatedAPI]);
}

/**
 * Get a _potentially_ authenticated API client. If `accessToken` is provided,
 * it is used to authenticate client requests.
 *
 * Same as with `useGetApi`, do not keep the result of this function around for too long.
 *
 * You should not need to call this hook directly. Use the generated hooks instead.
 */
export function useGetUnauthenticatedAPI(): (
  accessToken?: string
) => Promise<API> {
  return useCallback(
    async (accessToken) =>
      new API({
        BASE: apiUrl,
        TOKEN: accessToken,
        HEADERS: {
          "x-correlation-id": createCorrelationId(),
          "x-ui-version": version,
          "x-device-id": deviceId,
          "Authorization": `token`,
        },
      }),
    []
  );
}
