import { FieldProps } from "formik";
import { useEffect, useMemo, useState } from "react";
import { ApiError, Fahrzeug, FahrzeugePage, HerkunftTyp } from "../../../api";
import {
  ListItem,
  ListItemText,
  debounce,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { Autocomplete } from "formik-mui";
import { useSucheFahrzeuge } from "../../../client/hooks";

export default function FahrzeugSelect({
  field,
  form,
  meta,
  label,
  fahrzeugHerkunft,
  ...props
}: {
  label: string;
  fahrzeugHerkunft: HerkunftTyp;
  required?: boolean;
} & FieldProps) {
  const searchFahrzeuge = useSucheFahrzeuge();
  const [inputValue, setInputValue] = useState(field.value);
  const [options, setOptions] = useState<ReadonlyArray<Fahrzeug>>([]);

  const fetchOptions = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: FahrzeugePage) => void
        ) =>
          searchFahrzeuge({
            fahrzeugHerkunft: fahrzeugHerkunft,
            search: inputValue === "" ? request.input : inputValue,
            page: 0,
            limit: 20,
            sort: "kennzeichen",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchFahrzeuge, inputValue]
  );

  useEffect(() => {
    let active = true;

    fetchOptions({ input: inputValue }, (error, results) => {
      if (error) console.error("Error:", error);
      if (active) {
        setOptions([
          ...(field.value ? [field.value] : []),
          ...(results ? results.items : []),
        ]);
      }
    });

    return () => {
      active = false;
    };
  }, [field.value, inputValue, fetchOptions]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      autoComplete
      includeInputInList
      filterSelectedOptions
      options={options}
      filterOptions={(x) => x}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        // @ts-ignore
        const fahrzeugId = value ? value.id : "";
        form.setFieldValue(field.name, fahrzeugId);
      }}
      getOptionLabel={(option: string | Fahrzeug) => {
        const fahrzeug = options.find((opt) => opt.id === field.value);
        return fahrzeug?.kennzeichen ?? "";
      }}
      renderOption={(props, option: string | Fahrzeug) => (
        <ListItem {...props}>
          <ListItemText
            primary={typeof option === "string" ? option : option.kennzeichen}
            secondary={
              typeof option === "string"
                ? undefined
                : [option.gruppe, option.fabrikant, option.fahrzeugTyp]
                    .filter(Boolean)
                    .join(", ")
            }
          />
        </ListItem>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          name={field.name}
          label={"Fahrzeug"}
          {...props}
          {...params}
        />
      )}
    />
  );
}
