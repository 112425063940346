import { Box, Typography, type BoxProps } from "@mui/material";
import { ReactNode, type PropsWithChildren } from "react";

export type Props = PropsWithChildren<{
  label: ReactNode;
}> &
  Omit<BoxProps, "children">;

export default function LabelValue({ label, children, ...props }: Props) {
  return (
    <Box component="dl" {...props}>
      <Typography component="dt" variant="body2" color="textSecondary">
        {label}
      </Typography>
      <Typography component="dd" whiteSpace="pre-line">
        {children}
      </Typography>
    </Box>
  );
}
