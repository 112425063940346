import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useCreateEntsorger, useCreateErzeuger } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { EntsorgerDaten, ErzeugerDaten } from "../../api";
import ErzeugerFieldsContent from "../../components/erzeuger/ErzeugerFieldsContent";
import EntsorgerFieldsContent from "../../components/entsorger/EntsorgerFieldsContent";

export default function ErstelleEntsorger() {
  const { t } = useTranslation("customer");
  const { mutateAsync } = useSaveEntsorger();
  let schema = yup.object().shape({
    datum: yup.string().required(),
    nummer: yup.string().required(),
    name: yup.string(),
    archviert: yup.boolean(),
    verbliedortId: yup.string(),
    abfallartId: yup.string(),
    abfallartKey: yup.string(),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        nummer: string;
        name: string;
        datum: string;
        archiviert: boolean;
        verbleibortId: string;
        abfallartId: string;
        abfallartKey: string;
      },
      formikHelpers: FormikHelpers<{
        nummer: string;
        name: string;
        datum: string;
        archiviert: boolean;
        verbleibortId: string;
        abfallartId: string;
        abfallartKey: string;
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      const entsorger = await mutateAsync({
        nummer: values.nummer,
        name: values.name,
        datum: values.datum + "T00:00:00.000Z",
        archiviert: values.archiviert,
        verbleibortId: values.verbleibortId,
        abfallartId: values.abfallartId,
      });
      navigate(`/entsorger`);
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <Layout title={"Neuer Entsorger"} back="/entsorger">
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Formik
          initialValues={{
            datum: getCurrentDate(),
            nummer: "",
            name: "",
            archiviert: false,
            verbleibortId: "",
            abfallartId: "",
            abfallartKey: "",
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty, values }) => (
            <>
              <Typography variant="h5">Neuer Entsorger</Typography>
              <DialogContent>
                <Form id="new-entsorger">
                  <EntsorgerFieldsContent></EntsorgerFieldsContent>
                </Form>
              </DialogContent>
              <DialogActions>
                <SubmitButton
                  form="new-entsorger"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Layout>
  );
}
function useSaveEntsorger() {
  const createEntsorger = useCreateEntsorger();
  const queryClient = useQueryClient();

  return useMutation(
    (input: EntsorgerDaten) => {
      return createEntsorger(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["entsorger"]);
      },
    }
  );
}
