import DeleteIcon from "@mui/icons-material/Delete";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import {useDeleteKunde} from "../../client/hooks";
import {useMutation, useQueryClient} from "react-query";
import {bindPopover, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";

export default function DeleteKundeButton({id}: {id: string}) {
    const mutate = useLoeschenKunde(id);
    const popupState = usePopupState({
        variant: "popover",
        popupId: `kunde-${id}-deactivate`,
    });
    return (
        <>
            <IconButton
                sx={{ position: 'absolute', top: 10, right: 10 }}
                {...bindTrigger(popupState)}
                aria-label="delete"
            >
                <DeleteIcon />
            </IconButton>
            <Dialog {...bindPopover(popupState)}>
                <DialogTitle id="alert-dialog-title">
                    Kunde wirlich löschen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sie sind dabei den Kunden zu löschen. Sind Sie sicher?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => popupState.close()} color="inherit">
                        abbrechen
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            mutate.mutate();
                            popupState.close();
                        }}
                    >
                        löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </>)

}
function useLoeschenKunde(id:string) {
    const deleteKunde = useDeleteKunde();
    const queryClient = useQueryClient();

    return useMutation(
        () => {
            return deleteKunde(id);
        },
        {
            onSuccess: async () => {
                queryClient.invalidateQueries([
                    "kunden",id
                ]);
            },
        }
    );
}
