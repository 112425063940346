import { ArrowBack, Menu } from "@mui/icons-material";
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { type ReactNode } from "react";
import { drawerWidth } from "../config";
import LinkBehavior from "./LinkBehavior";
import NavUser from "./NavUser";

export default function Nav({
  title,
  back,
  mobile,
  open,
  setOpen,
}: {
  title?: ReactNode;
  back?: string;
  mobile: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  return (
    <AppBar
      position="fixed"
      color="primary"
      elevation={0}
      sx={{
        backgroundColor: "primary.900",
        color: "common.white",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderBottom: (theme) =>
          mobile ? `1px solid ${theme.palette.divider}` : undefined,
        width: mobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
      }}
    >
      <Toolbar>
        {mobile && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(!open)}
            sx={{ mr: 1 }}
          >
            <Menu />
          </IconButton>
        )}
        {title && (
          <Stack direction="row" alignItems="center">
            {back && (
              <IconButton
                href={back}
                edge="start"
                color="inherit"
                component={LinkBehavior}
              >
                <ArrowBack />
              </IconButton>
            )}
            <Typography variant="h5">{title}</Typography>
          </Stack>
        )}
        <Box sx={{ ml: "auto" }}>
          <NavUser />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
