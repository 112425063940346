import { TFunction } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

/**
 * Localize the error messages of yup validations.
 */
export default function YupLocalizer() {
  const { t } = useTranslation("validations");

  useEffect(() => {
    yup.setLocale(createYupLocale(t));
  }, [t]);

  // This component doesn't render anything.
  return <></>;
}

function createYupLocale(t: TFunction<"validations">): yup.LocaleObject {
  const required = t("required");

  return {
    mixed: { required },
    string: {
      min: ({ min }) => t("string.min", { min }),
      max: ({ max }) => t("string.max", { max }),
      email: t("string.email"),
    },
  };
}
