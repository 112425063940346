import { Box, Divider, Stack, Typography } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../i18n";
import AddressFieldsContent from "../fields/AddressFieldsContent";
import CommunicationFieldsContent from "../fields/CommunicationFieldsContent";

export default function KundenFieldsContent() {
  const { t } = useT("customer");
  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
      >
        <Field component={TextField} label="Name *" name="name" />
        <Field
          component={TextField}
          label={t("name-suffix")}
          name="namenszusatz"
        />
        <Field
          component={TextField}
          label={t("customer-debitor-number")}
          name="kundennummer"
        />
        <Field
          component={TextField}
          label={t("tax-id")}
          name="umsatzsteuerId"
        />
      </Box>
      <AddressFieldsContent></AddressFieldsContent>
      <CommunicationFieldsContent></CommunicationFieldsContent>
      <Typography sx={{ paddingTop: 2 }}>{t("storage")}</Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
      >
        <Field
          component={TextField}
          label={t("documents")}
          name="schriftAblage"
        />
      </Box>
    </Stack>
  );
}
