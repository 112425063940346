import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import DataTable from "../data/DataTable";
import { useGetApi } from "../../client";
import { Abladestelle } from "../../api";

export type AbladestelleFilter = {
  search?: string;
};

export type Props = Omit<DataRequest<AbladestelleFilter>, "filter"> &
  Partial<Pick<DataRequest<AbladestelleFilter>, "filter">>;

export default function AbladestellenDatatable({ ...input }: Props) {
  const request = useDataRequest<AbladestelleFilter>({ filter: {}, ...input });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <MaterialResults {...request} />
    </Stack>
  );
}

function FilterComp(request: DataRequestState<AbladestelleFilter>) {
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-abladestelle");
            }}
          >
            Abladestelle anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neu-abladestelle");
            }}
          >
            Abladestelle anlegen
          </Button>
        </>
      )}
    </Box>
  );
}

function MaterialResults({ ...request }: DataRequestState<AbladestelleFilter>) {
  return <Results request={request} />;
}

function Results({
  request,
}: {
  request: DataRequestState<AbladestelleFilter>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadAbladestellen: DataLoader<AbladestelleFilter, Abladestelle> =
    useCallback(
      async (params) =>
        (await getApi()).abladestellen.sucheAbladestellen({ ...params }),
      [getApi]
    );
  return (
    <DataTable
      columns={useColumns()}
      request={request}
      queryKey={["abladestellen"]}
      loadData={loadAbladestellen}
      onRowClick={(row) => {
        navigate("/abladestellen/" + row.id);
      }}
    />
  );
}

function useColumns(): Array<GridColDef<Abladestelle>> {
  const { t } = useT("customer");

  return useMemo(
    () => [
      {
        field: "kurzname",
        headerName: t("Kurzname"), // Kurzname
        flex: 1,
      },
      {
        field: "firma",
        headerName: t("Firma"), // Firma
        flex: 2,
      },
      {
        field: "zusatz",
        headerName: t("Zusatz"), // Zusatz
        flex: 2,
      },
      {
        field: "ort",
        headerName: t("Ort"), // Ort
        flex: 2,
      },
      {
        field: "isDeponie",
        headerName: t("Typ"), // Ort
        flex: 2,
        renderCell: (params) => {
          return params.value ? "Deponie" : "Abladestelle";
        },
      },
    ],
    [t]
  );
}
