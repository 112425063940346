import {Form, Formik, FormikHelpers} from "formik";
import Layout from "../../components/Layout";
import {capitalize, Chip, DialogActions, DialogContent, Paper, Typography} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import {useNavigate, useParams} from "react-router";
import SubmitButton from "../../components/SubmitButton";
import {useTranslation} from "react-i18next";
import {useErzeuger} from "../../client/queries";
import {useUpdateErzeuger} from "../../client/hooks";
import {useMutation, useQueryClient} from "react-query";
import {ErzeugerDaten} from "../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import ErzeugerFieldsContent from "../../components/erzeuger/ErzeugerFieldsContent";
import DeleteErzeugerButton from "../../components/erzeuger/DeleteErzeugerButton";

export default function DetailsErzeuger() {
    const { id: erzeugerId = "" } = useParams<{
        id: string;
    }>();
    const erzeuger = useErzeuger(erzeugerId)
    const { t } = useTranslation("customer");
    let schema = yup.object().shape({
        nummer: yup.string().required(),
        name: yup.string().required(),
        datum: yup.string().required()
    });
    const navigate = useNavigate();
    const {mutateAsync} = useSaveErzeuger(erzeugerId);
    const onSubmit = useCallback(
        async (
            values: {
                nummer: string,
                name: string,
                datum: string},
            formikHelpers: FormikHelpers<{ nummer:string, name: string, datum: string }>
        ) => {
            const erzeuger = await mutateAsync({ nummer:values.nummer, name:values.name, datum:values.datum+"T00:00:00.000Z" });
            const { setSubmitting, setStatus } = formikHelpers;
            navigate(`/erzeuger`);
            setStatus(undefined);
            try {
            } catch (error: any) {
                setStatus(error.message);
            } finally {
                setSubmitting(false);
            }
        },
        [navigate, mutateAsync]
    );
    return (
        <Layout title={"Erzeuger"} back="/erzeuger" >
            <Paper elevation={1} sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2, position: 'relative' }}>
                <DeleteErzeugerButton id={erzeugerId}></DeleteErzeugerButton>
                <Formik
                    enableReinitialize
                    initialValues={{
                        nummer: erzeuger.nummer ?? "",
                        name: erzeuger.name ?? "",
                        datum: erzeuger.datum?.split("T")[0] ?? ""
                    }}
                    validationSchema={schema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, isValidating, isValid, dirty }) => (
                    <>
                        <Typography variant="h5" >{
                            erzeuger.geloeschtAm === undefined ? (
                                "Erzeuger " + erzeuger.nummer
                            ) : (
                                <>
                                    <>{"Erzeuger " + erzeuger.nummer}</>
                                    <Chip
                                        size="small"
                                        icon={<DeleteIcon />}
                                        label={"gelöscht"}
                                        color="error"
                                    />
                                </>
                            )}</Typography>
                        <DialogContent>
                            <Form id="details-erzeuger">
                                <ErzeugerFieldsContent></ErzeugerFieldsContent>
                            </Form>
                        </DialogContent>
                        <DialogActions>
                            <SubmitButton
                                form="details-erzeuger"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                loading={isSubmitting}
                                disabled={isValidating || !isValid || !dirty}
                            >
                                {capitalize(t("save"))}
                            </SubmitButton>
                        </DialogActions>
                    </>
                    )}
                </Formik>
            </Paper>
        </Layout>
    );
}

function useSaveErzeuger(id:string) {
    const updateErzeuger = useUpdateErzeuger();
    const queryClient = useQueryClient();

    return useMutation(
        (input:ErzeugerDaten) => {
            return updateErzeuger(id,input);
        },
        {
            onSuccess: async () => {
                queryClient.invalidateQueries([
                    "erzeuger",id
                ]);
            },
        }
    );
}