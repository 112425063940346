import { TextField, Grid, Switch, FormControlLabel } from "@mui/material";
import { Field } from "formik";

export default function AbladestelleFieldsContent() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <Field
          name="kurzname"
          label="Kurzname"
          as={TextField}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field name="firma" label="Firma" as={TextField} fullWidth />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControlLabel
          sx={{ mt: 1 }}
          control={
            <Field
              name={"isDeponie"}
              as={Switch}
              color="primary"
              type="checkbox"
            />
          }
          label={"Ist Deponie"}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field name="zusatz" label="Zusatz" as={TextField} fullWidth />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field name="ort" label="Ort" as={TextField} fullWidth />
      </Grid>
    </Grid>
  );
}
