import { customAlphabet } from "nanoid";

/**
 * Create a 21-character long ID using the same algorithm as the backend (nanoid).
 * The increased size of the alphabet provides a similar collision rate as type 4 UUIDs
 * but with only 21 characters.
 */

export const createId = customAlphabet(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  21
);
