import {
    Box,
    Button,
    Stack, useMediaQuery, useTheme,
} from "@mui/material";
import {useCallback, useMemo} from "react";
import { useNavigate } from "react-router";
import {GridColDef} from "@mui/x-data-grid";
import {DataLoader, DataRequest, DataRequestState, useDataRequest, useDebouncedFilter} from "../data";
import {useT} from "../../i18n";
import SearchField from "../SearchField";
import DataTable from "../data/DataTable";
import {useGetApi} from "../../client";
import {Erzeuger} from "../../api";

export type AbfallartFilter = {
    search?: string;
};


export type Props = Omit<
    DataRequest<AbfallartFilter>,
    "filter"
> &
    Partial<Pick<DataRequest<AbfallartFilter>, "filter">>;

export default function ErzeugerDataTable({
                                               ...input
                                           }: Props) {
    const request = useDataRequest<AbfallartFilter>({ filter: {}, ...input });

    return (
        <Stack spacing={2}>
            <FilterComp {...request} />
            <ErzeugerResults {...request} />
        </Stack>
    );
}

function FilterComp(request: DataRequestState<AbfallartFilter>) {
    const [{ search }, setField] =
        useDebouncedFilter(request);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%' }}>
            {isMobile ?
            <Stack direction="row">
                <SearchField
                    value={search ?? ""}
                    onChange={(s) => setField("search", s)}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginLeft: 2}}
                    onClick={()=>{navigate('/neu-erzeuger')}}
                >
                    Erzeuger anlegen
                </Button>
            </Stack> :
                <>
                    <SearchField
                        value={search ?? ""}
                        onChange={(s) => setField("search", s)}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                        onClick={()=>{navigate('/neu-erzeuger')}}
                    >
                        Erzeuger anlegen
                    </Button>
                </>
             }
        </Box>
    );
}

function ErzeugerResults({
                             ...request
                         }: DataRequestState<AbfallartFilter>) {
    return <Results request={request} />;
}
function Results({
                     request,
                 }: {
    request: DataRequestState<AbfallartFilter>;
}) {
    const navigate = useNavigate();
    const getApi = useGetApi();
    const loadErzeuger: DataLoader<AbfallartFilter, Erzeuger> = useCallback(
        async (params) =>
            (await getApi()).erzeuger.sucheErzeuger({ ...params }),
        [getApi]
    );
    return (
        <DataTable
            columns={useColumns()}
            request={request}
            queryKey={["erzeuger"]} //TODO: Richtigen QueryKey verwenden
            loadData={loadErzeuger}
            onRowClick={((row)=>{navigate('/erzeuger/' + row.id)})}
        />
    );
}
function useColumns(): Array<GridColDef<Erzeuger>> {
    const { t } = useT("customer");

    return useMemo(
        () => [
            {
                field: "datum",
                headerName: t("date"),
                valueFormatter: (params) => {
                    if (params.value) {
                        return new Date(params.value).toLocaleDateString();
                    }
                    return "";
                }
            },
            {
                field: "nummer",
                headerName: t("number"),
            },
            {
                field: "name",
                headerName: t("name"),
                flex: 5,
            }
        ],
        [t]
    );
}