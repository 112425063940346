// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ApiError, type SystemInformation } from "../../api";
import { useGetSystemInformation as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useSystemInformation(
  options: Omit<
    UseQueryOptions<SystemInformation, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): SystemInformation {
  const getter = useGetter();
  return useQuery(["system"], () => getter(), options).data!;
}
