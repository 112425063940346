// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Abladestelle, AbladestelleDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useCreateAbladestelle(): (
  requestBody: AbladestelleDaten
) => Promise<Abladestelle> {
  const getAPI = useGetApi();
  return useCallback(
    async (requestBody) =>
      (await getAPI()).abladestellen.createAbladestelle({ requestBody }),
    [getAPI]
  );
}
