import { FieldProps } from "formik";
import { useSucheBenutzer } from "../../client/hooks";
import { useEffect, useMemo, useState } from "react";
import { ApiError, Benutzer, BenutzerListe } from "../../api";
import {
  ListItem,
  ListItemText,
  debounce,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { Autocomplete } from "formik-mui";
import { useT } from "../../i18n";

export default function BenutzerSelect({
  field,
  form,
  meta,
  label,
  ...props
}: {
  label: string;
  required?: boolean;
} & FieldProps) {
  const { t } = useT("user");
  const searchBenutzer = useSucheBenutzer();
  const [inputValue, setInputValue] = useState(field.value);
  const [options, setOptions] = useState<ReadonlyArray<Benutzer>>([]);

  const fetchOptions = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: BenutzerListe) => void
        ) =>
          searchBenutzer({
            search: inputValue === "" ? request.input : inputValue,
            page: 0,
            limit: 20,
            sort: "name",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchBenutzer, inputValue]
  );

  useEffect(() => {
    let active = true;

    fetchOptions({ input: inputValue }, (error, results) => {
      if (error) console.error("Error:", error);
      if (active) {
        setOptions([
          ...(field.value ? [field.value] : []),
          ...(results ? results.items : []),
        ]);
      }
    });

    return () => {
      active = false;
    };
  }, [field.value, inputValue, fetchOptions]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      autoComplete
      includeInputInList
      filterSelectedOptions
      options={options}
      filterOptions={(x) => x}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        // @ts-ignore
        const benutzerId = value ? value.id : "";
        form.setFieldValue(field.name, benutzerId);
      }}
      getOptionLabel={(option: string | Benutzer) => {
        const benutzer = options.find((opt) => opt.id === field.value);
        return (
          [benutzer?.name, benutzer?.vorname].filter(Boolean).join(", ") ?? ""
        );
      }}
      renderOption={(props, option: string | Benutzer) => {
        return (
          <ListItem {...props}>
            <ListItemText
              primary={
                typeof option === "string"
                  ? option
                  : [option.name, option.vorname].filter(Boolean).join(", ")
              }
              secondary={
                typeof option === "string"
                  ? undefined
                  : [option.personalNummer, option.email ?? option.geburtsDatum]
                      .filter(Boolean)
                      .join(", ")
              }
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          name={field.name}
          label={"Mitarbeiter"}
          {...props}
          {...params}
        />
      )}
    />
  );
}
