import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { supportedLngs, useSetLanguage } from "../i18n";

const flags = {
  de: "🇩🇪",
  en: "🇺🇸",
};

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const set = useSetLanguage();

  return (
    <Box sx={{ px: 2, py: 1 }}>
      {supportedLngs
        .filter((lang) => lang !== i18n.language)
        .map((language) => (
          <Button
            size="small"
            key={language}
            onClick={() => set(language)}
            sx={{ p: 0 }}
          >
            {flags[language] ?? language}
          </Button>
        ))}
    </Box>
  );
}
