import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import DataTable from "../data/DataTable";
import { Baustelle, BaustellenInfo, Umsatz } from "../../api";

export type VorgaengeFilter = {
  search?: string;
  baustellenId?: string;
  isTaetigkeitsbericht?: boolean;
};

export type Props = Omit<DataRequest<VorgaengeFilter>, "filter"> &
  Partial<Pick<DataRequest<VorgaengeFilter>, "filter">> & {
    baustellenId: string; // Add baustellenId as a required prop
  };

export default function VorgaengeDataTable({ baustellenId, ...input }: Props) {
  const request = useDataRequest<VorgaengeFilter>({
    filter: { baustellenId: baustellenId },
    ...input,
  });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <VorgaengeResults {...request} />
    </Stack>
  );
}

function FilterComp(request: DataRequestState<VorgaengeFilter>) {
  const [{ search, isTaetigkeitsbericht, baustellenId }, setField] =
    useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (id) {
      setField("baustellenId", id);
    }
  }, [id, setField, search]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => {
              setField("search", s);
            }}
          />
          <FormControlLabel
            sx={{ ml: 2 }}
            control={
              <Checkbox
                color="primary"
                checked={isTaetigkeitsbericht}
                onChange={(e) =>
                  setField("isTaetigkeitsbericht", e.target.checked)
                }
              />
            }
            label={"Nur Tätigkeitsberichte"}
          />
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => {
              setField("search", s);
            }}
          />
          <FormControlLabel
            sx={{ ml: 2 }}
            control={
              <Checkbox
                color="primary"
                checked={isTaetigkeitsbericht}
                onChange={(e) =>
                  setField("isTaetigkeitsbericht", e.target.checked)
                }
              />
            }
            label={"Nur Tätigkeitsberichte"}
          />
        </>
      )}
    </Box>
  );
}

function VorgaengeResults({ ...request }: DataRequestState<VorgaengeFilter>) {
  return <Results request={request} />;
}

function Results({ request }: { request: DataRequestState<VorgaengeFilter> }) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadUmsatz: DataLoader<VorgaengeFilter, Umsatz> = useCallback(
    async (params) => (await getApi()).umsatz.sucheUmsatz({ ...params }),
    [getApi]
  );

  return (
    <DataTable
      columns={useColumns()}
      request={request}
      queryKey={["umsaetze"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadUmsatz}
      onRowClick={(row: GridRowParams<Umsatz>) =>
        navigate(`${row.row.referenzUrl}`)
      }
    />
  );
}
function useColumns(): Array<GridColDef<Umsatz>> {
  const { t, capitalize } = useT("vorgaenge");

  return useMemo(
    () => [
      {
        field: "buchungsDatum",
        headerName: t("booking-date"),
        flex: 1,
        renderCell: ({ value }) =>
          new Date(value as string).toLocaleDateString(),
      },
      {
        field: "buchungsArt",
        headerName: t("booking-type"),
        flex: 1,
      },
      {
        field: "buchungsTyp",
        headerName: t("booking-short-type"),
        flex: 1,
      },
      {
        field: "referenzTyp",
        headerName: t("reference-type"),
        flex: 1,
      },
      {
        field: "bezeichnung",
        headerName: t("description"),
        flex: 2,
      },
      {
        field: "bemerkung",
        headerName: t("remark"),
        flex: 1,
      },
      {
        field: "betrag",
        headerName: t("total-amount"),
        flex: 1,
        align: "right",
        headerAlign: "right",
        renderCell: ({ value }) =>
          `${(value as number).toFixed(2).replace(".", ",")} €`,
      },
    ],
    [t]
  );
}
