import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  Box,
  Button,
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import {
  useCreateFuhrparkeintrag,
  useCreateFuhrparkeintragAnbauteil,
  useDeleteFuhrparkeintrag,
  useDeleteFuhrparkeintragAnbauteil,
  useGetTaetigkeitsbericht,
  useSucheFuhrparkeintraege,
  useSucheFuhrparkeintragAnbauteil,
  useUpdateFuhrparkeintrag,
  useUpdateFuhrparkeintragAnbauteil,
  useUpdateTaetigkeitsbericht,
} from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import {
  Fuhrparkeintrag,
  FuhrparkeintragAnbauteil,
  Taetigkeitsbericht,
  TaetigkeitsberichtDaten,
} from "../../api";
import TaetigkeitsberichtFieldsContent from "../../components/taetigkeitsberichte/TaetigkeitsberichtFieldsContent";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteTaetigkeitsberichtButton from "../../components/taetigkeitsberichte/DeleteTaetigkeitsberichtButton";
import * as Yup from "yup";
import { ExpandContextProvider } from "../../expand";

const FuhrparkeintragEigenPersonalItem = React.lazy(
  () =>
    import(
      "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragEigenPersonalItem"
    )
);

interface FuhrparkeintragData {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeugId: string;
  fahrzeugHerkunft: string;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: FuhrparkeintragAnbauteil[];
}

interface ExtendedTaetigkeitsberichtDaten {
  mitarbeiterId: string;
  baustelleId: string;
  datum: string;
  arbeitszeitNetto: number;
  stundensatz: number;
  lohnkosten: number;
  bemerkung: string;
  fuhrparkeintraege: FuhrparkeintragData[];
}

const validationSchema = Yup.object().shape({
  mitarbeiterId: yup.string().required(),
  baustelleId: yup.string().required(),
  datum: yup.string().required(),
  arbeitszeitNetto: yup.number().min(0).nullable(),
  bemerkung: yup.string(),
  fuhrparkeintraege: Yup.array()
    .of(
      Yup.object().shape({
        fahrzeugId: yup.mixed().required(),
        fahrzeugNutzungsdauer: yup.number().min(0).required(),
        fahrzeugStundenPreis: yup.number().min(0).required(),
        fahrzeugKosten: yup.number().min(0),
        fuhrparkGesamtKosten: yup.number().min(0),
        bemerkung: yup.string().nullable(),
        fuhrparkeintragAnbauteile: Yup.array().of(
          Yup.object().shape({
            anbauteilId: yup.mixed().required(),
            anbauteilNutzungsdauer: yup.number().min(0).required(),
            anbauteilStundenPreis: yup.number().min(0).required(),
            anbauteilAnzahl: yup.number().min(1).required(),
            anbauteilKosten: yup.number().min(0),
          })
        ),
      })
    )
    .min(0, ""),
});

export default function TaetigkeitsberichtDetails() {
  const { id: taetigkeitsberichtId = "" } = useParams<{
    id: string;
  }>();

  const { t } = useTranslation("taetigkeitsbericht");
  const navigate = useNavigate();
  const getTaetigkeitsbericht = useGetTaetigkeitsbericht();
  const updateTaetigkeitsbericht = useUpdateTaetigkeitsbericht();
  const createFuhrparkeintrag = useCreateFuhrparkeintrag();
  const deleteFuhrparkeintrag = useDeleteFuhrparkeintrag();
  const updateFuhrparkeintrag = useUpdateFuhrparkeintrag();
  const searchFuhrparkeintraege = useSucheFuhrparkeintraege();
  const createFuhrparkeintragAnbauteil = useCreateFuhrparkeintragAnbauteil();
  const updateFuhrparkeintragAnbauteil = useUpdateFuhrparkeintragAnbauteil();
  const searchFuhrparkeintragAnbauteil = useSucheFuhrparkeintragAnbauteil();

  const [fuhrparkeintraege, setFuhrparkeintraege] = useState<
    FuhrparkeintragData[]
  >([]);
  const [taetigkeitsbericht, setTaetigkeitsbericht] =
    useState<Taetigkeitsbericht | null>(null);

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const [dauerVorhanden, setDauerVorhanden] = useState<number>(
    taetigkeitsbericht?.arbeitszeitNetto ?? 0
  );

  useEffect(() => {
    const fetchFuhrparkeintraege = async () => {
      if (taetigkeitsberichtId) {
        const fuhrparkeintraegeData = await searchFuhrparkeintraege(
          taetigkeitsberichtId,
          {
            search: taetigkeitsberichtId,
            page: 0,
            limit: 20,
            sort: "created",
          }
        );

        const completeFuhrparkeintraege = await Promise.all(
          fuhrparkeintraegeData.items.map(async (fuhrparkeintrag) => {
            const fuhrparkeintragAnbauteileData =
              await searchFuhrparkeintragAnbauteil(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                {
                  search: fuhrparkeintrag.id,
                  page: 0,
                  limit: 20,
                  sort: "created",
                }
              );

            return {
              ...fuhrparkeintrag,
              fuhrparkeintragAnbauteile: fuhrparkeintragAnbauteileData.items,
            };
          })
        );

        setFuhrparkeintraege(completeFuhrparkeintraege);
      }
    };
    fetchFuhrparkeintraege();
  }, [taetigkeitsberichtId, searchFuhrparkeintraege]);

  useEffect(() => {
    const fetchTaetigkeitsbericht = async () => {
      if (taetigkeitsberichtId) {
        const data = await getTaetigkeitsbericht(taetigkeitsberichtId);
        setTaetigkeitsbericht(data);
      }
    };
    fetchTaetigkeitsbericht();
  }, [taetigkeitsberichtId, getTaetigkeitsbericht]);

  useEffect(() => {
    var aktuelleVorhandeneDauer = taetigkeitsbericht?.arbeitszeitNetto ?? 0;
    fuhrparkeintraege.map((fuhrparkeintrag) => {
      aktuelleVorhandeneDauer -= fuhrparkeintrag.fahrzeugNutzungsdauer;
    });
    setDauerVorhanden(
      aktuelleVorhandeneDauer > 0 ? aktuelleVorhandeneDauer : 0
    );
  }, [taetigkeitsbericht, fuhrparkeintraege]);

  async function saveFuhrparkeintraege(
    values: ExtendedTaetigkeitsberichtDaten & {
      fuhrparkeintraege: Fuhrparkeintrag[];
    }
  ) {
    try {
      const taetigkeitsbericht = await updateTaetigkeitsbericht(
        taetigkeitsberichtId,
        {
          mitarbeiterId: values.mitarbeiterId,
          baustelleId: values.baustelleId,
          datum: values.datum,
          arbeitszeitNetto: values.arbeitszeitNetto,
          stundensatz: values.stundensatz,
          bemerkung: values.bemerkung,
        }
      );

      const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
        (fuhrparkeintrag) => {
          const fuhrparkeintragData = {
            fahrzeugId: fuhrparkeintrag.fahrzeugId,
            fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
            fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
            fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
            bemerkung: fuhrparkeintrag.bemerkung,
          };
          if (fuhrparkeintrag.id) {
            return updateFuhrparkeintrag(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              fuhrparkeintragData
            );
          } else {
            return createFuhrparkeintrag(
              taetigkeitsberichtId,
              fuhrparkeintragData
            );
          }
        }
      );

      const fuhrparkeintragResults = await Promise.all(fuhrparkeintragPromises);

      const fuhrparkeintragIds = fuhrparkeintragResults.map(
        (result) => result.id
      );

      const fuhrparkeintragAnbauteilPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
            (fuhrparkeintragAnbauteil) => {
              const fuhrparkeintragAnbauteilData = {
                anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                anbauteilNutzungsdauer:
                  fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                anbauteilStundenPreis:
                  fuhrparkeintragAnbauteil.anbauteilStundenPreis,
              };
              if (fuhrparkeintragAnbauteil.id) {
                return updateFuhrparkeintragAnbauteil(
                  taetigkeitsberichtId,
                  currentFuhrparkeintragId,
                  fuhrparkeintragAnbauteil.id,
                  fuhrparkeintragAnbauteilData
                );
              } else {
                return createFuhrparkeintragAnbauteil(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  fuhrparkeintragAnbauteilData
                );
              }
            }
          );
        }
      );

      await Promise.all(fuhrparkeintragAnbauteilPromises);

      const fuhrparkeintraegeData = await searchFuhrparkeintraege(
        taetigkeitsberichtId,
        {
          search: taetigkeitsberichtId,
          page: 0,
          limit: 20,
          sort: "created",
        }
      );

      const completeFuhrparkeintraege = await Promise.all(
        fuhrparkeintraegeData.items.map(async (fuhrparkeintrag) => {
          const fuhrparkeintragAnbauteileData =
            await searchFuhrparkeintragAnbauteil(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );

          return {
            ...fuhrparkeintrag,
            fuhrparkeintragAnbauteile: fuhrparkeintragAnbauteileData.items,
          };
        })
      );

      setFuhrparkeintraege(completeFuhrparkeintraege);
      const data = await getTaetigkeitsbericht(taetigkeitsberichtId);
      setTaetigkeitsbericht(data);
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const onSubmit = useCallback(
    async (
      values: ExtendedTaetigkeitsberichtDaten,
      formikHelpers: FormikHelpers<ExtendedTaetigkeitsberichtDaten>
    ) => {
      const { setSubmitting, setStatus, resetForm } = formikHelpers;
      try {
        // Update Taetigkeitsbericht first
        const updatedTaetigkeitsbericht = await updateTaetigkeitsbericht(
          taetigkeitsberichtId,
          {
            mitarbeiterId: values.mitarbeiterId,
            baustelleId: values.baustelleId,
            datum: values.datum,
            arbeitszeitNetto: values.arbeitszeitNetto,
            stundensatz: values.stundensatz,
            bemerkung: values.bemerkung,
          }
        );

        // Create or update each fuhrparkeintrag
        const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
          (fuhrparkeintrag) => {
            const fuhrparkeintragData = {
              fahrzeugId: fuhrparkeintrag.fahrzeugId,
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
            };
            if (fuhrparkeintrag.id) {
              // Update existing fuhrparkeintrag
              return updateFuhrparkeintrag(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                fuhrparkeintragData
              );
            } else {
              // Create new fuhrparkeintrag
              return createFuhrparkeintrag(
                taetigkeitsberichtId,
                fuhrparkeintragData
              );
            }
          }
        );

        const fuhrparkeintragResults = await Promise.all(
          fuhrparkeintragPromises
        );

        const fuhrparkeintragIds = fuhrparkeintragResults.map(
          (result) => result.id
        );

        const fuhrparkeintragAnbauteilPromises =
          values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
              (fuhrparkeintragAnbauteil) => {
                const fuhrparkeintragAnbauteilData = {
                  anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                  anbauteilNutzungsdauer:
                    fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                  anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  anbauteilStundenPreis:
                    fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                };
                if (fuhrparkeintragAnbauteil.id) {
                  return updateFuhrparkeintragAnbauteil(
                    taetigkeitsberichtId,
                    currentFuhrparkeintragId,
                    fuhrparkeintragAnbauteil.id,
                    fuhrparkeintragAnbauteilData
                  );
                } else {
                  return createFuhrparkeintragAnbauteil(
                    updatedTaetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    fuhrparkeintragAnbauteilData
                  );
                }
              }
            );
          });

        await Promise.all(fuhrparkeintragAnbauteilPromises);

        navigate(`/taetigkeitsberichte/`, { replace: true });
        setStatus(undefined);
        const fuhrparkeintraegeData = await searchFuhrparkeintraege(
          taetigkeitsberichtId,
          {
            search: taetigkeitsberichtId,
            page: 0,
            limit: 20,
            sort: "created",
          }
        );

        const completeFuhrparkeintraege = await Promise.all(
          fuhrparkeintraegeData.items.map(async (fuhrparkeintrag) => {
            const fuhrparkeintragAnbauteileData =
              await searchFuhrparkeintragAnbauteil(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                {
                  search: fuhrparkeintrag.id,
                  page: 0,
                  limit: 20,
                  sort: "created",
                }
              );

            return {
              ...fuhrparkeintrag,
              fuhrparkeintragAnbauteile: fuhrparkeintragAnbauteileData.items,
            };
          })
        );

        setFuhrparkeintraege(completeFuhrparkeintraege);
        const data = await getTaetigkeitsbericht(taetigkeitsberichtId);
        setTaetigkeitsbericht(data);
        resetForm({
          values: {
            ...values,
            // @ts-ignore
            fuhrparkeintraege: fuhrparkeintraege.map((fuhrparkeintrag) => ({
              id: fuhrparkeintrag.id,
              fahrzeugId: fuhrparkeintrag.fahrzeugId,
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
              fuhrparkeintragAnbauteile:
                fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
                  (fuhrparkeintragAnbauteil) => ({
                    id: fuhrparkeintragAnbauteil.id,
                    anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                    anbauteilNutzungsdauer:
                      fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                    anbauteilStundenPreis:
                      fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                    anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  })
                ),
            })),
          },
        });
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [
      navigate,
      updateTaetigkeitsbericht,
      createFuhrparkeintrag,
      updateFuhrparkeintrag,
      taetigkeitsberichtId,
    ]
  );

  if (!taetigkeitsbericht) {
    return <div>Loading...</div>;
  }

  return (
    <Layout
      title={capitalize(t("activity-report"))}
      back="/taetigkeitsberichte"
    >
      <Paper
        elevation={1}
        sx={{
          marginTop: 2,
          padding: 3,
          marginLeft: 2,
          marginRight: 2,
          position: "relative",
        }}
      >
        {taetigkeitsbericht.deleted === undefined ? (
          <DeleteTaetigkeitsberichtButton
            id={taetigkeitsberichtId}
          ></DeleteTaetigkeitsberichtButton>
        ) : (
          <></>
        )}
        <Formik
          enableReinitialize={true}
          initialValues={{
            mitarbeiterId: taetigkeitsbericht?.mitarbeiterId ?? "",
            baustelleId: taetigkeitsbericht?.baustelleId ?? "",
            datum: taetigkeitsbericht?.datum ?? "",
            arbeitszeitNetto: taetigkeitsbericht?.arbeitszeitNetto ?? 0,
            stundensatz: taetigkeitsbericht?.stundensatz ?? 0,
            lohnkosten: taetigkeitsbericht?.lohnkosten ?? 0,
            bemerkung: taetigkeitsbericht?.bemerkung ?? "",
            // @ts-ignore
            fuhrparkeintraege: fuhrparkeintraege.map((fuhrparkeintrag) => ({
              id: fuhrparkeintrag.id,
              fahrzeugId: fuhrparkeintrag.fahrzeugId,
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
              fuhrparkeintragAnbauteile:
                fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
                  (fuhrparkeintragAnbauteil) => ({
                    id: fuhrparkeintragAnbauteil.id,
                    anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                    anbauteilNutzungsdauer:
                      fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                    anbauteilStundenPreis:
                      fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                    anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  })
                ),
            })),
          }}
          validationSchema={validationSchema}
          // @ts-ignore
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty, values }) => (
            <>
              <Typography variant="h5">
                {capitalize(t("activity-report"))}
                {taetigkeitsbericht.deleted === undefined ? (
                  ""
                ) : (
                  <Chip
                    size="small"
                    icon={<DeleteIcon />}
                    label={"gelöscht"}
                    color="error"
                  />
                )}
              </Typography>
              <DialogContent>
                <Form id="details-taetigkeitsbericht">
                  <TaetigkeitsberichtFieldsContent isNew={false} />
                  <FieldArray name="fuhrparkeintraege">
                    {({ push, remove }) => (
                      <>
                        {values.fuhrparkeintraege.map(
                          (fuhrparkeintrag, index) => (
                            <Box key={index} sx={{ marginTop: 2 }}>
                              <Box
                                display={"grid"}
                                sx={{
                                  gridTemplateColumns: ["1fr", "10fr .1fr"],
                                  gap: 2,
                                  marginTop: 2,
                                }}
                              >
                                <ExpandContextProvider
                                  id={`fuhrparkeintrag-${index}`}
                                >
                                  <FuhrparkeintragEigenPersonalItem
                                    isNew={!fuhrparkeintrag.id}
                                    // @ts-ignore
                                    fuhrparkeintrag={fuhrparkeintrag}
                                    index={index}
                                    expanded={expandedIndex === index}
                                    handleChange={handleAccordionChange}
                                    taetigkeitsberichtDatum={values.datum}
                                    dauerVorhanden={dauerVorhanden}
                                    isValidating={isValidating}
                                    isValid={isValid}
                                  />
                                  <IconButton
                                    onClick={() => {
                                      const fuhrparkeintragId =
                                        values.fuhrparkeintraege[index].id;
                                      if (fuhrparkeintragId) {
                                        deleteFuhrparkeintrag(
                                          taetigkeitsberichtId,
                                          fuhrparkeintragId
                                        ).then(() => {
                                          remove(index);
                                        });
                                      } else {
                                        remove(index);
                                      }
                                    }}
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ExpandContextProvider>
                              </Box>
                            </Box>
                          )
                        )}
                        <Box sx={{ marginTop: 2 }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              var werte = 0;

                              const newEntry = {
                                fahrzeugId: "",
                                fahrzeugHerkunft: "",
                                fahrzeugStundenPreis: 0,
                                fahrzeugNutzungsdauer: 0,
                                bemerkung: "",
                                fuhrparkeintragAnbauteile: [
                                  {
                                    anbauteilId: "",
                                    anbauteilNutzungsdauer: 0,
                                    anbauteilAnzahl: 1,
                                    anbauteilStundenPreis: 0,
                                    anbauteilKosten: 0,
                                  },
                                ],
                              };

                              values.fuhrparkeintraege.map(() => {
                                werte++;
                              });

                              if (werte > 0) {
                                // @ts-ignore
                                saveFuhrparkeintraege(values).then(() => {
                                  push(newEntry);
                                });
                              } else {
                                push(newEntry);
                              }
                            }}
                            disabled={isValidating || !isValid}
                          >
                            {capitalize(t("add-carpool"))}
                          </Button>
                        </Box>
                      </>
                    )}
                  </FieldArray>
                </Form>
              </DialogContent>
              <DialogActions>
                <SubmitButton
                  form="details-taetigkeitsbericht"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Layout>
  );
}

function useSaveTaetigkeitsbericht(id: string) {
  const updateTaetigkeitsbericht = useUpdateTaetigkeitsbericht();
  const queryClient = useQueryClient();

  return useMutation(
    (input: TaetigkeitsberichtDaten) => {
      return updateTaetigkeitsbericht(id, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["taetigkeitsberichte"]);
      },
    }
  );
}
