// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ApiError, type Me } from "../../api";
import { useMe as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useMe(
  options: Omit<UseQueryOptions<Me, ApiError>, "queryKey" | "queryFn"> = {}
): Me {
  const getter = useGetter();
  return useQuery(["me"], () => getter(), options).data!;
}
