import { Form, Formik, FormikHelpers } from "formik";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { ZugewiesenesFahrzeugAnbauteilDaten } from "../../../api";
import ZuweisungAnbauteilFieldsContent from "../../../components/fuhrpark/fahrzeug/ZuweisungAnbauteilFieldsContent";
import {
  useAssignAnbauteilZuFahrzeug,
  useGetFahrzeugAnbauteil,
} from "../../../client/hooks";
import { useFahrzeugAnbauteil } from "../../../client/queries";

interface WeiseAnbauteilFahrzeugZuProps {
  onClose: () => void;
  zugewiesenesAnbauteilId: string;
  anbauteilId: string;
}

export default function NeuerKostensatzAnbauteilZuFahrzeug({
  onClose,
  anbauteilId,
  zugewiesenesAnbauteilId,
}: WeiseAnbauteilFahrzeugZuProps) {
  const { t } = useTranslation("fuhrpark");

  const { id: fahrzeugId = "" } = useParams<{
    id: string;
  }>();

  const { mutateAsync } = useAssignAnbauteilFahrzeugZu(fahrzeugId, anbauteilId);
  let schema = yup.object().shape({
    kostenStunde: yup
      .number()
      .min(0)
      .required(capitalize(t("assignment-required-cost-hour"))),
  });
  const zugewiesenesLetztesAnbauteil = useFahrzeugAnbauteil(
    zugewiesenesAnbauteilId
  );
  const onSubmit = useCallback(
    async (
      values: {
        kostenStunde: number;
      },
      formikHelpers: FormikHelpers<{
        kostenStunde: number;
      }>
    ) => {
      const { kostenStunde } = values;
      const { setSubmitting, setStatus } = formikHelpers;
      const zugewiesenesAnbauteilZuFahrzeug = await mutateAsync({
        kostenStunde: values.kostenStunde,
      });
      onClose();
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [mutateAsync, onClose]
  );
  return (
    <Paper
      elevation={1}
      sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
    >
      <Formik
        initialValues={{
          kostenStunde: zugewiesenesLetztesAnbauteil?.kostenStunde || 0.0,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValidating, isValid, dirty }) => (
          <>
            <Typography variant="h5">{t("new-assignment-costset")}</Typography>
            <DialogContent>
              <Form id="weise-anbauteil-zu">
                <ZuweisungAnbauteilFieldsContent />
              </Form>
            </DialogContent>
            <DialogActions>
              <SubmitButton
                form="weise-anbauteil-zu"
                type="submit"
                variant="contained"
                color="secondary"
                loading={isSubmitting}
                disabled={isValidating || !isValid || !dirty}
              >
                {capitalize(t("save"))}
              </SubmitButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Paper>
  );
}

function useAssignAnbauteilFahrzeugZu(fahrzeugId: string, anbauteilId: string) {
  const assignAnbauteilFahrzeugZu = useAssignAnbauteilZuFahrzeug();
  const queryClient = useQueryClient();

  return useMutation(
    (input: ZugewiesenesFahrzeugAnbauteilDaten) => {
      return assignAnbauteilFahrzeugZu(fahrzeugId, anbauteilId, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["anbauteil-fahrzeug"]);
      },
    }
  );
}
