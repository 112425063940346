/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { checkFahrzeugMessage } from '../models/checkFahrzeugMessage';
import type { Fahrzeug } from '../models/Fahrzeug';
import type { FahrzeugDaten } from '../models/FahrzeugDaten';
import type { FahrzeugePage } from '../models/FahrzeugePage';
import type { FahrzeugKennzeichen } from '../models/FahrzeugKennzeichen';
import type { FahrzeugKosten } from '../models/FahrzeugKosten';
import type { FahrzeugKostenPage } from '../models/FahrzeugKostenPage';
import type { HerkunftTyp } from '../models/HerkunftTyp';
import type { Timestamp } from '../models/Timestamp';
import type { ZugewiesenesFahrzeugAnbauteil } from '../models/ZugewiesenesFahrzeugAnbauteil';
import type { ZugewiesenesFahrzeugAnbauteilDaten } from '../models/ZugewiesenesFahrzeugAnbauteilDaten';
import type { ZugewiesenesFahrzeugAnbauteilPage } from '../models/ZugewiesenesFahrzeugAnbauteilPage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FahrzeugeService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Fahrzeuge.
   * @returns FahrzeugePage Die Seite der Fahrzeuge.
   * @throws ApiError
   */
  public sucheFahrzeuge({
    fahrzeugHerkunft,
    search,
    page,
    limit,
    sort,
  }: {
    fahrzeugHerkunft?: HerkunftTyp,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<FahrzeugePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/fahrzeuge',
      query: {
        'fahrzeugHerkunft': fahrzeugHerkunft,
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Fahrzeug das angelegte Fahrzeug
   * @throws ApiError
   */
  public createFahrzeug({
    requestBody,
  }: {
    requestBody: FahrzeugDaten,
  }): CancelablePromise<Fahrzeug> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/fahrzeuge',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Bekomme ein bestimmtes Fahrzeug.
   * @returns Fahrzeug Das Fahrzeug.
   * @throws ApiError
   */
  public getFahrzeug({
    fahrzeugId,
  }: {
    fahrzeugId: string,
  }): CancelablePromise<Fahrzeug> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/fahrzeuge/{fahrzeugId}',
      path: {
        'fahrzeugId': fahrzeugId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Fahrzeug das aktualisierte Fahrzeug
   * @throws ApiError
   */
  public updateFahrzeug({
    fahrzeugId,
    requestBody,
  }: {
    fahrzeugId: string,
    requestBody: FahrzeugDaten,
  }): CancelablePromise<Fahrzeug> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/fahrzeuge/{fahrzeugId}',
      path: {
        'fahrzeugId': fahrzeugId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Fahrzeug das gelöschte Fahrzeug
   * @throws ApiError
   */
  public deleteFahrzeug({
    fahrzeugId,
  }: {
    fahrzeugId: string,
  }): CancelablePromise<Fahrzeug> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/fahrzeuge/{fahrzeugId}',
      path: {
        'fahrzeugId': fahrzeugId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Bekomme die Kosten für ein bestimmtes Fahrzeug.
   * @returns FahrzeugKostenPage Die Kosten für das Fahrzeug.
   * @throws ApiError
   */
  public getFahrzeugKosten({
    fahrzeugId,
    search,
    page,
    limit,
    sort,
  }: {
    fahrzeugId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<FahrzeugKostenPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/fahrzeuge/{fahrzeugId}/costs',
      path: {
        'fahrzeugId': fahrzeugId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns checkFahrzeugMessage checks if a Fahrzeug already exists
   * @throws ApiError
   */
  public checkIfFahrzeugExists({
    requestBody,
  }: {
    requestBody: FahrzeugKennzeichen,
  }): CancelablePromise<checkFahrzeugMessage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/fahrzeuge-check',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle zugewiesenen Anbauteile zu einem Fahrzeug.
   * @returns ZugewiesenesFahrzeugAnbauteilPage Die Seite der zugewiesenen Anbauteile zu einem Fahrzeug.
   * @throws ApiError
   */
  public sucheZugewieseneFahrzeugAnbauteile({
    fahrzeugId,
    search,
    page,
    limit,
    sort,
  }: {
    fahrzeugId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<ZugewiesenesFahrzeugAnbauteilPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/fahrzeuge/{fahrzeugId}/anbauteile',
      path: {
        'fahrzeugId': fahrzeugId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns ZugewiesenesFahrzeugAnbauteil das zugewiesene Fahrzeug Anbauteil
   * @throws ApiError
   */
  public assignAnbauteilZuFahrzeug({
    fahrzeugId,
    anbauteilId,
    requestBody,
  }: {
    fahrzeugId: string,
    anbauteilId: string,
    requestBody: ZugewiesenesFahrzeugAnbauteilDaten,
  }): CancelablePromise<ZugewiesenesFahrzeugAnbauteil> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/fahrzeuge/{fahrzeugId}/anbauteile/{anbauteilId}',
      path: {
        'fahrzeugId': fahrzeugId,
        'anbauteilId': anbauteilId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Bekomme die Kosten für ein bestimmtes, zugewiesenes Fahrzeug Anbauteil.
   * @returns ZugewiesenesFahrzeugAnbauteilPage Die Kosten für das zugewiesene Fahrzeug Anbauteil.
   * @throws ApiError
   */
  public getFahrzeugAnbauteilKosten({
    fahrzeugId,
    anbauteilId,
    search,
    page,
    limit,
    sort,
  }: {
    fahrzeugId: string,
    anbauteilId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<ZugewiesenesFahrzeugAnbauteilPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/fahrzeuge/{fahrzeugId}/anbauteile/{anbauteilId}/costs',
      path: {
        'fahrzeugId': fahrzeugId,
        'anbauteilId': anbauteilId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Bekomme die Kosten für ein bestimmtes Datum, eines zugewiesenen Anbauteils.
   * @returns ZugewiesenesFahrzeugAnbauteil Die Kosten für ein bestimmtes Datum, eines zugewiesenen Anbauteils.
   * @throws ApiError
   */
  public getFahrzeugAnbauteilKostenFuerBestimmtesDatum({
    fahrzeugId,
    anbauteilId,
    requestBody,
  }: {
    fahrzeugId: string,
    anbauteilId: string,
    requestBody: {
      date: Timestamp;
    },
  }): CancelablePromise<ZugewiesenesFahrzeugAnbauteil> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/fahrzeuge/{fahrzeugId}/anbauteile/{anbauteilId}/costs/date',
      path: {
        'fahrzeugId': fahrzeugId,
        'anbauteilId': anbauteilId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Bekomme die Fahrzeugkosten für ein Datum
   * @returns FahrzeugKosten Die Fahrzeugkosten für ein Datum
   * @throws ApiError
   */
  public getFahrzeugKostenFuerDatum({
    fahrzeugId,
    requestBody,
  }: {
    fahrzeugId: string,
    requestBody: {
      date: Timestamp;
    },
  }): CancelablePromise<FahrzeugKosten> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/fahrzeuge/{fahrzeugId}/costs/date',
      path: {
        'fahrzeugId': fahrzeugId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Bekomme ein bestimmtes, zugewiesenes Fahrzeug Anbauteil.
   * @returns ZugewiesenesFahrzeugAnbauteil Das zugewiesene Fahrzeug Anbauteil.
   * @throws ApiError
   */
  public getFahrzeugAnbauteil({
    zugewiesenesAnbauteilId,
  }: {
    zugewiesenesAnbauteilId: string,
  }): CancelablePromise<ZugewiesenesFahrzeugAnbauteil> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/zugewiesene-anbauteile/{zugewiesenesAnbauteilId}',
      path: {
        'zugewiesenesAnbauteilId': zugewiesenesAnbauteilId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns ZugewiesenesFahrzeugAnbauteil das gelöschte zugewiesene Anbauteil
   * @throws ApiError
   */
  public deleteZugewiesenesAnbauteil({
    zugewiesenesAnbauteilId,
  }: {
    zugewiesenesAnbauteilId: string,
  }): CancelablePromise<ZugewiesenesFahrzeugAnbauteil> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/zugewiesene-anbauteile/{zugewiesenesAnbauteilId}',
      path: {
        'zugewiesenesAnbauteilId': zugewiesenesAnbauteilId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
