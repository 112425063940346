import { createContext, useContext, type ReactNode } from "react";
import { type Me } from "./api";
import { useMe } from "./client/queries";
import PermissionsContextProvider from "./components/PermissionsContext";

export type UserContext = Me;

const Context = createContext<UserContext | undefined>(undefined);

export function UserProvider({ children }: { children: ReactNode }) {
  const me = useMe({ staleTime: 5 * 60 * 1000 });

  return (
    <Context.Provider value={me}>
      <PermissionsContextProvider permissions={me.permissions}>
        {children}
      </PermissionsContextProvider>
    </Context.Provider>
  );
}

export function useUser(): UserContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
