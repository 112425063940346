// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type UmsatzAuswertungListe } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useSucheUmsatzAuswertungByBaustellenId(): (
  baustellenId: string
) => Promise<UmsatzAuswertungListe> {
  const getAPI = useGetApi();
  return useCallback(
    async (baustellenId) =>
      (await getAPI()).umsatz.sucheUmsatzAuswertungByBaustellenId({
        baustellenId,
      }),
    [getAPI]
  );
}
