/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Anbauteil } from '../models/Anbauteil';
import type { AnbauteilDaten } from '../models/AnbauteilDaten';
import type { AnbauteilPage } from '../models/AnbauteilPage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AnbauteileService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle noch nicht benutzen Anbauteile zu einem Fahrzeug.
   * @returns AnbauteilPage Die Seite der noch nicht benutzen Anbauteile zu einem Fahrzeug.
   * @throws ApiError
   */
  public sucheUnbenutzeFahrzeugAnbauteile({
    fahrzeugId,
    search,
    page,
    limit,
    sort,
  }: {
    fahrzeugId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<AnbauteilPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/anbauteile/unbenutzt/fahrzeug/{fahrzeugId}',
      path: {
        'fahrzeugId': fahrzeugId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Anbauteile.
   * @returns AnbauteilPage Die Seite der Anbauteile.
   * @throws ApiError
   */
  public sucheAnbauteile({
    search,
    page,
    limit,
    sort,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<AnbauteilPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/anbauteile',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Anbauteil das angelegte Anbauteil
   * @throws ApiError
   */
  public createAnbauteil({
    requestBody,
  }: {
    requestBody: AnbauteilDaten,
  }): CancelablePromise<Anbauteil> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/anbauteile',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Bekomme ein bestimmtes Anbauteil.
   * @returns Anbauteil Das Anbauteil.
   * @throws ApiError
   */
  public getAnbauteil({
    anbauteilId,
  }: {
    anbauteilId: string,
  }): CancelablePromise<Anbauteil> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/anbauteile/{anbauteilId}',
      path: {
        'anbauteilId': anbauteilId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Anbauteil das aktualisierte Anbauteil
   * @throws ApiError
   */
  public updateAnbauteil({
    anbauteilId,
    requestBody,
  }: {
    anbauteilId: string,
    requestBody: AnbauteilDaten,
  }): CancelablePromise<Anbauteil> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/anbauteile/{anbauteilId}',
      path: {
        'anbauteilId': anbauteilId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Anbauteil das gelöschte Anbauteil
   * @throws ApiError
   */
  public deleteAnbauteil({
    anbauteilId,
  }: {
    anbauteilId: string,
  }): CancelablePromise<Anbauteil> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/anbauteile/{anbauteilId}',
      path: {
        'anbauteilId': anbauteilId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
