import React from "react";
import "../styles/datev-button.css";

interface DatevButtonProps {
  children: string;
  loading: boolean;
  onClick: () => void;
}

const DatevButton: React.FC<DatevButtonProps> = ({ loading, onClick }) => {
  return (
    <button className="datev-btn" onClick={onClick}>
      {!loading && (
        <img
          src={"pi-datev-logo-farbig-24.svg"}
          alt="Logo"
          className="datev-btn__logo"
        />
      )}
      <span>{loading ? "Laden..." : "Anmelden mit DATEV"}</span>
    </button>
  );
};

export default DatevButton;
