// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import {
  type FuhrparkeintragAnbauteil,
  FuhrparkeintragAnbauteilDaten,
} from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useCreateFuhrparkeintragAnbauteil(): (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  requestBody: FuhrparkeintragAnbauteilDaten
) => Promise<FuhrparkeintragAnbauteil> {
  const getAPI = useGetApi();
  return useCallback(
    async (taetigkeitsberichtId, fuhrparkeintragId, requestBody) =>
      (await getAPI()).fuhrparkeintraganbauteile.createFuhrparkeintragAnbauteil(
        { taetigkeitsberichtId, fuhrparkeintragId, requestBody }
      ),
    [getAPI]
  );
}
