import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Field, FieldArray, useFormikContext } from "formik";
import BaustellenSelect from "../baustellen/BaustellenSelect";
import BenutzerSelect from "../benutzer/BenutzerSelect";
import { TextField } from "formik-mui";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ZugewieseneRechnungBaustelle } from "../../api";
import { useEffect, useState } from "react";
import {
  useDeleteZugewieseneBaustelle,
  useGetZugewieseneBaustellen,
  useUpdateZugewieseneBaustelle,
} from "../../client/hooks";
import SearchField from "../SearchField";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useQueryClient } from "react-query";
interface ZugewieseneBaustelleUpdated {
  id: string;
  rechnungsId: string | undefined;
  baustellenId: string | undefined;
  prueferId: string | undefined;
  pruefStatus: string | undefined;
  betrag: string;
  leistung: string | undefined;
}
const formatNumber = (value: number | undefined) => {
  // If the value is null, undefined, or NaN, return an empty string
  if (value == null || isNaN(value)) return "";

  // Format the number with exactly two decimal places
  return value
    .toFixed(2)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
export default function BaustellenEintrag({
  baustellenEintraege,
  rechnungId,
}: {
  baustellenEintraege: ZugewieseneBaustelleUpdated[];
  rechnungId: string;
}) {
  const fetchZugewieseneBaustellen = useGetZugewieseneBaustellen();
  const queryClient = useQueryClient();
  const { setFieldValue } = useFormikContext();
  const deleteZuweisung = useDeleteZugewieseneBaustelle();
  const updateZuweisung = useUpdateZugewieseneBaustelle();
  useEffect(() => {
    const fetchZugewiesene = async () => {
      const baustellenData = await fetchZugewieseneBaustellen(rechnungId, {
        search: "",
        page: 0,
        limit: 20,
      });
      console.log("Baustellen items:" + baustellenData.items.length);
      if (baustellenData.items.length > 0) {
        await setFieldValue(
          "baustellenEintrag",
          baustellenData.items.map((item) => {
            return {
              id: item.id,
              rechnungsId: item.rechnungsId,
              baustellenId: item.baustellenId,
              prueferId: item.prueferId,
              pruefStatus: item.pruefStatus,
              betrag: formatNumber(item.betrag),
              leistung: item.leistung,
            };
          })
        );
      }
    };
    fetchZugewiesene();
  }, [rechnungId]);

  async function saveZuweisung(
    rechnungId: string,
    baustelle: ZugewieseneBaustelleUpdated,
    status: string
  ) {
    if (baustelle.id) {
      const formattedBetrag = parseFloat(
        String(baustelle.betrag).replace(".", "").replace(",", ".")
      );
      updateZuweisung(rechnungId, baustelle.id, {
        baustellenId: baustelle.baustellenId,
        prueferId: baustelle.prueferId,
        leistung: baustelle.leistung,
        betrag: formattedBetrag,
        pruefStatus: status,
      });
      baustellenEintraege = baustellenEintraege.map((item) => {
        if (item.id === baustelle.id) {
          item.pruefStatus = status;
        }
        return item;
      });
      const anyAbgelehnt = baustellenEintraege.some(
        (item) => item.pruefStatus === "abgelehnt"
      );

      if (anyAbgelehnt) {
        setFieldValue("pruefStatus", "GEPRUEFT_NICHT_OK");
      } else {
        const allFreigegeben = baustellenEintraege.every(
          (item) => item.pruefStatus === "freigegeben"
        );
        console.log("All freigegeben:" + allFreigegeben);
        baustellenEintraege.map((item) =>
          console.log("Item:" + item.pruefStatus)
        );
        if (allFreigegeben) {
          setFieldValue("pruefStatus", "GEPRUEFT_OK");
        }
      }
    }
  }
  return (
    <FieldArray name="baustellenEintrag">
      {({ push, remove }) => (
        <>
          {baustellenEintraege ? (
            baustellenEintraege.map((baustelle, index) => (
              <>
                <Box
                  sx={{
                    display: "grid",
                    gap: 2,
                    gridTemplateColumns: ["1fr", "2fr  1fr 1fr"],
                  }}
                >
                  <Field
                    component={BaustellenSelect}
                    name={`baustellenEintrag.${index}.baustellenId`}
                  ></Field>
                  <Field
                    component={BenutzerSelect}
                    name={`baustellenEintrag.${index}.prueferId`}
                  ></Field>
                  <Field
                    component={TextField}
                    name={`baustellenEintrag.${index}.betrag`}
                    label={"Nettobetrag in €"}
                  ></Field>
                  <Field
                    component={TextField}
                    name={`baustellenEintrag.${index}.leistung`}
                    label={"Leistung"}
                  ></Field>
                  {baustelle.pruefStatus !== "offen" ? (
                    <Field
                      component={TextField}
                      name={`baustellenEintrag.${index}.pruefStatus`}
                      label={"Prüfstatus"}
                      inputProps={{ readOnly: true }}
                    ></Field>
                  ) : (
                    <></>
                  )}
                  <IconButton
                    sx={{ width: "30px" }}
                    onClick={async () => {
                      if (baustelle.id) {
                        await deleteZuweisung(rechnungId, baustelle.id);
                      }
                      remove(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                      Tätigkeitsberichte
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <SearchField onChange={() => {}}></SearchField>
                        <Table size={"small"}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Zuordnen</TableCell>
                              <TableCell>Datum</TableCell>
                              <TableCell>Abladestelle</TableCell>
                              <TableCell>Menge</TableCell>
                              <TableCell>Einheiten</TableCell>
                              <TableCell>Anzahl Fahrten</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell padding="none">
                                <Checkbox />
                              </TableCell>
                              <TableCell padding="none">01.01.2021</TableCell>
                              <TableCell sx={{ paddingLeft: "15px" }}>
                                Deponie
                              </TableCell>
                              <TableCell sx={{ paddingLeft: "15px" }}>
                                1
                              </TableCell>
                              <TableCell sx={{ paddingLeft: "15px" }}>
                                TO
                              </TableCell>
                              <TableCell sx={{ paddingLeft: "15px" }}>
                                1
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell padding="none">
                                <Checkbox />
                              </TableCell>
                              <TableCell padding="none">01.01.2021</TableCell>
                              <TableCell sx={{ paddingLeft: "15px" }}>
                                Deponie
                              </TableCell>
                              <TableCell sx={{ paddingLeft: "15px" }}>
                                1
                              </TableCell>
                              <TableCell sx={{ paddingLeft: "15px" }}>
                                TO
                              </TableCell>
                              <TableCell sx={{ paddingLeft: "15px" }}>
                                1
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end", // Aligns button to the right
                    mt: 2,
                  }}
                >
                  {baustelle.pruefStatus === "in Prüfung" ? (
                    <>
                      <Button
                        variant={"outlined"}
                        sx={{ height: "70%" }}
                        onClick={() => {
                          setFieldValue(
                            `baustellenEintrag.${index}.pruefStatus`,
                            "abgelehnt"
                          );
                          saveZuweisung(rechnungId, baustelle, "abgelehnt");
                        }}
                      >
                        Ablehnen
                      </Button>
                      <Button
                        variant={"contained"}
                        sx={{ height: "70%", ml: 2 }}
                        color={"secondary"}
                        onClick={() => {
                          setFieldValue(
                            `baustellenEintrag.${index}.pruefStatus`,
                            "freigegeben"
                          );
                          saveZuweisung(rechnungId, baustelle, "freigegeben");
                        }}
                      >
                        Freigeben
                      </Button>
                    </>
                  ) : baustelle.pruefStatus === "freigegeben" ? (
                    <Button
                      variant={"outlined"}
                      sx={{ height: "70%" }}
                      color={"success"}
                      onClick={() => {
                        setFieldValue(
                          `baustellenEintrag.${index}.pruefStatus`,
                          "in Prüfung"
                        );
                        saveZuweisung(rechnungId, baustelle, "in Prüfung");
                      }}
                    >
                      Freigegeben
                    </Button>
                  ) : baustelle.pruefStatus === "abgelehnt" ? (
                    <Button
                      variant={"outlined"}
                      sx={{ height: "70%" }}
                      color={"error"}
                      onClick={() => {
                        setFieldValue(
                          `baustellenEintrag.${index}.pruefStatus`,
                          "in Prüfung"
                        );
                        saveZuweisung(rechnungId, baustelle, "in Prüfung");
                      }}
                    >
                      Abgelehnt
                    </Button>
                  ) : (
                    <></>
                  )}
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }}></Divider>
              </>
            ))
          ) : (
            <></>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end", // Aligns button to the right
              mt: 2, // Adds some margin to the top to separate it from the fields above
            }}
          >
            <Button
              variant="contained"
              color={"secondary"}
              onClick={() => {
                setFieldValue("pruefStatus", "OFFEN");
                push({
                  baustellenId: "",
                  prueferId: "",
                  pruefStatus: "offen",
                });
              }}
            >
              <AddIcon />
            </Button>
          </Box>
        </>
      )}
    </FieldArray>
  );
}
