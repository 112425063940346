import { ListItem, ListItemIcon, SxProps } from "@mui/material";
import { type PropsWithChildren, type ReactNode } from "react";
import LabelValue from "./LabelValue";

export type Props = PropsWithChildren<{
  label: ReactNode;
  icon?: ReactNode;
  sx?: SxProps;
  indent?: boolean;
}>;

export default function LabelListItem({ icon, label, sx, children }: Props) {
  return (
    <ListItem sx={sx}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <LabelValue label={label} sx={{ flex: "1 1 auto", my: 0.75 }}>
        {children}
      </LabelValue>
    </ListItem>
  );
}
