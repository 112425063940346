import {SVGAttributes} from "react";

export default function Logo_white(props: SVGAttributes<SVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 350 100"
            fill={"white"}
            {...props}
        >
            <defs></defs>
            <g>
                <rect x="160.04" y="16.99" width="9.97" height="38.82"/>
                <rect x="173.43" y="16.99" width="9.97" height="38.82"/>
                <path d="M239,25.44h-13.56c-3.85,0-6.97,3.12-6.97,6.97v23.4h9.97v-21.28h10.56v-9.09Z"/>
                <path 
                      d="M141.83,46.8c-.75,0-1.31-.83-.07-1.47l16.02-8.22c-1.37-7.33-7.79-12.88-15.52-12.88-8.72,0-15.79,7.07-15.79,15.79s7.07,15.79,15.79,15.79c.17,0,.33-.02.5-.03h0s14.47.01,14.47.01v-9.01h-15.4ZM142.76,33.58c1,0,1.94.23,2.78.64l-9.11,4.68c.53-3.02,3.17-5.32,6.33-5.32Z"/>
                <path 
                      d="M200.2,46.8c-.75,0-1.31-.83-.07-1.47l16.02-8.22c-1.37-7.33-7.79-12.88-15.52-12.88-8.72,0-15.79,7.07-15.79,15.79s7.07,15.79,15.79,15.79c.17,0,.33-.02.5-.03h0s14.47.01,14.47.01v-9.01h-15.4ZM201.14,33.58c1,0,1.94.23,2.78.64l-9.11,4.68c.53-3.02,3.17-5.32,6.33-5.32Z"/>
                <path 
                      d="M127.17,19.7c-1.36-1.62-3.04-2.7-5.35-2.7h-21.93l-7.9,8.25h22.69s-19.54,20.4-19.54,20.4c-1.29,1.45-1.32,4.95-.23,6.92,1.14,2.05,3.2,3.25,5.63,3.25h26.65v-8.88h-18.51s12.56-12.78,18.94-19.48c1.3-1.36,1.08-5.93-.46-7.76Z"/>
                <polygon  points="29.13 16.99 21.23 25.24 88.95 25.24 96.85 16.99 29.13 16.99"/>
                <path 
                      d="M324.44,57.58s-223.9-.06-223.9-.06c-3.02,0-5.68-1.54-7.12-4.13-1.36-2.44-1.45-6.76.45-8.88l16.86-17.59h-20.34s-2.66,2.76-2.66,2.76h16.41s-12.38,12.93-12.38,12.93c-2.79,3.12-2.67,8.8-.81,12.15,1.94,3.5,5.53,5.58,9.59,5.58,0,0,223.9.06,223.9.06v-2.83Z"/>
                <polygon  points="87.41 26.91 19.63 26.91 16.97 29.69 80.66 29.68 84.8 29.68 87.41 26.91"/>
            </g>
            <g>
                <path 
                      d="M101.96,72.97c0,.52-.1.95-.3,1.29s-.47.6-.8.79c-.33.19-.71.32-1.15.39-.43.07-.88.11-1.35.11h-3.98v-9.52h3.98c.48,0,.93.03,1.34.1s.76.19,1.05.37c.29.18.52.42.68.72.16.3.25.69.25,1.17,0,1.24-.68,1.96-2.03,2.16.78.07,1.37.29,1.74.67s.57.96.57,1.76ZM99.5,68.64c0-.45-.11-.76-.32-.92-.21-.17-.57-.25-1.08-.25h-1.54v2.42h1.64c.49,0,.83-.12,1.02-.36.19-.24.28-.53.28-.89ZM99.71,72.71c0-.29-.04-.53-.1-.71s-.17-.33-.29-.44c-.13-.11-.28-.18-.46-.22s-.38-.06-.6-.06h-1.69v2.83h1.47c.58,0,1-.09,1.27-.27.27-.18.41-.55.41-1.11Z"/>
                <path 
                      d="M109.76,75.25c-.39.11-.88.22-1.48.32-.59.1-1.23.15-1.93.15-.6,0-1.1-.04-1.5-.13-.4-.09-.73-.22-.97-.41-.25-.18-.42-.41-.52-.69-.1-.28-.15-.61-.15-.99,0-.34.06-.64.18-.92.12-.28.31-.52.59-.72.28-.2.65-.36,1.11-.48.46-.12,1.04-.17,1.73-.17h.91v-.49c0-.17-.03-.32-.08-.45-.05-.13-.14-.24-.27-.32-.13-.08-.31-.15-.55-.2-.24-.05-.54-.07-.92-.07s-.71.03-1.06.08c-.35.06-.64.12-.87.2l-.08-1.36c.09-.03.23-.06.42-.11s.41-.08.67-.13.54-.08.85-.1.63-.04.97-.04c.56,0,1.03.04,1.41.13s.68.22.91.41c.23.18.39.42.5.7.1.28.15.63.15,1.03v4.77ZM107.73,74.14v-1.82h-.63c-.4,0-.73.03-.97.08-.25.06-.44.13-.57.23-.14.1-.23.21-.27.34-.05.13-.07.27-.07.42,0,.13.02.25.06.36.04.11.1.21.2.29.09.08.22.14.39.19.16.05.38.07.64.07.21,0,.43-.02.66-.05.22-.03.42-.07.59-.12Z"/>
                <path 
                      d="M118.09,75.25c-.37.11-.85.22-1.44.32-.58.1-1.21.15-1.88.15s-1.26-.06-1.69-.17c-.42-.12-.75-.32-.98-.6-.23-.28-.38-.66-.45-1.13-.07-.47-.1-1.06-.1-1.76v-3.67h2.04v3.67c0,.44.02.8.06,1.07.04.28.1.49.2.64s.22.26.39.32.39.09.66.09c.21,0,.41-.02.61-.05s.38-.07.54-.12v-5.63h2.04v6.87Z"/>
                <path 
                      d="M125.46,73.34c0,.85-.25,1.46-.76,1.82s-1.3.55-2.39.55c-.55,0-1.07-.04-1.55-.13-.48-.08-.85-.17-1.1-.27l.08-1.33c.26.08.59.16.97.22s.78.1,1.17.1c.32,0,.58-.01.78-.04.21-.03.37-.07.49-.13.12-.06.21-.13.25-.22.05-.09.07-.21.07-.34,0-.14-.03-.26-.08-.36-.05-.1-.15-.18-.28-.25-.14-.07-.32-.13-.55-.18-.23-.05-.52-.1-.88-.15-.74-.09-1.28-.3-1.63-.62-.35-.32-.52-.84-.52-1.56,0-.41.07-.76.22-1.04.14-.28.35-.52.62-.69.27-.18.59-.31.97-.38s.81-.12,1.28-.12c.64,0,1.17.04,1.59.13.42.08.71.16.88.24l-.08,1.3c-.19-.05-.46-.1-.83-.17s-.75-.1-1.14-.1c-.33,0-.59.02-.79.05-.2.03-.36.08-.47.14-.11.06-.19.14-.23.22s-.06.19-.06.3c0,.12.02.22.07.31.05.08.14.16.27.22.13.07.31.12.54.17.23.05.53.1.9.16.36.06.68.13.96.22s.5.21.69.38.32.37.41.62c.09.25.14.56.14.93Z"/>
                <path 
                      d="M131.18,75.57c-.07.02-.26.05-.55.08-.29.04-.58.06-.88.06-.62,0-1.11-.06-1.47-.17-.36-.11-.64-.28-.83-.5-.19-.22-.31-.5-.37-.83-.06-.33-.08-.7-.08-1.13v-6.41h2.04v1.71h1.99v1.46h-1.99v3.42c0,.3.07.54.22.71.15.18.45.27.91.27.06,0,.14,0,.24,0,.09,0,.19,0,.28-.01s.18-.01.25-.02c.07,0,.13-.01.15-.01l.08,1.4Z"/>
                <path 
                      d="M138.94,71.87v.12c0,.05,0,.1,0,.16,0,.06,0,.11-.01.16,0,.05,0,.09,0,.1h-4.56c0,.27.02.5.07.71s.14.4.27.56c.14.16.33.28.57.37.25.09.57.13.97.13.21,0,.42,0,.63-.03.21-.02.42-.04.61-.07.19-.03.37-.06.52-.09.16-.03.28-.06.38-.09l.08,1.43c-.23.08-.61.17-1.12.25-.51.08-1.16.12-1.93.12-.6,0-1.09-.08-1.49-.25-.4-.16-.71-.41-.94-.73-.23-.32-.4-.72-.49-1.2-.09-.48-.14-1.03-.14-1.66,0-1.18.26-2.08.79-2.72s1.39-.95,2.6-.95c.6,0,1.1.08,1.51.25s.74.4.99.71c.25.31.43.69.54,1.15.11.46.16.98.16,1.57ZM137.05,71.26c0-.21-.02-.42-.05-.62-.03-.2-.1-.38-.19-.54-.09-.16-.23-.28-.41-.38-.18-.09-.42-.14-.71-.14s-.54.05-.71.15c-.18.1-.31.22-.4.38-.09.15-.15.33-.18.54-.03.21-.05.41-.05.62h2.7Z"/>
                <path  d="M142.48,75.54h-2.04v-10.14h2.04v10.14Z"/>
                <path  d="M146.4,75.54h-2.04v-10.14h2.04v10.14Z"/>
                <path 
                      d="M154.53,71.87v.12c0,.05,0,.1,0,.16,0,.06,0,.11-.01.16,0,.05,0,.09,0,.1h-4.56c0,.27.02.5.07.71s.14.4.27.56c.14.16.33.28.57.37.25.09.57.13.97.13.21,0,.42,0,.63-.03.21-.02.42-.04.61-.07.19-.03.37-.06.52-.09.16-.03.28-.06.38-.09l.08,1.43c-.23.08-.61.17-1.12.25-.51.08-1.16.12-1.93.12-.6,0-1.09-.08-1.49-.25-.4-.16-.71-.41-.94-.73-.23-.32-.4-.72-.49-1.2-.09-.48-.14-1.03-.14-1.66,0-1.18.26-2.08.79-2.72s1.39-.95,2.6-.95c.6,0,1.1.08,1.51.25s.74.4.99.71c.25.31.43.69.54,1.15.11.46.16.98.16,1.57ZM152.64,71.26c0-.21-.02-.42-.05-.62-.03-.2-.1-.38-.19-.54-.09-.16-.23-.28-.41-.38-.18-.09-.42-.14-.71-.14s-.54.05-.71.15c-.18.1-.31.22-.4.38-.09.15-.15.33-.18.54-.03.21-.05.41-.05.62h2.7Z"/>
                <path 
                      d="M162.61,75.54h-2.04v-3.67c0-.43-.02-.78-.06-1.06-.04-.28-.11-.5-.21-.65-.1-.15-.24-.26-.41-.32s-.39-.09-.65-.09c-.21,0-.41.02-.62.05-.21.03-.39.07-.55.12v5.63h-2.04v-6.87c.37-.11.85-.22,1.44-.32.59-.1,1.22-.15,1.89-.15s1.26.06,1.69.17c.43.12.76.32.99.6.23.28.38.66.45,1.13s.11,1.06.11,1.76v3.67Z"/>
                <path  d="M171.11,75.54h-2.17v-9.52h2.17v9.52Z"/>
                <path 
                      d="M179.71,75.54h-2.04v-3.67c0-.43-.02-.78-.06-1.06-.04-.28-.11-.5-.21-.65-.1-.15-.24-.26-.41-.32s-.39-.09-.65-.09c-.21,0-.41.02-.62.05-.21.03-.39.07-.55.12v5.63h-2.04v-6.87c.37-.11.85-.22,1.44-.32.59-.1,1.22-.15,1.89-.15s1.26.06,1.69.17c.43.12.76.32.99.6.23.28.38.66.45,1.13s.11,1.06.11,1.76v3.67Z"/>
                <path 
                      d="M185.77,65.38l-.08,1.4c-.07,0-.21-.02-.39-.04-.19-.01-.35-.02-.49-.02-.25,0-.46.03-.61.09-.15.06-.27.15-.36.26-.08.11-.14.24-.17.39s-.04.31-.04.49v.42h1.99v1.46h-1.99v5.71h-2.04v-7.42c0-.39.03-.76.08-1.11.05-.35.17-.66.37-.92.2-.26.48-.47.85-.62.37-.15.88-.23,1.53-.23.33,0,.62.02.88.06.26.04.42.07.48.08Z"/>
                <path 
                      d="M194.03,71.87c0,.63-.06,1.19-.19,1.67s-.33.88-.62,1.2c-.28.32-.66.56-1.12.73-.46.16-1.03.25-1.72.25s-1.25-.08-1.71-.25c-.46-.16-.82-.41-1.1-.73-.28-.32-.47-.72-.59-1.2s-.17-1.03-.17-1.67.06-1.14.2-1.6.34-.84.63-1.15c.29-.31.66-.54,1.13-.69.46-.15,1.03-.23,1.7-.23s1.22.08,1.67.23c.45.15.82.39,1.1.69.28.31.48.69.6,1.15s.18.99.18,1.6ZM191.94,71.87c0-.27-.01-.54-.04-.8-.03-.26-.09-.5-.2-.7-.1-.21-.25-.37-.45-.5-.2-.13-.47-.19-.82-.19s-.62.06-.82.19-.35.29-.46.5c-.11.21-.18.44-.2.7-.03.26-.04.53-.04.8,0,.3.01.59.04.87s.09.53.2.75c.1.22.25.4.46.53.2.13.48.2.83.2s.62-.07.83-.2c.21-.14.36-.31.46-.53.1-.22.16-.47.19-.75.03-.28.04-.57.04-.87Z"/>
                <path 
                      d="M199.85,68.29l-.08,1.51c-.08-.02-.21-.03-.4-.04-.19,0-.38,0-.57,0-.27,0-.51.02-.73.07s-.37.08-.46.11v5.6h-2.04v-6.87c.13-.05.3-.1.51-.15.21-.06.44-.11.7-.15s.53-.08.81-.11.58-.04.88-.04c.34,0,.63,0,.88.03.25.02.42.04.5.06Z"/>
                <path 
                      d="M211.99,75.54h-2.04v-3.67c0-.43-.02-.78-.06-1.06-.04-.28-.1-.5-.2-.65s-.22-.26-.39-.32-.38-.09-.63-.09c-.11,0-.23,0-.36.03s-.25.04-.36.06-.21.05-.29.08-.15.05-.18.07c.06.16.1.39.13.71.03.31.05.71.05,1.18v3.67h-2.06v-3.67c0-.43-.02-.78-.06-1.06-.04-.28-.1-.5-.2-.65s-.22-.26-.38-.32-.36-.09-.6-.09c-.19,0-.38.02-.57.05-.2.03-.36.07-.5.12v5.63h-2.04v-6.87c.36-.11.83-.22,1.4-.32.57-.1,1.18-.15,1.83-.15.4,0,.77.04,1.12.13s.61.2.78.33c.09-.05.23-.1.41-.15.18-.05.38-.1.6-.15s.45-.09.7-.12c.25-.03.49-.05.73-.05.68,0,1.23.06,1.65.17.42.12.73.32.96.6.22.28.37.66.44,1.13.07.47.1,1.06.1,1.76v3.67Z"/>
                <path 
                      d="M220.03,75.25c-.39.11-.88.22-1.48.32-.59.1-1.23.15-1.93.15-.6,0-1.1-.04-1.5-.13-.4-.09-.73-.22-.97-.41-.25-.18-.42-.41-.52-.69-.1-.28-.15-.61-.15-.99,0-.34.06-.64.18-.92.12-.28.31-.52.59-.72.28-.2.65-.36,1.11-.48.46-.12,1.04-.17,1.73-.17h.91v-.49c0-.17-.03-.32-.08-.45-.05-.13-.14-.24-.27-.32-.13-.08-.31-.15-.55-.2-.24-.05-.54-.07-.92-.07s-.71.03-1.06.08c-.35.06-.64.12-.87.2l-.08-1.36c.09-.03.23-.06.42-.11s.41-.08.67-.13.54-.08.85-.1.63-.04.97-.04c.56,0,1.03.04,1.41.13s.68.22.91.41c.23.18.39.42.5.7.1.28.15.63.15,1.03v4.77ZM217.99,74.14v-1.82h-.63c-.4,0-.73.03-.97.08-.25.06-.44.13-.57.23-.14.1-.23.21-.27.34-.05.13-.07.27-.07.42,0,.13.02.25.06.36.04.11.1.21.2.29.09.08.22.14.39.19.16.05.38.07.64.07.21,0,.43-.02.66-.05.22-.03.42-.07.59-.12Z"/>
                <path 
                      d="M226.04,75.57c-.07.02-.26.05-.55.08-.29.04-.58.06-.88.06-.62,0-1.11-.06-1.47-.17-.36-.11-.64-.28-.83-.5-.19-.22-.31-.5-.37-.83-.06-.33-.08-.7-.08-1.13v-6.41h2.04v1.71h1.99v1.46h-1.99v3.42c0,.3.07.54.22.71.15.18.45.27.91.27.06,0,.14,0,.24,0,.09,0,.19,0,.28-.01s.18-.01.25-.02c.07,0,.13-.01.15-.01l.08,1.4Z"/>
                <path 
                      d="M229.75,66.34c0,.34-.09.6-.27.78s-.49.27-.94.27-.76-.09-.92-.27-.25-.44-.25-.78.09-.58.27-.75c.18-.17.49-.26.93-.26s.74.09.92.26c.17.17.26.42.26.75ZM229.59,75.54h-2.04v-7.17h2.04v7.17Z"/>
                <path 
                      d="M238.34,71.87c0,.63-.06,1.19-.19,1.67s-.33.88-.62,1.2c-.28.32-.66.56-1.12.73-.46.16-1.03.25-1.72.25s-1.25-.08-1.71-.25c-.46-.16-.82-.41-1.1-.73-.28-.32-.47-.72-.59-1.2s-.17-1.03-.17-1.67.06-1.14.2-1.6.34-.84.63-1.15c.29-.31.66-.54,1.13-.69.46-.15,1.03-.23,1.7-.23s1.22.08,1.67.23c.45.15.82.39,1.1.69.28.31.48.69.6,1.15s.18.99.18,1.6ZM236.25,71.87c0-.27-.01-.54-.04-.8-.03-.26-.09-.5-.2-.7-.1-.21-.25-.37-.45-.5-.2-.13-.47-.19-.82-.19s-.62.06-.82.19-.35.29-.46.5c-.11.21-.18.44-.2.7-.03.26-.04.53-.04.8,0,.3.01.59.04.87s.09.53.2.75c.1.22.25.4.46.53.2.13.48.2.83.2s.62-.07.83-.2c.21-.14.36-.31.46-.53.1-.22.16-.47.19-.75.03-.28.04-.57.04-.87Z"/>
                <path 
                      d="M246.46,75.54h-2.04v-3.67c0-.43-.02-.78-.06-1.06-.04-.28-.11-.5-.21-.65-.1-.15-.24-.26-.41-.32s-.39-.09-.65-.09c-.21,0-.41.02-.62.05-.21.03-.39.07-.55.12v5.63h-2.04v-6.87c.37-.11.85-.22,1.44-.32.59-.1,1.22-.15,1.89-.15s1.26.06,1.69.17c.43.12.76.32.99.6.23.28.38.66.45,1.13s.11,1.06.11,1.76v3.67Z"/>
                <path 
                      d="M253.86,73.34c0,.85-.25,1.46-.76,1.82s-1.3.55-2.39.55c-.55,0-1.07-.04-1.55-.13-.48-.08-.85-.17-1.1-.27l.08-1.33c.26.08.59.16.97.22s.78.1,1.17.1c.32,0,.58-.01.78-.04.21-.03.37-.07.49-.13.12-.06.21-.13.25-.22.05-.09.07-.21.07-.34,0-.14-.03-.26-.08-.36-.05-.1-.15-.18-.28-.25-.14-.07-.32-.13-.55-.18-.23-.05-.52-.1-.88-.15-.74-.09-1.28-.3-1.63-.62-.35-.32-.52-.84-.52-1.56,0-.41.07-.76.22-1.04.14-.28.35-.52.62-.69.27-.18.59-.31.97-.38s.81-.12,1.28-.12c.64,0,1.17.04,1.59.13.42.08.71.16.88.24l-.08,1.3c-.19-.05-.46-.1-.83-.17s-.75-.1-1.14-.1c-.33,0-.59.02-.79.05-.2.03-.36.08-.47.14-.11.06-.19.14-.23.22s-.06.19-.06.3c0,.12.02.22.07.31.05.08.14.16.27.22.13.07.31.12.54.17.23.05.53.1.9.16.36.06.68.13.96.22s.5.21.69.38.32.37.41.62c.09.25.14.56.14.93Z"/>
                <path 
                      d="M262.37,71.87c0,1.3-.27,2.26-.81,2.89-.54.63-1.35.95-2.42.95-.33,0-.64-.03-.94-.08-.3-.05-.55-.11-.75-.17v3.05h-2.04v-9.84c.43-.12.92-.23,1.46-.32.55-.09,1.23-.14,2.06-.14.64,0,1.19.08,1.62.23.44.15.79.39,1.06.69.27.31.46.69.58,1.15s.18.99.18,1.6ZM260.27,71.87c0-.27-.01-.54-.04-.8-.03-.26-.09-.5-.2-.7-.1-.21-.25-.37-.45-.5s-.46-.19-.8-.19c-.32,0-.6.02-.84.06s-.41.08-.5.11v4.17c.11.05.29.09.55.13s.52.06.8.06c.35,0,.62-.06.81-.2.2-.13.34-.31.44-.53.1-.22.16-.47.19-.76.03-.28.04-.58.04-.88Z"/>
                <path 
                      d="M268.18,68.29l-.08,1.51c-.08-.02-.21-.03-.4-.04-.19,0-.38,0-.57,0-.27,0-.51.02-.73.07s-.37.08-.46.11v5.6h-2.04v-6.87c.13-.05.3-.1.51-.15.21-.06.44-.11.7-.15s.53-.08.81-.11.58-.04.88-.04c.34,0,.63,0,.88.03.25.02.42.04.5.06Z"/>
                <path 
                      d="M276.46,71.87c0,.63-.06,1.19-.19,1.67s-.33.88-.62,1.2c-.28.32-.66.56-1.12.73-.46.16-1.03.25-1.72.25s-1.25-.08-1.71-.25c-.46-.16-.82-.41-1.1-.73-.28-.32-.47-.72-.59-1.2s-.17-1.03-.17-1.67.06-1.14.2-1.6.34-.84.63-1.15c.29-.31.66-.54,1.13-.69.46-.15,1.03-.23,1.7-.23s1.22.08,1.67.23c.45.15.82.39,1.1.69.28.31.48.69.6,1.15s.18.99.18,1.6ZM274.37,71.87c0-.27-.01-.54-.04-.8-.03-.26-.09-.5-.2-.7-.1-.21-.25-.37-.45-.5-.2-.13-.47-.19-.82-.19s-.62.06-.82.19-.35.29-.46.5c-.11.21-.18.44-.2.7-.03.26-.04.53-.04.8,0,.3.01.59.04.87s.09.53.2.75c.1.22.25.4.46.53.2.13.48.2.83.2s.62-.07.83-.2c.21-.14.36-.31.46-.53.1-.22.16-.47.19-.75.03-.28.04-.57.04-.87Z"/>
                <path 
                      d="M284.62,76.35c0,.44-.09.81-.27,1.1s-.41.53-.7.71c-.29.18-.62.31-.99.39s-.75.12-1.14.12-.77-.01-1.12-.04c-.35-.02-.66-.05-.94-.09-.28-.04-.52-.07-.72-.1-.2-.03-.35-.06-.44-.09l.08-1.44c.1.03.25.06.43.09.18.03.38.06.6.09.22.03.45.05.69.07.24.02.46.03.68.03.26,0,.5-.02.72-.06s.41-.11.57-.2c.16-.09.28-.22.37-.38.09-.16.13-.36.13-.6v-.69c-.15.06-.39.12-.73.18s-.69.1-1.06.1c-.6,0-1.1-.08-1.5-.23-.4-.15-.72-.38-.96-.69-.24-.31-.41-.69-.51-1.15-.1-.46-.15-.99-.15-1.6,0-1.21.29-2.13.87-2.74.58-.62,1.52-.92,2.83-.92.68,0,1.31.04,1.88.13.57.09,1.03.19,1.38.32v7.7ZM282.58,73.88v-4c-.13-.05-.32-.09-.57-.13-.25-.04-.51-.06-.78-.06-.31,0-.56.06-.76.19s-.35.29-.46.5-.18.44-.22.7c-.04.26-.06.53-.06.8s.02.55.05.81c.03.26.1.49.2.69.1.2.25.36.45.49.2.13.46.19.78.19.27,0,.53-.02.78-.06.25-.04.44-.08.57-.13Z"/>
                <path 
                      d="M290.78,68.29l-.08,1.51c-.08-.02-.21-.03-.4-.04-.19,0-.38,0-.57,0-.27,0-.51.02-.73.07s-.37.08-.46.11v5.6h-2.04v-6.87c.13-.05.3-.1.51-.15.21-.06.44-.11.7-.15s.53-.08.81-.11.58-.04.88-.04c.34,0,.63,0,.88.03.25.02.42.04.5.06Z"/>
                <path 
                      d="M298.34,75.25c-.39.11-.88.22-1.48.32-.59.1-1.23.15-1.93.15-.6,0-1.1-.04-1.5-.13-.4-.09-.73-.22-.97-.41-.25-.18-.42-.41-.52-.69-.1-.28-.15-.61-.15-.99,0-.34.06-.64.18-.92.12-.28.31-.52.59-.72.28-.2.65-.36,1.11-.48.46-.12,1.04-.17,1.73-.17h.91v-.49c0-.17-.03-.32-.08-.45-.05-.13-.14-.24-.27-.32-.13-.08-.31-.15-.55-.2-.24-.05-.54-.07-.92-.07s-.71.03-1.06.08c-.35.06-.64.12-.87.2l-.08-1.36c.09-.03.23-.06.42-.11s.41-.08.67-.13.54-.08.85-.1.63-.04.97-.04c.56,0,1.03.04,1.41.13s.68.22.91.41c.23.18.39.42.5.7.1.28.15.63.15,1.03v4.77ZM296.31,74.14v-1.82h-.63c-.4,0-.73.03-.97.08-.25.06-.44.13-.57.23-.14.1-.23.21-.27.34-.05.13-.07.27-.07.42,0,.13.02.25.06.36.04.11.1.21.2.29.09.08.22.14.39.19.16.05.38.07.64.07.21,0,.43-.02.66-.05.22-.03.42-.07.59-.12Z"/>
                <path 
                      d="M310.91,75.54h-2.04v-3.67c0-.43-.02-.78-.06-1.06-.04-.28-.1-.5-.2-.65s-.22-.26-.39-.32-.38-.09-.63-.09c-.11,0-.23,0-.36.03s-.25.04-.36.06-.21.05-.29.08-.15.05-.18.07c.06.16.1.39.13.71.03.31.05.71.05,1.18v3.67h-2.06v-3.67c0-.43-.02-.78-.06-1.06-.04-.28-.1-.5-.2-.65s-.22-.26-.38-.32-.36-.09-.6-.09c-.19,0-.38.02-.57.05-.2.03-.36.07-.5.12v5.63h-2.04v-6.87c.36-.11.83-.22,1.4-.32.57-.1,1.18-.15,1.83-.15.4,0,.77.04,1.12.13s.61.2.78.33c.09-.05.23-.1.41-.15.18-.05.38-.1.6-.15s.45-.09.7-.12c.25-.03.49-.05.73-.05.68,0,1.23.06,1.65.17.42.12.73.32.96.6.22.28.37.66.44,1.13.07.47.1,1.06.1,1.76v3.67Z"/>
                <path 
                      d="M323.53,75.54h-2.04v-3.67c0-.43-.02-.78-.06-1.06-.04-.28-.1-.5-.2-.65s-.22-.26-.39-.32-.38-.09-.63-.09c-.11,0-.23,0-.36.03-.13.02-.25.04-.36.06s-.21.05-.29.08-.14.05-.18.07c.06.16.1.39.13.71.03.31.05.71.05,1.18v3.67h-2.06v-3.67c0-.43-.02-.78-.06-1.06-.04-.28-.1-.5-.2-.65-.09-.15-.22-.26-.38-.32s-.36-.09-.6-.09c-.19,0-.38.02-.57.05-.2.03-.36.07-.5.12v5.63h-2.04v-6.87c.36-.11.83-.22,1.4-.32.57-.1,1.18-.15,1.83-.15.4,0,.78.04,1.12.13.34.09.61.2.78.33.09-.05.23-.1.41-.15.18-.05.38-.1.6-.15s.45-.09.7-.12c.25-.03.49-.05.74-.05.68,0,1.23.06,1.64.17.41.12.73.32.96.6.22.28.37.66.44,1.13.07.47.1,1.06.1,1.76v3.67Z"/>
            </g>
        </svg>
    );
}
