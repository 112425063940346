import { Form, Formik, FormikHelpers} from "formik";
import Layout from "../../components/Layout";
import {capitalize, Chip, DialogActions, DialogContent, Paper, Typography} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import {useNavigate, useParams} from "react-router";
import SubmitButton from "../../components/SubmitButton";
import {useTranslation} from "react-i18next";
import {useBaustelle} from "../../client/queries";
import {useUpdateBaustelle} from "../../client/hooks";
import {useMutation, useQueryClient} from "react-query";
import {BaustelleDaten} from "../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import BaustellenFieldsContent from "../../components/baustellen/BaustellenFieldsContent";
import DeleteBaustelleButton from "../../components/baustellen/DeleteBaustelleButton";

export const formatNumber = (value: number | undefined) => {
    // If the value is null, undefined, or NaN, return an empty string
    if (value == null || isNaN(value)) return "";

    // Format the number with exactly two decimal places
    return value.toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export default function DetailsBaustelle() {
    const { id: baustellenId = "" } = useParams<{
        id: string;
    }>();
    const baustelle = useBaustelle(baustellenId);

    const {mutateAsync} = useSaveBaustelle(baustellenId);
    const { t } = useTranslation("customer");
    let schema = yup.object().shape({
        bezeichnung: yup.string(),
        name: yup.string().required(),
        plz: yup.string(),
        strasse: yup.string(),
        ort: yup.string().required(),
        telefon: yup.string(),
        email: yup.string(),
        erstAuftragsSumme: yup.string(),
        auftragsSumme: yup.string(),
        saldo: yup.string(),
        kunde: yup.object({ id: yup.string(),name:yup.string()}),
    });
    const navigate = useNavigate();

    const onSubmit = useCallback(
        async (
            values: { bezeichnung:string, name: string,
                plz: string,
                strasse: string,
                ort: string,
                telefon: string,
                email: string,
                erstAuftragsSumme: string,
                auftragsSumme: string,
                saldo: string,
                kunde: { id: string, name:string }},
            formikHelpers: FormikHelpers<{bezeichnung:string, name: string,
                plz: string,
                strasse: string,
                ort: string,
                telefon: string,
                email: string,
                erstAuftragsSumme: string,
                auftragsSumme: string,
                saldo: string,
                kunde: { id: string, name: string }}>
        ) => {
            let auftragsSummeClean = values.auftragsSumme.replace(".","")
            let auftragsSumme = auftragsSummeClean
                ? parseFloat(values.auftragsSumme.replace(",", "."))
                : 0.0;
            let saldo = values.saldo
                ? parseFloat(values.saldo.replace(",", "."))
                : 0.0;
            const baustellen = await mutateAsync({name:values.name, strasse:values.strasse, plz:values.plz, telefon:values.telefon, email:values.email,ort:values.ort,saldo:saldo,bezeichnung:values.bezeichnung,auftragsSumme:auftragsSumme,kundenId:values.kunde.id});
            navigate(`/baustellen`);
            const { setSubmitting, setStatus } = formikHelpers;
            setStatus(undefined);
            try {
            } catch (error: any) {
                setStatus(error.message);
            } finally {
                setSubmitting(false);
            }

        },
        [navigate, mutateAsync]
    );
    return (
                <Paper elevation={1} sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2, position: "relative" }}>
                    <DeleteBaustelleButton id={baustellenId}></DeleteBaustelleButton>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            bezeichnung: baustelle.bezeichnung ?? "",
                            name: baustelle.name ?? "",
                            plz: baustelle.plz ?? "",
                            strasse: baustelle.strasse ?? "",
                            ort: baustelle.ort ?? "",
                            telefon: baustelle.telefon ?? "",
                            email: baustelle.email ?? "",
                            erstAuftragsSumme: formatNumber(baustelle.erstAuftragsSumme),
                            auftragsSumme: formatNumber(baustelle.auftragsSumme),
                            saldo: formatNumber(baustelle.saldo),
                            kunde: { id: baustelle.kundenId ?? "",name: baustelle.kundenName ?? ""}
                        }}
                        validationSchema={schema}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting, isValidating, isValid, dirty }) => (
                            <>
                                <Typography variant="h5" >{
                                    baustelle.geloeschtAm === undefined ? (
                                        t("construction-site_one") + " " + baustelle.nummer
                                    ) : (
                                        <>
                                            <>{t("construction-site_one") + " " + baustelle.nummer}</>
                                            <Chip
                                                size="small"
                                                icon={<DeleteIcon />}
                                                label={"gelöscht"}
                                                color="error"
                                            />
                                        </>
                                    )}</Typography>
                                <DialogContent>
                                    <Form id="details-baustelle">
                                        <BaustellenFieldsContent isNew={false}></BaustellenFieldsContent>
                                    </Form>
                                </DialogContent>
                                <DialogActions>
                                    <SubmitButton
                                        form="details-baustelle"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        loading={isSubmitting}
                                        disabled={isValidating || !isValid || !dirty}
                                    >
                                        {capitalize(t("save"))}
                                    </SubmitButton>
                                </DialogActions>
                            </>
                        )}
                    </Formik>
                </Paper>

    );
}
function useSaveBaustelle(id:string) {
    const updateBaustelle = useUpdateBaustelle();
    const queryClient = useQueryClient();

    return useMutation(
        (input:BaustelleDaten) => {
            return updateBaustelle(id,input);
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    "baustellen",id
                ]);
            },
        }
    );
}