import { useLocation, useNavigate } from "react-router";
import { Box } from "@mui/material";
import Layout from "../../components/Layout";
import { usePostDatevAuth } from "../../client/hooks";
import { useEffect, useState } from "react";
import ExterneSystemeListe from "./ExterneSystemeListe";

export default function Datev() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("codeExt");
  const state = queryParams.get("stateExt");
  let stateStorage = localStorage.getItem("DATEV_STATE");
  const mutate = usePostDatevAuth();
  const navigate = useNavigate();
  const [accessToken, setAccesstoken] = useState("");
  useEffect(() => {
    const fetchAccessToken = async () => {
      if (code) {
        try {
          if (stateStorage !== state) {
            console.error("Invalid state");
            return;
          }
          let accessTokenResponse = await mutate({ code: code });
          setAccesstoken(accessTokenResponse.accessToken ?? "");
          navigate("/externesysteme");
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      }
    };

    fetchAccessToken();
  }, [code, state]);
  if (stateStorage !== state) {
    return (
      <Layout>
        <div>State not found</div>
      </Layout>
    );
  }
  return (
    <Layout>
      <div>Datev</div>
    </Layout>
  );
}
