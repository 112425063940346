// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Abfallart } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useDeleteAbfallart(): (id: string) => Promise<Abfallart> {
  const getAPI = useGetApi();
  return useCallback(
    async (id) => (await getAPI()).abfallarten.deleteAbfallart({ id }),
    [getAPI]
  );
}
