import { createContext, useContext, type ReactNode } from "react";
import type { AllOptions, Options } from "./api";
import { useOptions as useOptionsHook } from "./client/queries";

const Context = createContext<AllOptions>({});

export function OptionsProvider({ children }: { children: ReactNode }) {
  const options = useOptionsHook({ staleTime: 5 * 60 * 1000 });
  return <Context.Provider value={options}>{children}</Context.Provider>;
}

export function useOptions(key: string): Options {
  const options = useContext(Context);
  if (!(key in options)) throw new Error(`Invalid options key: ${key}`);
  return options[key];
}
