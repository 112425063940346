import { Alert, Paper } from "@mui/material";
import {
  DataGrid,
  deDE,
  enUS,
  type GridColDef,
  type GridLocaleText,
  type GridPaginationModel,
  type GridSortModel,
} from "@mui/x-data-grid";
import { type DataGridPropsWithoutDefaultValue } from "@mui/x-data-grid/internals";
import { useTranslation } from "react-i18next";
import { useQuery, type QueryKey } from "react-query";
import { useState, useEffect } from "react";
import type { Identifiable } from "../../api";
import type {
  DataLoader,
  DataRequestParams,
  DataRequestState,
  Filter,
  Sort,
} from "./types";

const defaultPageSize = 25;

export type Props<TElement extends Identifiable, TFilter extends Filter> = {
  queryKey: QueryKey;
  loadData: DataLoader<TFilter, TElement>;
  columns: Array<GridColDef<TElement>>;
  request: DataRequestState<TFilter>;
} & Pick<DataGridPropsWithoutDefaultValue<TElement>, "onRowClick">;

export default function DataTable<
  TElement extends Identifiable,
  TFilter extends Filter
>({
  columns,
  queryKey,
  loadData,
  request: { params, setPagination, setSort, setFilter },
  ...props
}: Props<TElement, TFilter>) {
  const [pagination, setLocalPagination] = useState({
    page: params.page ?? 0,
    limit: params.limit ?? defaultPageSize,
  });

  useEffect(() => {
    if (params.page === undefined || params.limit === undefined) {
      setPagination(pagination.page, pagination.limit);
      setFilter({
        ...params,
        page: pagination.page,
        limit: pagination.limit,
      } as TFilter);
    }
  }, []);

  useEffect(() => {
    setLocalPagination({
      page: params.page ?? 0,
      limit: params.limit ?? defaultPageSize,
    });
  }, [params.page, params.limit]);

  const results = useSearch({
    queryKey,
    loadData,
    params: {
      ...params,
      page: pagination.page,
      limit: pagination.limit,
    },
  });

  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    setLocalPagination({ page: newPage, limit: newPageSize });
    setPagination(newPage, newPageSize);
    setFilter({
      ...params,
      page: newPage,
      limit: newPageSize,
    } as TFilter);
  };

  const sortModel = toSortModel(params.sort);

  return (
    <Paper
      sx={{
        width: "100%",
        display: "table",
        tableLayout: "fixed",
      }}
    >
      {results.error ? (
        <Alert severity="error">{(results.error as any).message}</Alert>
      ) : null}
      <DataGrid
        autoHeight
        disableColumnFilter
        columns={columns}
        rows={results.data?.items ?? []}
        rowCount={results.data?.totalElements ?? 0}
        paginationMode="server"
        paginationModel={toPaginationModel(pagination.page, pagination.limit)}
        onPaginationModelChange={(e) =>
          handlePaginationChange(e.page, e.pageSize)
        }
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(e) => setSort(fromSortModel(e))}
        loading={results.isLoading}
        localeText={useLocaleText()}
        {...props}
      />
    </Paper>
  );
}

function toSortModel(sort?: string): GridSortModel {
  if (!sort) return [];
  const [field, direction] = sort.split(":");
  return [{ field, sort: direction as 'asc' | 'desc' }];
}

function fromSortModel(model: GridSortModel): Sort {
  return model.map(({ field, sort }) => ({ field, direction: sort ?? "asc" }));
}

function toPaginationModel(page?: number, limit?: number): GridPaginationModel {
  return { page: page ?? 0, pageSize: limit ?? defaultPageSize };
}

function useLocaleText(): Partial<GridLocaleText> {
  const {
    i18n: { language },
  } = useTranslation();

  switch (language) {
    case "de":
      return deDE.components.MuiDataGrid.defaultProps.localeText;
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
}

function useSearch<TElement extends Identifiable, TFilter extends Filter>({
  queryKey,
  loadData,
  params,
}: {
  queryKey: QueryKey;
  loadData: DataLoader<TFilter, TElement>;
  params: DataRequestParams<TFilter>;
}) {
  return useQuery(
    Array.isArray(queryKey) ? [...queryKey, params] : [queryKey, params],
    () => loadData(params),
    { suspense: false }
  );
}
