// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Abfallart, type ApiError } from "../../api";
import { useGetAbfallart as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useAbfallart(
  id: string,
  options: Omit<
    UseQueryOptions<Abfallart, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): Abfallart {
  const getter = useGetter();
  return useQuery(["abfallarten", id], () => getter(id), options).data!;
}
