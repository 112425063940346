import { useCallback } from "react";
import { useTranslation as useI18nTranslation } from "react-i18next";
import { supportedLngs } from "./i18n";

export function useSetLanguage() {
  const { i18n } = useI18nTranslation();

  return useCallback(
    (code: string) => {
      if (!supportedLngs.includes(code as any))
        throw new Error(`Unknown language: ${code}`);
      window.localStorage.setItem("i18nextLng", code);

      // TODO save user's language choice in the backend.
      return i18n.changeLanguage(code);
    },
    [i18n]
  );
}
