// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Taetigkeitsbericht, TaetigkeitsberichtDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateTaetigkeitsbericht(): (
  taetigkeitsberichtId: string,
  requestBody: TaetigkeitsberichtDaten
) => Promise<Taetigkeitsbericht> {
  const getAPI = useGetApi();
  return useCallback(
    async (taetigkeitsberichtId, requestBody) =>
      (await getAPI()).taetigkeitsberichte.updateTaetigkeitsbericht({
        taetigkeitsberichtId,
        requestBody,
      }),
    [getAPI]
  );
}
