import {
    Box,
    Button,
    Stack, useMediaQuery, useTheme,
} from "@mui/material";
import {useCallback, useMemo} from "react";
import { useNavigate } from "react-router";
import {GridColDef} from "@mui/x-data-grid";
import {DataLoader, DataRequest, DataRequestState, useDataRequest, useDebouncedFilter} from "../data";
import {useT} from "../../i18n";
import SearchField from "../SearchField";
import {useGetApi} from "../../client";
import DataTable from "../data/DataTable";
import {AusgangsrechnungInfo} from "../../api";

export type AusgangsrechnungenFilter = {
    search?: string;
    kundenId?: string;
};


export type Props = Omit<
    DataRequest<AusgangsrechnungenFilter>,
    "filter"
> &
    Partial<Pick<DataRequest<AusgangsrechnungenFilter>, "filter">>;

export default function AusgangsrechnungenDataTable({
                                               ...input
                                           }: Props) {
    const request = useDataRequest<AusgangsrechnungenFilter>({ filter: {}, ...input });

    return (
        <Stack spacing={2}>
            <FilterComp {...request} />
            <AusgangsrechnungenResults {...request} />
        </Stack>
    );
}

function FilterComp(request: DataRequestState<AusgangsrechnungenFilter>) {
    const [{ search }, setField] =
        useDebouncedFilter(request);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const {t} = useT("ausgangsrechnung");
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%' }}>
            {isMobile ?
            <Stack direction="row">
                <SearchField
                    value={search ?? ""}
                    onChange={(s) => setField("search", s)}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginLeft: 2}}
                    onClick={()=>{navigate('/neu-ausgangsrechnung')}}
                >
                    {t("create-invoice")}
                </Button>
            </Stack> :
                <>
                    <SearchField
                        value={search ?? ""}
                        onChange={(s) => setField("search", s)}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                        onClick={()=>{navigate('/neu-ausgangsrechnung')}}
                    >
                        {t("create-invoice")}
                    </Button>
                </>
             }
        </Box>
    );
}

function AusgangsrechnungenResults({
                             ...request
                         }: DataRequestState<AusgangsrechnungenFilter>) {
    return <Results request={request} />;
}

function Results({
                     request,
                 }: {
    request: DataRequestState<AusgangsrechnungenFilter>;
}) {
    const navigate = useNavigate();
    const getApi = useGetApi();
    const loadAusgangsrechnungen: DataLoader<AusgangsrechnungenFilter, AusgangsrechnungInfo> = useCallback(
        async (params) =>
            (await getApi()).ausgangsrechnungen.sucheAusgangsrechnungen({ ...params }),
        [getApi]
    );

    return (
        <DataTable
            columns={useColumns()}
            request={request}
            queryKey={["ausgangsrechnungen"]} //TODO: Richtigen QueryKey verwenden
            loadData={loadAusgangsrechnungen}
            onRowClick={((row)=>{navigate('/ausgangsrechnungen/' + row.id)})}
        />
    );
}
function useColumns(): Array<GridColDef<AusgangsrechnungInfo>> {
    const { t} = useT("ausgangsrechnung");

    return useMemo(
        () => [
            {
            field: "faelligkeitsDatum",
            headerName: t("date"),
            renderCell: ({ value }) => value ? new Date(value).toLocaleDateString() : "kein Datum",
            },
            {
                field: "rechnungsNummer",
                headerName: t("invoice-number"),
                flex:1
            },
            {
                field: "art",
                headerName: t("type"),
            },
            {
                field: "kundenName",
                headerName: t("customer-name"),
                flex:1
            },
            {
                field: "baustellenName",
                headerName: t("construction-site-name"),
                flex:1
            },
            {
                field: "baustellenBezeichnung",
                headerName: t("description"),
                flex:1
            },
            {
                field: "lfsNr",
                headerName: t("lfs-No"),
                flex:1
            },
            {
                field: "bemerkung",
                headerName: t("remark"),
                flex:1
            },
            {
                field: "buchungsart",
                headerName: t("booking-type"),
                flex:1
            },
            {
                field: "gesamtPreis",
                headerName: t("total-price"),
                flex:1,
                align: "right",
                headerAlign: "right",
                renderCell: ({ value }) => `${(value as number).toFixed(2).replace(".", ",")} €`,
            }
        ],
        [t]
    );
}