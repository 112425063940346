import {
  type FlatNamespace,
  type i18n as I18N,
  type KeyPrefix,
  type TFunction,
} from "i18next";
import {
  useTranslation as useI18nTranslation,
  type FallbackNs,
  type UseTranslationOptions,
} from "react-i18next";
import { type $Tuple } from "react-i18next/helpers";
import { useCapitalize } from "./capitalize";
import { useDoIt, type DoIt } from "./doIt";

/**
 * This is the one-stop solution for all your translation needs.
 */
export type CustomT<
  Ns extends FlatNamespace | $Tuple<FlatNamespace> | undefined,
  KPrefix extends KeyPrefix<FallbackNs<Ns>>
> = {
  t: TFunction<FallbackNs<Ns>, KPrefix>;
  i18n: I18N;
  capitalize: (s: string) => string;
  doIt: DoIt;
};

export function useT<
  Ns extends FlatNamespace | $Tuple<FlatNamespace> | undefined = undefined,
  KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined
>(ns?: Ns, options?: UseTranslationOptions<KPrefix>): CustomT<Ns, KPrefix> {
  const { t, i18n } = useI18nTranslation(ns, options);
  const capitalize = useCapitalize();
  const doIt = useDoIt();
  return { t, i18n, capitalize, doIt };
}
