// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type AusgangsrechnungInfo } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useGetAusgangsrechnung(): (
  ausgangsrechnungId: string
) => Promise<AusgangsrechnungInfo> {
  const getAPI = useGetApi();
  return useCallback(
    async (ausgangsrechnungId) =>
      (await getAPI()).ausgangsrechnungen.getAusgangsrechnung({
        ausgangsrechnungId,
      }),
    [getAPI]
  );
}
