import { useT } from "../../i18n";
import { Box, Divider, Typography } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";

export default function CommunicationFieldsContent() {
  const { t } = useT("customer");
  return (
    <>
      <Typography sx={{ paddingTop: 2 }}>{t("communication")}</Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
      >
        <Field component={TextField} label={t("phone")} name="telefon" />
        <Field component={TextField} label={t("fax")} name="fax" />
        <Field component={TextField} label={t("email")} name="email" />
      </Box>
    </>
  );
}
