/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Fuhrparkeintrag } from '../models/Fuhrparkeintrag';
import type { FuhrparkeintragDaten } from '../models/FuhrparkeintragDaten';
import type { FuhrparkeintragPage } from '../models/FuhrparkeintragPage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FuhrparkeintraegeService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Fuhrparkeintraege zu einem bestimmten Taetigkeitsbericht.
   * @returns FuhrparkeintragPage Die Seite der Fuhrparkeintraege.
   * @throws ApiError
   */
  public sucheFuhrparkeintraege({
    taetigkeitsberichtId,
    search,
    page,
    limit,
    sort,
  }: {
    taetigkeitsberichtId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<FuhrparkeintragPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Fuhrparkeintrag der angelegte Fuhrparkeintrag
   * @throws ApiError
   */
  public createFuhrparkeintrag({
    taetigkeitsberichtId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    requestBody: FuhrparkeintragDaten,
  }): CancelablePromise<Fuhrparkeintrag> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Bekomme einen Fuhrparkeintrag
   * @returns Fuhrparkeintrag Der Fuhrparkeintrag
   * @throws ApiError
   */
  public getFuhrparkeintrag({
    taetigkeitsberichtId,
    fuhrparkeintragId,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
  }): CancelablePromise<Fuhrparkeintrag> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Aktualisiere einen Fuhrparkeintrag
   * @returns Fuhrparkeintrag Der aktualisierte Fuhrparkeintrag
   * @throws ApiError
   */
  public updateFuhrparkeintrag({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    requestBody: FuhrparkeintragDaten,
  }): CancelablePromise<Fuhrparkeintrag> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Fuhrparkeintrag der gelöschte Fuhrparkeintrag
   * @throws ApiError
   */
  public deleteFuhrparkeintrag({
    taetigkeitsberichtId,
    fuhrparkeintragId,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
  }): CancelablePromise<Fuhrparkeintrag> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
