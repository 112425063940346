// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Benutzer } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useDeleteBenutzer(): (benutzerId: string) => Promise<Benutzer> {
  const getAPI = useGetApi();
  return useCallback(
    async (benutzerId) =>
      (await getAPI()).benutzer.deleteBenutzer({ benutzerId }),
    [getAPI]
  );
}
