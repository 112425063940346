import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import LieferantenFieldsContent from "../../components/lieferanten/LieferantenFieldsContent";
import { useMutation, useQueryClient } from "react-query";
import { useCreateLieferant } from "../../client/hooks";
import { LieferantDaten } from "../../api";

export default function ErstelleLieferant() {
  const { t } = useTranslation("customer");
  const { mutateAsync } = useSaveLieferant();
  let schema = yup.object().shape({
    name: yup.string().required(),
    namenszusatz: yup.string(),
    kundennummer: yup.string(),
    umsatzsteuerId: yup.string(),
    postfach: yup.string(),
    plzPostfach: yup.string(),
    plz: yup.string(),
    strasse: yup.string().required(),
    ort: yup.string().required(),
    telefon: yup.string(),
    fax: yup.string(),
    email: yup.string(),
    steuernummer: yup.string(),
    lieferantennummer: yup.string().required(),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        name: string;
        namenszusatz: string;
        kundennummer: string;
        umsatzsteuerId: string;
        postfach: string;
        plzPostfach: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        fax: string;
        email: string;
        steuernummer: string;
        lieferantennummer: string;
      },
      formikHelpers: FormikHelpers<{
        name: string;
        namenszusatz: string;
        kundennummer: string;
        umsatzsteuerId: string;
        postfach: string;
        plzPostfach: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        fax: string;
        email: string;
        steuernummer: string;
        lieferantennummer: string;
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      const supplier = await mutateAsync({
        name: values.name,
        strasse: values.strasse,
        plz: values.plz,
        telefon: values.telefon,
        email: values.email,
        ort: values.ort,
        umsatzsteuerId: values.umsatzsteuerId,
        fax: values.fax,
        postfach: values.postfach,
        plzPostfach: values.plzPostfach,
        namenszusatz: values.namenszusatz,
        kundennummer: values.kundennummer,
        steuernummer: values.steuernummer,
        lieferantennummer: values.lieferantennummer,
      });
      navigate(`/lieferanten`);
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={t("supplier-creation-form")} back="/lieferanten">
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Formik
          initialValues={{
            name: "",
            namenszusatz: "",
            kundennummer: "",
            umsatzsteuerId: "",
            postfach: "",
            plzPostfach: "",
            plz: "",
            strasse: "",
            ort: "",
            telefon: "",
            fax: "",
            email: "",
            steuernummer: "",
            lieferantennummer: "",
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty }) => (
            <>
              <Typography variant="h5">{t("new-supplier")}</Typography>
              <DialogContent>
                <Form id="new-supplier">
                  <LieferantenFieldsContent />
                </Form>
              </DialogContent>
              <DialogActions>
                <SubmitButton
                  form="new-supplier"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Layout>
  );
}

function useSaveLieferant() {
  const createLieferant = useCreateLieferant();
  const queryClient = useQueryClient();

  return useMutation(
    (input: LieferantDaten) => {
      return createLieferant(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["suppliers"]);
      },
    }
  );
}
