// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type AllOptions, type ApiError } from "../../api";
import { useGetOptions as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useOptions(
  options: Omit<
    UseQueryOptions<AllOptions, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): AllOptions {
  const getter = useGetter();
  return useQuery(["options"], () => getter(), options).data!;
}
