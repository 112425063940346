// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Fuhrparkeintrag, FuhrparkeintragDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useCreateFuhrparkeintrag(): (
  taetigkeitsberichtId: string,
  requestBody: FuhrparkeintragDaten
) => Promise<Fuhrparkeintrag> {
  const getAPI = useGetApi();
  return useCallback(
    async (taetigkeitsberichtId, requestBody) =>
      (await getAPI()).fuhrparkeintraege.createFuhrparkeintrag({
        taetigkeitsberichtId,
        requestBody,
      }),
    [getAPI]
  );
}
