import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthProvider } from "./auth";
import GlobalError from "./components/GlobalError";
import GlobalLoading from "./components/GlobalLoading";
import SuspenseBoundary from "./components/SuspenseBoundary";
import { I18nProvider } from "./i18n";
import { OptionsProvider } from "./options";
import Pages from "./pages";
import { SystemInformationProvider } from "./system-information";
import { ThemeProvider } from "./theme";
import { UserProvider } from "./user";

// Welcome to the entrypoint of the frontend application! We sincerely hope you
// will enjoy your stay. This file is the root of the application and has one main
// purpose: to provide the application with all necessary providers and then delegate
// the actual rendering of routes to `pages`.

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // We're using suspense by default for all queries. You can disable it for select
      // queries by passing the option `useQuery(..., { suspense: false })`
      suspense: true,
      // We don't want to retry failed requests by default, because this also retries
      // calls with 4xx status codes, which by definition will fail on every retry with
      // unchanged parameters.
      retry: false,
    },
  },
});

export default function Test() {
  // Helmet: Allows you to manage document head data like the title from deep within the component tree.
  // Theme: Provides the application with a MUI theme, colors and other visual stuff.
  // SuspenseBoundary: Wraps the application in an outermost suspense and error boundary. This is just a global fallback, individual pages should use more granular boundaries.
  // QueryClient: Provides react-query with the global instance of the query client.
  // Snackbar: Provides the application with a global snackbar for notifications.
  // SystemInformation: Load basic system information from the backend.
  // Options: Load lookup values from the backend.
  // Auth: Authenticate the user with OAuth against the authorization server. Any children of this provider are only rendered for authenticated users.
  // User: Load the user's profile from the backend.
  // Organizations: Load the user's organizations from the backend.

  return (
    <QueryClientProvider client={queryClient}>
      <I18nProvider>
        <HelmetProvider>
          <ThemeProvider>
            <SuspenseBoundary
              fallback={<GlobalLoading />}
              errorComp={GlobalError}
            >
              <SnackbarProvider maxSnack={3}>
                <SystemInformationProvider>
                  <OptionsProvider>
                    <AuthProvider>
                      <UserProvider>
                        <Pages />
                      </UserProvider>
                    </AuthProvider>
                  </OptionsProvider>
                </SystemInformationProvider>
              </SnackbarProvider>
              <ReactQueryDevtools />
            </SuspenseBoundary>
          </ThemeProvider>
        </HelmetProvider>
      </I18nProvider>
    </QueryClientProvider>
  );
}
