import { useLocation, useNavigate } from "react-router";
import { Box } from "@mui/material";
import Layout from "../../components/Layout";
import { usePostgoogleAuth } from "../../client/hooks";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function Google() {
  const location = useLocation();
  //const queryParams = new URLSearchParams(location.search);
  const [queryParameters] = useSearchParams();
  const state = queryParameters.get("stateExt");
  let stateStorage = localStorage.getItem("STATE");
  const code = queryParameters.get("codeExt");
  const navigate = useNavigate();
  const mutate = usePostgoogleAuth();
  const [accessToken, setAccesstoken] = useState("");
  useEffect(() => {
    const fetchAccessToken = async () => {
      console.log("fetchAccessToken");
      if (code) {
        try {
          let stateStorage = localStorage.getItem("STATE");
          if (stateStorage !== state) {
            console.error("Invalid state");
            return;
          }
          let accessTokenResponse = await mutate({ code: code });
          setAccesstoken(accessTokenResponse.accessToken ?? "");
          navigate("/externesysteme");
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      }
    };

    fetchAccessToken();
  }, [code, state]);
  if (stateStorage !== state) {
    return (
      <Layout>
        <div>State not found</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div>Google</div>
    </Layout>
  );
}
