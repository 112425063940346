// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { UmsatzAuswertungDaten, type UmsatzAuswertungListe } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useSucheUmsatzAuswertungByBaustellenIdAndByDate(): (
  baustellenId: string,
  requestBody: UmsatzAuswertungDaten
) => Promise<UmsatzAuswertungListe> {
  const getAPI = useGetApi();
  return useCallback(
    async (baustellenId, requestBody) =>
      (await getAPI()).umsatz.sucheUmsatzAuswertungByBaustellenIdAndByDate({
        baustellenId,
        requestBody,
      }),
    [getAPI]
  );
}
