import DeleteIcon from "@mui/icons-material/Delete";
import {
    Button,
    capitalize,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import {useMutation, useQueryClient} from "react-query";
import {bindPopover, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import {useDeleteTaetigkeitsbericht} from "../../client/hooks";
import {useT} from "../../i18n";
import {useNavigate} from "react-router";

export default function DeleteTaetigkeitsberichtButton({id}: {id: string}) {
    const { t } = useT("taetigkeitsbericht");
    const mutate = useLoescheTaetigkeitsbericht(id);
    const popupState = usePopupState({
        variant: "popover",
        popupId: `taetigkeitsbericht-${id}-deactivate`,
    });
    return (
        <>
            <IconButton
                sx={{ position: 'absolute', top: 10, right: 10 }}
                {...bindTrigger(popupState)}
                aria-label="delete"
            >
                <DeleteIcon />
            </IconButton>
            <Dialog {...bindPopover(popupState)}>
                <DialogTitle id="alert-dialog-title">
                    {capitalize(t("sure-delete-activity-report-header"))}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {capitalize(t("sure-delete-activity-report-body"))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => popupState.close()} color="inherit">
                        {capitalize(t("abort"))}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            mutate.mutate();
                            popupState.close();
                        }}
                    >
                        {capitalize(t("delete"))}
                    </Button>
                </DialogActions>
            </Dialog>
        </>)

}
function useLoescheTaetigkeitsbericht(id:string) {
    const deleteTaetigkeitsbericht = useDeleteTaetigkeitsbericht();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(
        () => {
            return deleteTaetigkeitsbericht(id);
        },
        {
            onSuccess: async () => {
                queryClient.invalidateQueries([
                    "taetigkeitsberichte"
                ]);
                navigate("/taetigkeitsberichte/")
            },
        }
    );
}
