import { useCallback, useState } from "react";
import type {
  DataRequest,
  DataRequestState,
  Filter,
  Sort,
  SortDirection,
} from "./types";

export type DataRequestProps<TFilter extends Filter> = DataRequest<TFilter>;

export function useDataRequest<TFilter extends Filter>(
  props: DataRequestProps<TFilter>
): DataRequestState<TFilter> {
  const [request, setRequest] = useState<DataRequest<TFilter>>({
    page: 0,
    ...props,
  } as DataRequest<TFilter>);

  const setFilter = useCallback(
    (filter: TFilter) => setRequest((r) => ({ ...r, filter })),
    []
  );

  const setPagination = useCallback(
    (page?: number, limit?: number) =>
      setRequest((r) => ({ ...r, page, limit })),
    []
  );

  const setPage = useCallback(
    (page?: number) => setRequest((r) => ({ ...r, page })),
    []
  );

  const setLimit = useCallback(
    (limit?: number) => setRequest((r) => ({ ...r, limit })),
    []
  );

  const setSort = useCallback(
    (sort?: Sort) => setRequest((r) => ({ ...r, sort })),
    []
  );

  const setFieldSort = useCallback(
    (field: string, direction: SortDirection) =>
      setRequest((r) => ({ ...r, sort: [{ field, direction }] })),
    []
  );

  const toggleSort = useCallback(
    (field: string) =>
      setRequest((r) => ({
        ...r,
        sort: [
          {
            field,
            direction:
              r.sort?.[0].field === field && r.sort?.[0].direction === "asc"
                ? "desc"
                : "asc",
          },
        ],
      })),
    []
  );

  return {
    ...request,
    get params() {
      return createParams(request);
    },
    setFilter,
    setPagination,
    setPage,
    setLimit,
    setSort,
    setFieldSort,
    toggleSort,
  };
}

function createParams<TFilter extends Filter>({
  filter,
  sort,
  page,
  limit,
}: DataRequest<TFilter>): TFilter & {
  page?: number;
  limit?: number;
  sort?: string;
} {
  return {
    ...(filter as TFilter),
    sort: sort?.length
      ? sort
          .map(
            ({ field, direction }) =>
              `${direction === "asc" ? "" : "-"}${field}`
          )
          .join(",")
      : undefined,
    page,
    limit,
  };
}
