// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Lieferant, LieferantDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateLieferant(): (
  id: string,
  requestBody: LieferantDaten
) => Promise<Lieferant> {
  const getAPI = useGetApi();
  return useCallback(
    async (id, requestBody) =>
      (await getAPI()).lieferanten.updateLieferant({ id, requestBody }),
    [getAPI]
  );
}
