import { customAlphabet } from "nanoid";

const createCorrelationIdSuffix = customAlphabet(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  13
);

/**
 * Every request from the front-end receives a unique correlation ID. This ID is used
 * in the backend to trace requests and events.
 */
export function createCorrelationId() {
  return Date.now().toString() + "." + createCorrelationIdSuffix();
}
