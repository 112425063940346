// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Anbauteil, type ApiError } from "../../api";
import { useGetAnbauteil as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useAnbauteil(
  anbauteilId: string,
  options: Omit<
    UseQueryOptions<Anbauteil, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): Anbauteil {
  const getter = useGetter();
  return useQuery(
    ["anbauteile", anbauteilId],
    () => getter(anbauteilId),
    options
  ).data!;
}
