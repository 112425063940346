// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Abladestelle, AbladestelleDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateAbladestelle(): (
  id: string,
  requestBody: AbladestelleDaten
) => Promise<Abladestelle> {
  const getAPI = useGetApi();
  return useCallback(
    async (id, requestBody) =>
      (await getAPI()).abladestellen.updateAbladestelle({ id, requestBody }),
    [getAPI]
  );
}
