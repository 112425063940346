import {
  AppBar,
  Badge,
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { ExpandContextProvider } from "../expand";
import React, { useEffect, useState } from "react";
import { NavButton } from "./NavButton";
import { DashboardIcon } from "./icons";
import { useT } from "../i18n";
import GroupIcon from "@mui/icons-material/Group";
import BusinessIcon from "@mui/icons-material/Business";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import CommuteIcon from "@mui/icons-material/Commute";
import FoundationIcon from "@mui/icons-material/Foundation";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { ExpandLess, ExpandMore, ReceiptLong } from "@mui/icons-material";
import EggIcon from "@mui/icons-material/Egg";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Guard from "./Guard";
import { apiUrl, applicationVersion, applicationVersionTime } from "../config";
import HardwareIcon from "@mui/icons-material/Hardware";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DescriptionIcon from "@mui/icons-material/Description";
import CableIcon from "@mui/icons-material/Cable";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import Logo_white from "./Logo_white";
import { useGetEingangsrechnungenInPruefungBadge } from "../client/hooks";

export function MainMenu({ close }: { close: () => void }) {
  const { t: t2, capitalize: capitalize2 } = useT("taetigkeitsbericht");
  const getEingangsrechnungenBadge = useGetEingangsrechnungenInPruefungBadge();
  const [badgeCount, setBadgeCount] = useState(0);
  const [expanded, setExpanded] = useState(() => {
    const savedState = localStorage.getItem("stammdaten-expanded");
    return savedState !== null ? JSON.parse(savedState) : true;
  });

  useEffect(() => {
    localStorage.setItem("stammdaten-expanded", JSON.stringify(expanded));
  }, [expanded]);
  useEffect(() => {
    const fetchBadgeCount = async () => {
      try {
        const badge = await getEingangsrechnungenBadge(); // Get the badge data
        setBadgeCount(badge.count ?? 0); // Assuming 'count' is the field that contains the count
      } catch (error) {
        console.error("Error fetching badge count:", error);
      }
    };

    fetchBadgeCount(); // Call the async function
  }, [getEingangsrechnungenBadge]);
  return (
    <ExpandContextProvider id="menu" store>
      <Box
        sx={{
          bgcolor: "grey.50",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          ".MuiListItemIcon-root": {
            color: "white",
          },
          ".MuiListItemText-primary": {
            color: "white",
          },
          ".MuiListItemSecondaryAction-root": {
            color: "grey.500",
          },
          ".MuiListItemButton-root": {
            "&.Mui-selected": {
              bgcolor: "grey.300",
              ".MuiListItemIcon-root": {
                color: "primary.900",
              },
              ".MuiListItemText-primary": {
                color: "primary.900",
                fontWeight: 500,
              },
              "&:hover": {
                bgcolor: "grey.300",
              },
            },
            "&:hover": {
              bgcolor: "grey.200",
              ".MuiListItemIcon-root": {
                color: "primary.900",
              },
              ".MuiListItemText-primary": {
                color: "primary.900",
              },
            },
          },
        }}
      >
        <AppBar
          position="static"
          color="primary"
          elevation={0}
          sx={{ bgcolor: "primary.900", color: "common.white" }}
        >
          <Toolbar sx={{ gap: 2 }}>
            <Box
              component={Logo_white}
              sx={{ color: "primary.100", fontSize: "2rem", width: "550px" }}
            />
          </Toolbar>
          <Box sx={{ flex: 1 }}>
            <List>
              <NavButton href="/dashboard" onClick={close}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </NavButton>
              <NavButton href="/eingangsrechnungen" onClick={close}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={"Eingangsrechnungen"} />
                <Badge badgeContent={badgeCount} color={"warning"}></Badge>
              </NavButton>

              <NavButton href="/ausgangsrechnungen" onClick={close}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary={"Ausgangsrechnungen"} />
              </NavButton>
              <NavButton href="/baustellen" onClick={close}>
                <ListItemIcon>
                  <FoundationIcon />
                </ListItemIcon>
                <ListItemText primary={"Baustellen"} />
              </NavButton>
              <NavButton href="/taetigkeitsberichte" onClick={close}>
                <ListItemIcon>
                  <SummarizeIcon />
                </ListItemIcon>
                <ListItemText primary={t2("activity-reports")} />
              </NavButton>
              <Stammdaten
                close={close}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            </List>
          </Box>
        </AppBar>
        {apiUrl.includes("localhost") ||
        apiUrl.includes("test") ||
        apiUrl.includes("staging") ? (
          <List>
            <Divider />
            <ListItem>
              <ListItemText
                inset
                primary={<>Version {applicationVersion}</>}
                secondary={<>{applicationVersionTime}</>}
                primaryTypographyProps={{
                  variant: "caption",
                  style: { color: "black", fontSize: "11pt" },
                }}
              />
            </ListItem>
          </List>
        ) : (
          <></>
        )}
      </Box>
    </ExpandContextProvider>
  );
}

function Stammdaten({
  close,
  expanded,
  setExpanded,
}: {
  expanded: boolean;
  close: () => void;
  setExpanded: (e: any) => void;
}) {
  const { t: t1, capitalize: capitalize1 } = useT("fuhrpark");
  const { t: t2, capitalize: capitalize2 } = useT("taetigkeitsbericht");
  return (
    <>
      <ListItemButton onClick={() => setExpanded((e: any) => !e)}>
        <ListItemIcon>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
        <ListItemText primary="Stammdaten" />
      </ListItemButton>
      <Collapse in={expanded}>
        <List component="div" disablePadding>
          <NavButton href="/kunden" onClick={close} indent={true}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={"Kunden"} />
          </NavButton>
          <NavButton href="/lieferanten" onClick={close} indent={true}>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary={"Lieferanten"} />
          </NavButton>
          <NavButton href="/fahrzeuge" indent={true}>
            <ListItemIcon>
              <CommuteIcon />
            </ListItemIcon>
            <ListItemText primary={capitalize1(t1("fuhrpark"))} />
          </NavButton>
          <NavButton href="/abfallarten" onClick={close} indent={true}>
            <ListItemIcon>
              <CleaningServicesIcon />
            </ListItemIcon>
            <ListItemText primary={"Abfallarten"} />
          </NavButton>
          <NavButton href="/abladestellen" onClick={close} indent={true}>
            <ListItemIcon>
              <HardwareIcon />
            </ListItemIcon>
            <ListItemText primary={"Abladestellen"} />
          </NavButton>
          <NavButton href="/erzeuger" onClick={close} indent={true}>
            <ListItemIcon>
              <EggIcon />
            </ListItemIcon>
            <ListItemText primary={"Erzeuger"} />
          </NavButton>
          <NavButton href="/entsorger" onClick={close} indent={true}>
            <ListItemIcon>
              <FolderDeleteIcon />
            </ListItemIcon>
            <ListItemText primary={"Entsorger"} />
          </NavButton>
          <Guard permission={"benutzer:list"}>
            <NavButton href="/benutzer" onClick={close} indent={true}>
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary={"Benutzer"} />
            </NavButton>
          </Guard>
          <Guard permission={"systemextern:list"}>
            <NavButton href="/externesysteme" onClick={close} indent={true}>
              <ListItemIcon>
                <CableIcon />
              </ListItemIcon>
              <ListItemText primary={"Externe Systeme"} />
            </NavButton>
          </Guard>
        </List>
      </Collapse>
    </>
  );
}
