import type { Error as ErrorVO } from "./api";

export class InternationalizableError extends Error implements ErrorVO {
  constructor(readonly code: string, message?: string) {
    super(message ?? code);
  }
}

export function notFound(): InternationalizableError {
  return new InternationalizableError("errors:not-found");
}
