// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ApiError, type BenutzerListe } from "../../api";
import { useSucheBenutzer as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export type SucheBenutzerQuery = {
  search?: string;
  page?: number;
  limit?: number;
  sort?: string;
};

export function useSucheBenutzer(
  query: SucheBenutzerQuery = {},
  options: Omit<
    UseQueryOptions<BenutzerListe, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): BenutzerListe {
  const getter = useGetter();
  return useQuery(["benutzer", query], () => getter(query), options).data!;
}
