import {Box, Stack} from "@mui/material";
import {Field} from "formik";
import {TextField} from "formik-mui";
import {useT} from "../../i18n";

export default function AbfallartenFieldsContent(){
    const { t } = useT("customer");
    return (<Stack direction="column">
        <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2}}>
            <Field
                component={TextField}
                label="EAK-Schluessel *"
                name="key"
            />
            <Field
                component={TextField}
                label="Name *"
                name="name"
            />
        </Box>
    </Stack>)
}