// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ZugewiesenesFahrzeugAnbauteil } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useDeleteZugewiesenesAnbauteil(): (
  zugewiesenesAnbauteilId: string
) => Promise<ZugewiesenesFahrzeugAnbauteil> {
  const getAPI = useGetApi();
  return useCallback(
    async (zugewiesenesAnbauteilId) =>
      (await getAPI()).fahrzeuge.deleteZugewiesenesAnbauteil({
        zugewiesenesAnbauteilId,
      }),
    [getAPI]
  );
}
