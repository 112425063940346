import { Box, capitalize, Stack } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../i18n";

export default function ZuweisungAnbauteilFieldsContent() {
  const { t } = useT("fuhrpark");
  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr"], gap: 2, marginTop: 2 }}
      >
        <Field
          component={TextField}
          label={capitalize(t("assign-attachment-part-cost-hour"))}
          name="kostenStunde"
          type={"number"}
          inputProps={{ min: 0, step: 0.01 }}
          required
        />
      </Box>
    </Stack>
  );
}
