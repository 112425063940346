// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ApiError, type Kunde } from "../../api";
import { useGetKunde as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useKunde(
  id: string,
  options: Omit<UseQueryOptions<Kunde, ApiError>, "queryKey" | "queryFn"> = {}
): Kunde {
  const getter = useGetter();
  return useQuery(["kunden", id], () => getter(id), options).data!;
}
