// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type EingangsrechnungenBadge } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useGetEingangsrechnungenInPruefungBadge(): () => Promise<EingangsrechnungenBadge> {
  const getAPI = useGetApi();
  return useCallback(
    async () =>
      (await getAPI()).rechnungen.getEingangsrechnungenInPruefungBadge(),
    [getAPI]
  );
}
