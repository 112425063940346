import { useMemo, type PropsWithChildren, type ReactNode } from "react";
import { usePermissions } from "./PermissionsContext";

export type Expression = {
  permission: string;
  fallback?: ReactNode;
};

export type Props = PropsWithChildren<Expression>;

export default function Guard({ children, fallback, ...props }: Props) {
  const permitted = usePermitted(props);
  return permitted ? <>{children}</> : <>{fallback}</> ?? null;
}

export function usePermitted({ permission }: Expression): boolean {
  const permissions = usePermissions();

  return useMemo(
    () => (permission ? permissions.includes(permission) : false),
    [permissions, permission]
  );
}
