import { capitalize, Paper, Typography, SxProps, Theme } from "@mui/material";
import { useT } from "../../../i18n";
import AnbauteilDataTable from "../../../components/fuhrpark/anbauteil/AnbauteilDataTable";
import ZugewieseneAnbauteileDataTable from "../../../components/fuhrpark/anbauteil/ZugewieseneAnbauteileDataTable";

interface ZugewieseneAnbauteileProps {
  sx?: SxProps<Theme>;
}

export default function ZugewieseneAnbauteile({
  sx,
}: ZugewieseneAnbauteileProps) {
  const { t } = useT("fuhrpark");
  return (
    <Paper elevation={1} sx={{ padding: 3, ...sx }}>
      <Typography variant="h5">
        {capitalize(t("attachment-parts-assigned"))}
      </Typography>
      <ZugewieseneAnbauteileDataTable />
    </Paper>
  );
}
