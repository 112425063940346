/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Maschinentransport } from '../models/Maschinentransport';
import type { MaschinentransportDaten } from '../models/MaschinentransportDaten';
import type { MaschinentransportPage } from '../models/MaschinentransportPage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MaschinentransporteService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Maschinentransporte zu einem bestimmten Fuhrparkeintrag.
   * @returns MaschinentransportPage Die Seite der Maschinentransporte.
   * @throws ApiError
   */
  public sucheMaschinentransporte({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    search,
    page,
    limit,
    sort,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<MaschinentransportPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/maschinentransport',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Maschinentransport der angelegte Maschinentransport
   * @throws ApiError
   */
  public createMaschinentransport({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    requestBody: MaschinentransportDaten,
  }): CancelablePromise<Maschinentransport> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/maschinentransport',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Bekomme einen Maschinentransport
   * @returns Maschinentransport Der Maschinentransport
   * @throws ApiError
   */
  public getMaschinentransport({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    maschinentransportId,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    maschinentransportId: string,
  }): CancelablePromise<Maschinentransport> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/maschinentransporte/{maschinentransportId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
        'maschinentransportId': maschinentransportId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Aktualisiere einen Maschinentransport
   * @returns Maschinentransport Der aktualisierte Maschinentransport
   * @throws ApiError
   */
  public updateMaschinentransport({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    maschinentransportId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    maschinentransportId: string,
    requestBody: MaschinentransportDaten,
  }): CancelablePromise<Maschinentransport> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/maschinentransporte/{maschinentransportId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
        'maschinentransportId': maschinentransportId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Maschinentransport der gelöschte Maschinentransport
   * @throws ApiError
   */
  public deleteMaschinentransport({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    maschinentransportId,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    maschinentransportId: string,
  }): CancelablePromise<Maschinentransport> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/maschinentransporte/{maschinentransportId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
        'maschinentransportId': maschinentransportId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
