// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ApiError, type BaustellenListe } from "../../api";
import { useSucheBaustellen as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export type SucheBaustellenQuery = {
  search?: string;
  kundenId?: string;
  page?: number;
  limit?: number;
  sort?: string;
};

export function useSucheBaustellen(
  query: SucheBaustellenQuery = {},
  options: Omit<
    UseQueryOptions<BaustellenListe, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): BaustellenListe {
  const getter = useGetter();
  return useQuery(["baustellen", query], () => getter(query), options).data!;
}
