import { FieldProps } from "formik";
import { useSucheKunden, useSucheLieferanten } from "../../client/hooks";
import { useEffect, useMemo, useState } from "react";
import {
  ApiError,
  Baustelle,
  Kunde,
  KundeListe,
  Lieferant,
  LieferantenListe,
} from "../../api";
import {
  ListItem,
  ListItemText,
  debounce,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { Autocomplete } from "formik-mui";

export default function LieferantenSelect({
  field,
  form,
  meta,
  label,
  ...props
}: {
  label: string;
  required?: boolean;
} & FieldProps) {
  const searchLieferanten = useSucheLieferanten();
  const [inputValue, setInputValue] = useState(field.value);
  const [options, setOptions] = useState<ReadonlyArray<Lieferant>>([]);

  const fetchOptions = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: LieferantenListe) => void
        ) =>
          searchLieferanten({
            search: inputValue === "" ? request.input : inputValue,
            page: 0,
            limit: 20,
            sort: "name",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchLieferanten, inputValue]
  );

  useEffect(() => {
    let active = true;

    fetchOptions({ input: inputValue }, (error, results) => {
      if (error) console.error("Error:", error);
      if (active) {
        setOptions([
          ...(field.value ? [field.value] : []),
          ...(results ? results.items : []),
        ]);
      }
    });

    return () => {
      active = false;
    };
  }, [field.value, inputValue, fetchOptions]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      autoComplete
      includeInputInList
      filterSelectedOptions
      options={options}
      filterOptions={(x) => x}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        // @ts-ignore
        const lieferantenId = value ? value.id : "";
        form.setFieldValue(field.name, lieferantenId);
      }}
      getOptionLabel={(option: string | Lieferant) => {
        const lieferant = options.find((opt) => opt.id === field.value);
        return lieferant?.name ?? "";
      }}
      renderOption={(props, option: string | Lieferant) => (
        <ListItem {...props}>
          <ListItemText
            primary={typeof option === "string" ? option : option.name}
            secondary={
              typeof option === "string"
                ? undefined
                : [option.strasse, option.ort].filter(Boolean).join(", ")
            }
          />
        </ListItem>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          name={field.name}
          label={"Lieferant"}
          {...props}
          {...params}
        />
      )}
    />
  );
}
