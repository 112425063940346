// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ApiError, type Rechnung } from "../../api";
import { useGetRechnung as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useRechnung(
  rechnungId: string,
  options: Omit<
    UseQueryOptions<Rechnung, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): Rechnung {
  const getter = useGetter();
  return useQuery(["rechnungen", rechnungId], () => getter(rechnungId), options)
    .data!;
}
