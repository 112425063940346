import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useEntsorger, useErzeuger } from "../../client/queries";
import { useUpdateEntsorger, useUpdateErzeuger } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { EntsorgerDaten, ErzeugerDaten } from "../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import ErzeugerFieldsContent from "../../components/erzeuger/ErzeugerFieldsContent";
import DeleteErzeugerButton from "../../components/erzeuger/DeleteErzeugerButton";
import EntsorgerFieldsContent from "../../components/entsorger/EntsorgerFieldsContent";
import DeleteEntsorgerButton from "../../components/entsorger/DeleteEntsorgerButton";

export default function DetailsEntsorger() {
  const { id: entsorgerId = "" } = useParams<{
    id: string;
  }>();
  const entsorger = useEntsorger(entsorgerId);
  const { t } = useTranslation("customer");
  let schema = yup.object().shape({
    nummer: yup.string().required(),
    name: yup.string(),
    datum: yup.string().required(),
    archiviert: yup.boolean(),
    verbleibortId: yup.string(),
    abfallartId: yup.string(),
    abfallartKey: yup.string(),
  });
  const navigate = useNavigate();
  const { mutateAsync } = useSaveEntsorger(entsorgerId);
  const onSubmit = useCallback(
    async (
      values: {
        nummer: string;
        name: string;
        datum: string;
        archiviert: boolean;
        verbleibortId: string;
        abfallartId: string;
        abfallartKey: string;
      },
      formikHelpers: FormikHelpers<{
        nummer: string;
        name: string;
        datum: string;
        archiviert: boolean;
        verbleibortId: string;
        abfallartId: string;
        abfallartKey: string;
      }>
    ) => {
      const entsorger = await mutateAsync({
        nummer: values.nummer,
        name: values.name,
        datum: values.datum + "T00:00:00.000Z",
        archiviert: values.archiviert,
        verbleibortId: values.verbleibortId,
        abfallartId: values.abfallartId,
      });
      const { setSubmitting, setStatus } = formikHelpers;
      navigate(`/entsorger`);
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={"Entsorger"} back="/entsorger">
      <Paper
        elevation={1}
        sx={{
          marginTop: 2,
          padding: 3,
          marginLeft: 2,
          marginRight: 2,
          position: "relative",
        }}
      >
        <DeleteEntsorgerButton id={entsorgerId}></DeleteEntsorgerButton>
        <Formik
          enableReinitialize
          initialValues={{
            nummer: entsorger.nummer ?? "",
            name: entsorger.name ?? "",
            datum: entsorger.datum?.split("T")[0] ?? "",
            archiviert: entsorger.archiviert ?? false,
            verbleibortId: entsorger.verbleibortId ?? "",
            abfallartId: entsorger.materialienId ?? "",
            abfallartKey: entsorger.abfallartKey ?? "",
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty }) => (
            <>
              <Typography variant="h5">
                {entsorger.geloeschtAm === undefined ? (
                  "Entsorger " + entsorger.nummer
                ) : (
                  <>
                    <>{"Entsorger " + entsorger.nummer}</>
                    <Chip
                      size="small"
                      icon={<DeleteIcon />}
                      label={"gelöscht"}
                      color="error"
                    />
                  </>
                )}
              </Typography>
              <DialogContent>
                <Form id="details-entsorger">
                  <EntsorgerFieldsContent></EntsorgerFieldsContent>
                </Form>
              </DialogContent>
              <DialogActions>
                <SubmitButton
                  form="details-entsorger"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Layout>
  );
}

function useSaveEntsorger(id: string) {
  const updateEntsorger = useUpdateEntsorger();
  const queryClient = useQueryClient();

  return useMutation(
    (input: EntsorgerDaten) => {
      return updateEntsorger(id, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["entsorger", id]);
      },
    }
  );
}
