import {Box, Divider, Stack, Typography} from "@mui/material";
import {Field, useFormikContext} from "formik";
import {TextField} from "formik-mui";
import {useT} from "../../i18n";
import KundenSelect from "../kunden/KundenSelect";

export default function BaustellenFieldsContent({isNew}: {isNew: boolean}){
    const {t} = useT("customer");
    const { setFieldValue } = useFormikContext();

    function formatNumber(value: string): string {
        // Entferne alle nicht-numerischen Zeichen außer dem Komma
        const cleanedValue = value.replace(/[^\d,]/g, '');

        // Teile den Wert in den Teil vor und nach dem Komma auf
        const [integerPart, decimalPart] = cleanedValue.split(',');

        // Formatiere den Integer-Teil mit Punkten als Tausendertrennzeichen
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Füge den Dezimalteil, falls vorhanden, wieder hinzu
        return decimalPart !== undefined
            ? `${formattedIntegerPart},${decimalPart}`
            : formattedIntegerPart;
    }
    const handleAuftragsummeChange = (event:any) => {
        const formattedValue = formatNumber(event.target.value);
        setFieldValue("auftragsSumme", formattedValue);
    };
    return(
        <Stack direction="column">
            <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "2fr 1fr "], gap: 2}}>
                <Field
                    component={TextField}
                    label="Bezeichnung"
                    name="bezeichnung"
                />
            </Box>
            <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr"], gap: 2,marginTop:2}}>
                <Field
                    component={TextField}
                    label="Name *"
                    name="name"
                />
               <Field
                    component={KundenSelect}
                    name="kunde"
                    label="Kunde"
                />
            </Box>
            <Typography sx={{paddingTop: 2}}>{t("address")}</Typography>
            <Divider sx={{marginBottom:2}} />
            <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr"], gap: 2}}>
                <Field
                    component={TextField}
                    label={t("street")}
                    name="strasse"
                />
            </Box>
            <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr"], gap: 2, marginTop: 2}}>
                <Field
                    component={TextField}
                    label={t("plz")}
                    name="plz"
                />
                <Field
                    component={TextField}
                    label={t("city") + " *"}
                    name="ort"
                />
            </Box>
            <Typography sx={{paddingTop: 2}}>{t("communication")}</Typography>
            <Divider sx={{marginBottom:2}} />
            <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr"], gap: 2}}>
                <Field
                    component={TextField}
                    label={t("phone")}
                    name="telefon"
                />
                <Field
                    component={TextField}
                    label={t("email")}
                    name="email"
                />
            </Box>
            <Typography sx={{paddingTop: 2}}>{t("total_other")}</Typography>
            <Divider sx={{marginBottom:2}} />
            <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr"], gap: 2, marginTop: 2}}>
                <Field
                    component={TextField}
                    label={t("order-total")}
                    name="auftragsSumme"
                    onChange={handleAuftragsummeChange}
                />
                {isNew ? <></> : <Field
                    component={TextField}
                    label={t("balance")}
                    name="saldo"
                    inputProps={{readOnly: true}}
                />}
            </Box>
        </Stack>
    )
}