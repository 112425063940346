// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { Timestamp, type ZugewiesenesFahrzeugAnbauteil } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useGetFahrzeugAnbauteilKostenFuerBestimmtesDatum(): (
  fahrzeugId: string,
  anbauteilId: string,
  requestBody: { date: Timestamp }
) => Promise<ZugewiesenesFahrzeugAnbauteil> {
  const getAPI = useGetApi();
  return useCallback(
    async (fahrzeugId, anbauteilId, requestBody) =>
      (await getAPI()).fahrzeuge.getFahrzeugAnbauteilKostenFuerBestimmtesDatum({
        fahrzeugId,
        anbauteilId,
        requestBody,
      }),
    [getAPI]
  );
}
