import { FieldProps } from "formik";
import {
  useSucheBaustellen,
  useGetKunde,
  useSucheAbladestellen,
} from "../../client/hooks"; // Import the useGetKunde hook
import { useEffect, useMemo, useState } from "react";
import {
  Abladestelle,
  AbladestellenListe,
  ApiError,
  Baustelle,
  KundeListe,
} from "../../api";
import {
  ListItem,
  ListItemText,
  debounce,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { Autocomplete } from "formik-mui";

export default function AbladestellenSelect({
  field,
  form,
  meta,
  label,
  ...props
}: {
  label: string;
  required?: boolean;
} & FieldProps) {
  const searchAbladestellen = useSucheAbladestellen();
  const getKunde = useGetKunde();
  const [inputValue, setInputValue] = useState(field.value);
  const [options, setOptions] = useState<ReadonlyArray<Abladestelle>>([]);
  const [kundenNamen, setKundenNamen] = useState<Record<string, string>>({});

  const fetchOptions = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: AbladestellenListe) => void
        ) =>
          searchAbladestellen({
            search: inputValue === "" ? request.input : inputValue,
            page: 0,
            limit: 20,
            sort: "kurzname",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchAbladestellen, inputValue]
  );

  useEffect(() => {
    let active = true;

    fetchOptions({ input: inputValue }, (error, results) => {
      if (error) console.error("Error:", error);
      if (active) {
        setOptions([
          ...(field.value ? [field.value] : []),
          ...(results ? results.items : []),
        ]);
      }
    });

    return () => {
      active = false;
    };
  }, [field.value, inputValue, fetchOptions, getKunde, kundenNamen]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      autoComplete
      includeInputInList
      filterSelectedOptions
      options={options}
      filterOptions={(x) => x}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        // @ts-ignore
        const abladestelleId = value ? value.id : "";
        form.setFieldValue(field.name, abladestelleId);
      }}
      getOptionLabel={(option: string | Abladestelle) => {
        const abladestelle = options.find((opt) => opt.id === field.value);
        return abladestelle?.kurzname ?? "";
      }}
      renderOption={(props, option: string | Abladestelle) => {
        return (
          <ListItem {...props}>
            <ListItemText
              primary={typeof option === "string" ? option : option.kurzname}
              secondary={
                typeof option === "string"
                  ? undefined
                  : [option.firma, option.ort].filter(Boolean).join(", ")
              }
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          name={field.name}
          label={"Verbleibort"}
          {...props}
          {...params}
        />
      )}
    />
  );
}
