/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Lieferant } from '../models/Lieferant';
import type { LieferantDaten } from '../models/LieferantDaten';
import type { LieferantenListe } from '../models/LieferantenListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LieferantenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns LieferantenListe Liste der Lieferanten
   * @throws ApiError
   */
  public sucheLieferanten({
    search,
    page,
    limit,
    sort,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<LieferantenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/lieferanten',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Lieferant der angelegte Lieferant
   * @throws ApiError
   */
  public createLieferant({
    requestBody,
  }: {
    requestBody: LieferantDaten,
  }): CancelablePromise<Lieferant> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/lieferanten',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Lieferant Lieferant
   * @throws ApiError
   */
  public getLieferant({
    id,
  }: {
    id: string,
  }): CancelablePromise<Lieferant> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/lieferanten/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Lieferant der aktualisierte Lieferant
   * @throws ApiError
   */
  public updateLieferant({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: LieferantDaten,
  }): CancelablePromise<Lieferant> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/lieferanten/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Lieferant der gelöschte Lieferant
   * @throws ApiError
   */
  public deleteLieferant({
    id,
  }: {
    id: string,
  }): CancelablePromise<Lieferant> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/lieferanten/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
