import { Box, Divider, Typography } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../i18n";

export default function AddressFieldsContent() {
  const { t } = useT("customer");
  return (
    <>
      <Typography sx={{ paddingTop: 2 }}>{t("address")}</Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
      >
        <Field
          component={TextField}
          label={t("street") + " *"}
          name="strasse"
        />
        <Field
          component={TextField}
          label={t("post-office-box")}
          name="postfach"
        />
        <Field
          component={TextField}
          label={t("plz-officebox")}
          name="plzPostfach"
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <Field component={TextField} label={t("plz")} name="plz" />
        <Field component={TextField} label={t("city") + " *"} name="ort" />
      </Box>
    </>
  );
}
