import { FieldProps } from "formik";
import { useSucheBaustellen, useGetKunde } from "../../client/hooks"; // Import the useGetKunde hook
import { useEffect, useMemo, useState } from "react";
import { ApiError, Baustelle, KundeListe } from "../../api";
import {
  ListItem,
  ListItemText,
  debounce,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { Autocomplete } from "formik-mui";

export default function BaustellenSelect({
  field,
  form,
  meta,
  label,
  ...props
}: {
  label: string;
  required?: boolean;
} & FieldProps) {
  const searchBaustellen = useSucheBaustellen();
  const getKunde = useGetKunde();
  const [inputValue, setInputValue] = useState(field.value);
  const [options, setOptions] = useState<ReadonlyArray<Baustelle>>([]);
  const [kundenNamen, setKundenNamen] = useState<Record<string, string>>({});

  const fetchOptions = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: KundeListe) => void
        ) =>
          searchBaustellen({
            search: inputValue === "" ? request.input : inputValue,
            page: 0,
            limit: 20,
            sort: "name",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchBaustellen, inputValue]
  );

  useEffect(() => {
    let active = true;

    fetchOptions({ input: inputValue }, (error, results) => {
      if (error) console.error("Error:", error);
      if (active) {
        setOptions([
          ...(field.value ? [field.value] : []),
          ...(results ? results.items : []),
        ]);
        if (results) {
          results.items.forEach((item: Baustelle) => {
            if (item.kundenId && !kundenNamen[item.kundenId]) {
              getKunde(item.kundenId).then((kunde) => {
                setKundenNamen((prev) => ({
                  ...prev,
                  // @ts-ignore
                  [item.kundenId]: kunde.name,
                }));
              });
            }
          });
        }
      }
    });

    return () => {
      active = false;
    };
  }, [field.value, inputValue, fetchOptions, getKunde, kundenNamen]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      autoComplete
      includeInputInList
      filterSelectedOptions
      options={options}
      filterOptions={(x) => x}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        // @ts-ignore
        const baustelleId = value ? value.id : "";
        form.setFieldValue(field.name, baustelleId);
      }}
      getOptionLabel={(option: string | Baustelle) => {
        const baustelle = options.find((opt) => opt.id === field.value);
        return baustelle?.name ?? "";
      }}
      renderOption={(props, option: string | Baustelle) => {
        const kundenName =
          typeof option === "string" ? "" : kundenNamen[option.kundenId ?? ""];
        return (
          <ListItem {...props}>
            <ListItemText
              primary={typeof option === "string" ? option : option.name}
              secondary={
                typeof option === "string"
                  ? undefined
                  : [option.nummer, kundenName ?? option.kundenId]
                      .filter(Boolean)
                      .join(", ")
              }
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          name={field.name}
          label={"Baustelle"}
          {...props}
          {...params}
        />
      )}
    />
  );
}
