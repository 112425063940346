import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  Box,
  Button,
  capitalize,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import {
  useCreateFuhrparkeintrag,
  useCreateFuhrparkeintragAnbauteil,
  useCreateTaetigkeitsbericht,
} from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { FuhrparkeintragAnbauteil, TaetigkeitsberichtDaten } from "../../api";
import TaetigkeitsberichtFieldsContent from "../../components/taetigkeitsberichte/TaetigkeitsberichtFieldsContent";
import * as Yup from "yup";
import { ExpandContextProvider } from "../../expand";
import FuhrparkeintragEigenPersonalItem from "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragEigenPersonalItem";
import DeleteIcon from "@mui/icons-material/Delete";

interface ExtendedTaetigkeitsberichtDaten {
  mitarbeiterId: string;
  baustelleId: string;
  datum: string;
  arbeitszeitNetto: number;
  stundensatz: number;
  lohnkosten: number;
  bemerkung: string;
  fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
}

interface ExtendedFuhrparkeintragDaten {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeugId: string;
  fahrzeugHerkunft: string;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: FuhrparkeintragAnbauteil[];
}

const validationSchema = Yup.object().shape({
  mitarbeiterId: yup.string().required(),
  baustelleId: yup.string().required(),
  datum: yup.string().required(),
  arbeitszeitNetto: yup.number().min(0).nullable(),
  bemerkung: yup.string(),
  fuhrparkeintraege: Yup.array()
    .of(
      Yup.object().shape({
        fahrzeugId: yup.mixed().required(),
        fahrzeugNutzungsdauer: yup.number().min(0).required(),
        fahrzeugStundenPreis: yup.number().min(0).required(),
        fahrzeugKosten: yup.number().min(0),
        fuhrparkGesamtKosten: yup.number().min(0),
        bemerkung: yup.string().nullable(),
        fuhrparkeintragAnbauteile: Yup.array()
          .of(
            Yup.object().shape({
              anbauteilId: yup.mixed().required(),
              anbauteilNutzungsdauer: yup.number().min(0).required(),
              anbauteilStundenPreis: yup.number().min(0).required(),
              anbauteilAnzahl: yup.number().min(1).required(),
              anbauteilKosten: yup.number().min(0),
            })
          )
          .min(0, ""),
      })
    )
    .min(0, ""),
});

export default function ErstelleTaetigkeitsbericht() {
  const { t } = useTranslation("taetigkeitsbericht");
  const createTaetigkeitsbericht = useCreateTaetigkeitsbericht();
  const createFuhrparkeintrag = useCreateFuhrparkeintrag();
  const createFuhrparkeintragAnbauteil = useCreateFuhrparkeintragAnbauteil();
  const navigate = useNavigate();

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  async function saveFuhrparkeintraege(
    values: ExtendedTaetigkeitsberichtDaten & {
      fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
    }
  ) {
    try {
      const taetigkeitsbericht = await createTaetigkeitsbericht({
        mitarbeiterId: values.mitarbeiterId,
        baustelleId: values.baustelleId,
        datum: values.datum,
        arbeitszeitNetto: values.arbeitszeitNetto,
        stundensatz: values.stundensatz,
        bemerkung: values.bemerkung,
      });

      // Create each fuhrparkeintrag
      const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
        (fuhrparkeintrag) => {
          const fuhrparkeintragData = {
            fahrzeugId: fuhrparkeintrag.fahrzeugId,
            fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
            fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
            fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
            bemerkung: fuhrparkeintrag.bemerkung,
          };
          return createFuhrparkeintrag(
            taetigkeitsbericht.id,
            fuhrparkeintragData
          );
        }
      );

      const fuhrparkeintragResults = await Promise.all(fuhrparkeintragPromises);

      const fuhrparkeintragIds = fuhrparkeintragResults.map(
        (result) => result.id
      );

      const fuhrparkeintragAnbauteilPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
            (fuhrparkeintragAnbauteil) => {
              const fuhrparkeintragAnbauteilData = {
                anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                anbauteilNutzungsdauer:
                  fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                anbauteilStundenPreis:
                  fuhrparkeintragAnbauteil.anbauteilStundenPreis,
              };
              return createFuhrparkeintragAnbauteil(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                fuhrparkeintragAnbauteilData
              );
            }
          );
        }
      );

      await Promise.all(fuhrparkeintragAnbauteilPromises);
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const onSubmit = useCallback(
    async (
      values: ExtendedTaetigkeitsberichtDaten & {
        fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
      },
      formikHelpers: FormikHelpers<
        ExtendedTaetigkeitsberichtDaten & {
          fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
        }
      >
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      try {
        // Create Tätigkeitsbericht first
        const taetigkeitsbericht = await createTaetigkeitsbericht({
          mitarbeiterId: values.mitarbeiterId,
          baustelleId: values.baustelleId,
          datum: values.datum,
          arbeitszeitNetto: values.arbeitszeitNetto,
          stundensatz: values.stundensatz,
          bemerkung: values.bemerkung,
        });

        // Create each fuhrparkeintrag
        const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
          (fuhrparkeintrag) => {
            const fuhrparkeintragData = {
              fahrzeugId: fuhrparkeintrag.fahrzeugId,
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
            };
            return createFuhrparkeintrag(
              taetigkeitsbericht.id,
              fuhrparkeintragData
            );
          }
        );

        const fuhrparkeintragResults = await Promise.all(
          fuhrparkeintragPromises
        );

        const fuhrparkeintragIds = fuhrparkeintragResults.map(
          (result) => result.id
        );

        const fuhrparkeintragAnbauteilPromises =
          values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
              (fuhrparkeintragAnbauteil) => {
                const fuhrparkeintragAnbauteilData = {
                  anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                  anbauteilNutzungsdauer:
                    fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                  anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  anbauteilStundenPreis:
                    fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                };
                return createFuhrparkeintragAnbauteil(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  fuhrparkeintragAnbauteilData
                );
              }
            );
          });

        await Promise.all(fuhrparkeintragAnbauteilPromises);

        setStatus(undefined);

        navigate(`/taetigkeitsberichte`);
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [
      navigate,
      createTaetigkeitsbericht,
      createFuhrparkeintrag,
      createFuhrparkeintragAnbauteil,
      t,
    ]
  );

  return (
    <Layout
      title={capitalize(t("activity-report-creation-form"))}
      back="/taetigkeitsberichte"
    >
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Formik
          initialValues={{
            mitarbeiterId: "",
            baustelleId: "",
            datum: "",
            arbeitszeitNetto: 0,
            stundensatz: 0,
            lohnkosten: 0,
            bemerkung: "",
            fuhrparkeintraege: [] as ExtendedFuhrparkeintragDaten[],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty, values }) => {
            var dauerVorhanden = values.arbeitszeitNetto;

            values.fuhrparkeintraege.map((fuhrparkeintrag) => {
              if (fuhrparkeintrag.fahrzeugId) {
                dauerVorhanden -= fuhrparkeintrag.fahrzeugNutzungsdauer;
              }
            });

            return (
              <>
                <Typography variant="h5">
                  {capitalize(t("new-activity-report"))}
                </Typography>
                <DialogContent>
                  <Form id="neuer-taetigkeitsbericht">
                    <TaetigkeitsberichtFieldsContent isNew={true} />
                    <FieldArray name="fuhrparkeintraege">
                      {({ push, remove }) => (
                        <>
                          <Box sx={{ marginTop: "2em", marginBottom: "2em" }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                var werte = 0;

                                push({
                                  fahrzeugId: "",
                                  fahrzeugHerkunft: "",
                                  fahrzeugStundenPreis: 0,
                                  fahrzeugNutzungsdauer: 0,
                                  bemerkung: "",
                                  fuhrparkeintragAnbauteile: [
                                    {
                                      anbauteilId: "",
                                      anbauteilNutzungsdauer: 0,
                                      anbauteilAnzahl: 1,
                                      anbauteilStundenPreis: 0,
                                      anbauteilKosten: 0,
                                    },
                                  ],
                                });

                                values.fuhrparkeintraege.map(() => {
                                  werte++;
                                });

                                if (werte > 0) {
                                  saveFuhrparkeintraege(values).then(
                                    (result) => {
                                      navigate(`/taetigkeitsberichte`);
                                    }
                                  );
                                }
                              }}
                              disabled={isValidating || !isValid}
                            >
                              {capitalize(t("add-carpool"))}
                            </Button>
                          </Box>
                          {values.fuhrparkeintraege.map(
                            (fuhrparkeintrag, index) => (
                              <Box key={index} sx={{ marginTop: 2 }}>
                                <Box
                                  display={"grid"}
                                  sx={{
                                    gridTemplateColumns: ["1fr", "10fr .1fr"],
                                    gap: 2,
                                    marginTop: 2,
                                  }}
                                >
                                  <ExpandContextProvider
                                    id={`fuhrparkeintrag-${index}`}
                                  >
                                    <FuhrparkeintragEigenPersonalItem
                                      isNew={!fuhrparkeintrag.id}
                                      fuhrparkeintrag={fuhrparkeintrag}
                                      index={index}
                                      expanded={expandedIndex === index}
                                      handleChange={handleAccordionChange}
                                      taetigkeitsberichtDatum={values.datum}
                                      dauerVorhanden={
                                        dauerVorhanden > 0 ? dauerVorhanden : 0
                                      }
                                      isValid={isValid}
                                      isValidating={isValidating}
                                    />
                                    <IconButton
                                      onClick={() => remove(index)}
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </ExpandContextProvider>
                                </Box>
                              </Box>
                            )
                          )}
                        </>
                      )}
                    </FieldArray>
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="neuer-taetigkeitsbericht"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            );
          }}
        </Formik>
      </Paper>
    </Layout>
  );
}

function useSaveTaetigkeitsbericht() {
  const createTaetigkeitsbericht = useCreateTaetigkeitsbericht();
  const queryClient = useQueryClient();

  return useMutation(
    (input: TaetigkeitsberichtDaten) => {
      return createTaetigkeitsbericht(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["taetigkeitsberichte"]);
      },
    }
  );
}
