// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Ausgangsrechnung } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useDeleteAusgangsrechnung(): (
  ausgangsrechnungId: string
) => Promise<Ausgangsrechnung> {
  const getAPI = useGetApi();
  return useCallback(
    async (ausgangsrechnungId) =>
      (await getAPI()).ausgangsrechnungen.deleteAusgangsrechnung({
        ausgangsrechnungId,
      }),
    [getAPI]
  );
}
