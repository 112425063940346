// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Rechnung, RechnungDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateRechnung(): (
  rechnungId: string,
  requestBody: RechnungDaten
) => Promise<Rechnung> {
  const getAPI = useGetApi();
  return useCallback(
    async (rechnungId, requestBody) =>
      (await getAPI()).rechnungen.updateRechnung({ rechnungId, requestBody }),
    [getAPI]
  );
}
