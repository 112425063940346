import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import LieferantenFieldsContent from "../../components/lieferanten/LieferantenFieldsContent";
import { useLieferant } from "../../client/queries";
import { useUpdateLieferant } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { KundeDaten } from "../../api";
import DeleteLieferantButton from "../../components/lieferanten/DeleteLieferantButton";
import DeleteIcon from "@mui/icons-material/Delete";

export default function DetailsLieferant() {
  const { id: supplierId = "" } = useParams<{
    id: string;
  }>();
  const lieferant = useLieferant(supplierId);
  const { mutateAsync } = useSavelieferant(supplierId);
  const { t } = useTranslation("customer");
  let schema = yup.object().shape({
    name: yup.string().required(),
    namenszusatz: yup.string(),
    kundennummer: yup.string(),
    umsatzsteuerId: yup.string(),
    postfach: yup.string(),
    plzPostfach: yup.string(),
    plz: yup.string(),
    strasse: yup.string().required(),
    ort: yup.string().required(),
    telefon: yup.string(),
    fax: yup.string(),
    email: yup.string(),
    schriftAblage: yup.string(),
    steuernummer: yup.string(),
    lieferantennummer: yup.string().required(),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        name: string;
        namenszusatz: string;
        kundennummer: string;
        umsatzsteuerId: string;
        postfach: string;
        plzPostfach: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        fax: string;
        email: string;
        schriftAblage: string;
        steuernummer: string;
        lieferantennummer: string;
      },
      formikHelpers: FormikHelpers<{
        name: string;
        namenszusatz: string;
        kundennummer: string;
        umsatzsteuerId: string;
        postfach: string;
        plzPostfach: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        fax: string;
        email: string;
        schriftAblage: string;
        steuernummer: string;
        lieferantennummer: string;
      }>
    ) => {
      const lieferant = await mutateAsync(values);
      const { setSubmitting, setStatus } = formikHelpers;
      navigate(`/lieferanten`);
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={"Lieferant"} back="/lieferanten">
      <Paper
        elevation={1}
        sx={{
          marginTop: 2,
          padding: 3,
          marginLeft: 2,
          marginRight: 2,
          position: "relative",
        }}
      >
        <DeleteLieferantButton id={supplierId}></DeleteLieferantButton>
        <Formik
          initialValues={{
            name: lieferant.name ?? "",
            namenszusatz: lieferant.namenszusatz ?? "",
            kundennummer: lieferant.kundennummer ?? "",
            umsatzsteuerId: lieferant.umsatzsteuerId ?? "",
            postfach: lieferant.postfach ?? "",
            plzPostfach: lieferant.plzPostfach ?? "",
            plz: lieferant.plz ?? "",
            strasse: lieferant.strasse ?? "",
            ort: lieferant.ort ?? "",
            telefon: lieferant.telefon ?? "",
            fax: lieferant.fax ?? "",
            email: lieferant.email ?? "",
            schriftAblage: lieferant.schriftAblage ?? "",
            steuernummer: lieferant.steuernummer ?? "",
            lieferantennummer: lieferant.lieferantennummer ?? "",
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty }) => (
            <>
              <Typography variant="h5">
                {lieferant.geloeschtAm === undefined ? (
                  "Lieferant"
                ) : (
                  <>
                    <>Lieferant</>
                    <Chip
                      size="small"
                      icon={<DeleteIcon />}
                      label={"gelöscht"}
                      color="error"
                    />
                  </>
                )}
              </Typography>
              <DialogContent>
                <Form id="details-lieferant">
                  <LieferantenFieldsContent></LieferantenFieldsContent>
                </Form>
              </DialogContent>
              <DialogActions>
                <SubmitButton
                  form="details-lieferant"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Layout>
  );
}
function useSavelieferant(id: string) {
  const updateKunde = useUpdateLieferant();
  const queryClient = useQueryClient();

  return useMutation(
    (input: KundeDaten) => {
      return updateKunde(id, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["lieferanten", id]);
      },
    }
  );
}
