// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ApiError, type ZugewiesenesFahrzeugAnbauteil } from "../../api";
import { useGetFahrzeugAnbauteil as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useFahrzeugAnbauteil(
  zugewiesenesAnbauteilId: string,
  options: Omit<
    UseQueryOptions<ZugewiesenesFahrzeugAnbauteil, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): ZugewiesenesFahrzeugAnbauteil {
  const getter = useGetter();
  return useQuery(
    ["zugewiesene-anbauteile", zugewiesenesAnbauteilId],
    () => getter(zugewiesenesAnbauteilId),
    options
  ).data!;
}
