/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthUrl } from '../models/AuthUrl';
import type { Challenge } from '../models/Challenge';
import type { DatevAuth } from '../models/DatevAuth';
import type { DatevAuthCode } from '../models/DatevAuthCode';
import type { Externessystem } from '../models/Externessystem';
import type { ExternessystemListe } from '../models/ExternessystemListe';
import type { ExternesystemDaten } from '../models/ExternesystemDaten';
import type { GoogleAuth } from '../models/GoogleAuth';
import type { GoogleAuthCode } from '../models/GoogleAuthCode';
import type { Revoke } from '../models/Revoke';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExternesystemeService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Externesysteme.
   * @returns ExternessystemListe Die Seite der Externesysteme.
   * @throws ApiError
   */
  public sucheExternesysteme({
    search,
    page,
    limit,
    sort,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<ExternessystemListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/externesysteme',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Externessystem Das angelegte Externesystem
   * @throws ApiError
   */
  public createExternesystem({
    requestBody,
  }: {
    requestBody: ExternesystemDaten,
  }): CancelablePromise<Externessystem> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/externesysteme',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Externessystem Das Externesystem
   * @throws ApiError
   */
  public getExternessystem({
    extrenesSystemId,
  }: {
    extrenesSystemId: string,
  }): CancelablePromise<Externessystem> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/externesysteme/{extrenesSystemId}',
      path: {
        'extrenesSystemId': extrenesSystemId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Externessystem Das aktualisierte Externesystem
   * @throws ApiError
   */
  public updateExternesystem({
    extrenesSystemId,
    requestBody,
  }: {
    extrenesSystemId: string,
    requestBody: ExternesystemDaten,
  }): CancelablePromise<Externessystem> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/externesysteme/{extrenesSystemId}',
      path: {
        'extrenesSystemId': extrenesSystemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns GoogleAuth Google Auth
   * @throws ApiError
   */
  public postgoogleAuth({
    requestBody,
  }: {
    requestBody: GoogleAuthCode,
  }): CancelablePromise<GoogleAuth> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/externesysteme/google/auth',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns GoogleAuth Google Refresh
   * @throws ApiError
   */
  public getgoogleRefresh(): CancelablePromise<GoogleAuth> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/externesysteme/google/refresh',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Externessystem Das Externesystem
   * @throws ApiError
   */
  public getExternesystemByTyp({
    typ,
  }: {
    typ: string,
  }): CancelablePromise<Externessystem> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/externesysteme/typ/{typ}',
      path: {
        'typ': typ,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns DatevAuth Datev Auth
   * @throws ApiError
   */
  public postDatevAuth({
    requestBody,
  }: {
    requestBody: DatevAuthCode,
  }): CancelablePromise<DatevAuth> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/externesysteme/datev/auth',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Challenge Google Challange
   * @throws ApiError
   */
  public googleChallange(): CancelablePromise<Challenge> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/externesysteme/google/challenge',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Challenge Datev Challange
   * @throws ApiError
   */
  public datevChallange(): CancelablePromise<Challenge> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/externesysteme/datev/challenge',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Revoke Google Revoke
   * @throws ApiError
   */
  public googleRevoke(): CancelablePromise<Revoke> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/externesysteme/google/revoke',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Revoke Datev Revoke
   * @throws ApiError
   */
  public datevRevoke(): CancelablePromise<Revoke> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/externesysteme/datev/revoke',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns AuthUrl Google Auth Url
   * @throws ApiError
   */
  public googleAuthUrl({
    state,
  }: {
    state: string,
  }): CancelablePromise<AuthUrl> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/externessysteme/google/authurl/{state}',
      path: {
        'state': state,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns AuthUrl Datev Auth Url
   * @throws ApiError
   */
  public datevAuthUrl({
    state,
  }: {
    state: string,
  }): CancelablePromise<AuthUrl> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/externessysteme/datev/authurl/{state}',
      path: {
        'state': state,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * check the status of open dxso-jobs
   * @returns any Processed
   * @throws ApiError
   */
  public checkDatevJobStatus(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/externesysteme/datev/check-status',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
