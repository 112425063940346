import {Box, capitalize, Divider, MenuItem, Stack, Typography} from "@mui/material";
import {Field} from "formik";
import {Select, TextField} from "formik-mui";
import {useT} from "../../../i18n";

export default function AnbauteilFieldsContent(){
    const { t } = useT("fuhrpark");
    return (<Stack direction="column">
        <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr"], gap: 2, marginTop: 2}}>
            <Field
                component={TextField}
                label={capitalize(t("attachment-part-title"))}
                name="geraeteBezeichnung"
                required
            />
        </Box>
    </Stack>)
}