import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useDeleteAbladestelle } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useNavigate } from "react-router";

export default function DeleteAbladestelleButton({ id }: { id: string }) {
  const mutate = useLoeschenAbladestelle(id);
  const navigate = useNavigate();
  const popupState = usePopupState({
    variant: "popover",
    popupId: `abladestelle-${id}-deactivate`,
  });
  return (
      <>
        <IconButton
            sx={{ position: "absolute", top: 10, right: 10 }}
            {...bindTrigger(popupState)}
            aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
        <Dialog {...bindPopover(popupState)}>
          <DialogTitle id="alert-dialog-title">
            Abladestelle wirklich löschen
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sie sind dabei die Abladestelle zu löschen. Sind Sie sicher?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => popupState.close()} color="inherit">
              abbrechen
            </Button>
            <Button
                variant="contained"
                color="error"
                onClick={() => {
                  mutate.mutate();
                  popupState.close();
                  navigate(`/abladestellen`);
                }}
            >
              löschen
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

function useLoeschenAbladestelle(id: string) {
  const deleteAbladestelle = useDeleteAbladestelle();
  const queryClient = useQueryClient();

  return useMutation(
      () => {
        return deleteAbladestelle(id);
      },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries(["abladestellen", id]);
        },
      }
  );
}
