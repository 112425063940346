// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type FahrzeugePage, HerkunftTyp } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type SucheFahrzeugeQuery = {
  fahrzeugHerkunft?: HerkunftTyp;
  search?: string;
  page?: number;
  limit?: number;
  sort?: string;
};

export function useSucheFahrzeuge(): (
  query: SucheFahrzeugeQuery
) => Promise<FahrzeugePage> {
  const getAPI = useGetApi();
  return useCallback(
    async (query = {}) =>
      (await getAPI()).fahrzeuge.sucheFahrzeuge({ ...query }),
    [getAPI]
  );
}
