// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type BaustellenInfo } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useGetBaustelle(): (id: string) => Promise<BaustellenInfo> {
  const getAPI = useGetApi();
  return useCallback(
    async (id) => (await getAPI()).baustellen.getBaustelle({ id }),
    [getAPI]
  );
}
