import i18next from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { useGetTranslations as useGetApiTranslations } from "../client/hooks";
import YupLocalizer from "./YupLocalizer";

export const supportedLngs = ["de", "en"] as const;

const defaultLanguage: (typeof supportedLngs)[number] = "en";
const defaultNS = "common";

export function I18nProvider({ children }: { children: ReactNode }) {
  const missingKeyHandler = useMissingKeyHandler();
  const loadTranslations = useLoadTranslations();
  const [initialized, setInitialized] = useState(false);

  const i18n = useMemo(
    () =>
      i18next
        .createInstance({
          ns: defaultNS,
          defaultNS: defaultNS,
          fallbackLng: defaultLanguage,
          fallbackNS: defaultNS,
          supportedLngs,
          interpolation: {
            escapeValue: false,
          },
          saveMissing: import.meta.env.DEV,
          missingKeyHandler,
        })
        .use(languageDetector)
        .use(initReactI18next)
        .use(resourcesToBackend(loadTranslations)),
    [missingKeyHandler, loadTranslations]
  );

  useEffect(() => {
    if (!initialized) {
      i18n.init().then(() => setInitialized(true));
    }
  }, [initialized, i18n]);

  return initialized ? (
    <I18nextProvider i18n={i18n}>
      <YupLocalizer />
      {children}
    </I18nextProvider>
  ) : null;
}

function useLoadTranslations() {
  const getApiTranslations = useGetApiTranslations();

  return useCallback(
    async (language: string, namespace: string) => {
      try {
        console.info(
          `[i18n] loading translations in ${language}/${namespace}...`
        );

        return getApiTranslations(language, namespace);
      } catch (error: unknown) {
        console.error(
          `[i18n] failed to load translations in ${language}/${namespace}:`,
          error
        );
        return {};
      }
    },
    [getApiTranslations]
  );
}

const missingKeys = new Set<string>();

function useMissingKeyHandler() {
  return useCallback((lngs: readonly string[], ns: string, key: string) => {
    const lng = Array.isArray(lngs) ? lngs[0] : lngs;
    const cacheKey = `${ns}:${key}:${lng}`;
    if (!missingKeys.has(cacheKey)) {
      missingKeys.add(cacheKey);
      console.warn(`[i18n] missing translation in ${lng}: ${ns}/${key}`);
    }
  }, []);
}
