import { FieldProps } from "formik";
import { useEffect, useMemo, useState } from "react";
import { ApiError, ZugewiesenesFahrzeugAnbauteilPage } from "../../../api";
import {
  ListItem,
  ListItemText,
  debounce,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { Autocomplete } from "formik-mui";
import { useSucheZugewieseneFahrzeugAnbauteile } from "../../../client/hooks";
import { ZugewiesenesAnbauteil } from "./ZugewieseneAnbauteileDataTable";

interface ExtendedZugewiesenesAnbauteil extends ZugewiesenesAnbauteil {
  geraeteBezeichnung: string;
}

export default function ZugewieseneAnbauteilSelect({
  field,
  form,
  meta,
  label,
  fahrzeugId,
  ...props
}: {
  label: string;
  fahrzeugId: string;
  required?: boolean;
} & FieldProps) {
  const searchZugewieseneAnbauteile = useSucheZugewieseneFahrzeugAnbauteile();
  const [inputValue, setInputValue] = useState(field.value);
  const [options, setOptions] = useState<
    ReadonlyArray<ExtendedZugewiesenesAnbauteil>
  >([]);

  const fetchOptions = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (
            error?: ApiError,
            results?: ZugewiesenesFahrzeugAnbauteilPage
          ) => void
        ) =>
          searchZugewieseneAnbauteile(fahrzeugId, {
            search: inputValue === "" ? request.input : inputValue,
            page: 0,
            limit: 20,
            sort: "created",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchZugewieseneAnbauteile, inputValue, fahrzeugId]
  );

  useEffect(() => {
    let active = true;

    fetchOptions({ input: inputValue }, (error, results) => {
      if (error) console.error("Error:", error);
      if (active) {
        setOptions([
          ...(field.value ? [field.value] : []),
          ...(results ? results.items : []),
        ]);
      }
    });

    return () => {
      active = false;
    };
  }, [field.value, inputValue, fetchOptions, fahrzeugId]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      autoComplete
      includeInputInList
      filterSelectedOptions
      options={options}
      filterOptions={(x) => x}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        // @ts-ignore
        const zugewiesenesAnbauteilId = value ? value.anbauteilId : "";
        form.setFieldValue(field.name, zugewiesenesAnbauteilId);
      }}
      getOptionLabel={(option: string | ExtendedZugewiesenesAnbauteil) => {
        const zugewiesenesAnbauteil = options.find(
          (opt) => opt.anbauteilId === field.value
        );
        return zugewiesenesAnbauteil?.geraeteBezeichnung ?? "";
      }}
      renderOption={(props, option: string | ExtendedZugewiesenesAnbauteil) => (
        <ListItem {...props}>
          <ListItemText
            primary={
              typeof option === "string" ? option : option.geraeteBezeichnung
            }
            secondary={
              typeof option === "string"
                ? undefined
                : [option.geraeteBezeichnung, option.kostenStunde]
                    .filter(Boolean)
                    .join(", ")
            }
          />
        </ListItem>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          name={field.name}
          label={"Anbauteil"}
          {...props}
          {...params}
        />
      )}
    />
  );
}
