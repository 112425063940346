/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllOptions } from '../models/AllOptions';
import type { SystemInformation } from '../models/SystemInformation';
import type { Translations } from '../models/Translations';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SystemService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get basic information about the system.
   * @returns SystemInformation Information about the system
   * @throws ApiError
   */
  public getSystemInformation(): CancelablePromise<SystemInformation> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/system',
    });
  }

  /**
   * Get all options supported by the system.
   * @returns AllOptions The options
   * @throws ApiError
   */
  public getOptions(): CancelablePromise<AllOptions> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/options',
    });
  }

  /**
   * Get translations for a given namespace and language.
   * @returns Translations The translations for the given namespace and language in [i18next](https://www.i18next.com/misc/json-format) format.
   * @throws ApiError
   */
  public getTranslations({
    language,
    namespace,
  }: {
    language: string,
    namespace: string,
  }): CancelablePromise<Translations> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/i18n/{language}/{namespace}',
      path: {
        'language': language,
        'namespace': namespace,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

}
