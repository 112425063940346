import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useT } from "../i18n";

export default function SearchField({
  value,
  onChange,
}: {
  value?: string;
  onChange: (value: string) => void;
}) {
  const { t, capitalize } = useT();

  return (
    <TextField
      type="search"
      size="small"
      placeholder={capitalize(t("search"))}
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
