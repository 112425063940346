import {
    Box,
    Button, capitalize, Dialog, DialogContent, IconButton,
    Stack, useMediaQuery, useTheme,
} from "@mui/material";
import {useCallback, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {GridColDef, GridRowId} from "@mui/x-data-grid";
import {DataLoader, DataRequest, DataRequestState, useDataRequest, useDebouncedFilter} from "../../data";
import {useT} from "../../../i18n";
import SearchField from "../../SearchField";
import DataTable from "../../data/DataTable";
import {useGetApi} from "../../../client";
import ErstelleAnbauteil from "../../../pages/fuhrpark/anbauteil/ErstelleAnbauteil";
import AnbauteilDetails from "../../../pages/fuhrpark/anbauteil/AnbauteilDetails";
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import WeiseAnbauteilFahrzeugZu from "../../../pages/fuhrpark/fahrzeug/WeiseAnbauteilFahrzeugZu";
import DeleteAnbauteilButton from "./DeleteAnbauteilButton";

export type AnbauteilFilter = {
    fahrzeugId: string;
    search?: string;
};

export type Anbauteil = {
    id: string;
    geraeteBezeichnung: string;
};

export type Props = Omit<
    DataRequest<AnbauteilFilter>,
    "filter"
> &
    Partial<Pick<DataRequest<AnbauteilFilter>, "filter">>;

export default function AnbauteilDataTable({
                                               ...input
                                           }: Props) {
    const { id: fahrzeugId = "" } = useParams<{
        id: string;
    }>();

    const request = useDataRequest<AnbauteilFilter>({ filter: { fahrzeugId: fahrzeugId }, ...input });

    return (
        <Stack spacing={2}>
            <FilterComp {...request} />
            <AnbauteilResults {...request} />
        </Stack>
    );
}

function FilterComp(request: DataRequestState<AnbauteilFilter>) {
    const [open, setOpen] = useState(false);
    const { t } = useT("fuhrpark");
    const [{ search }, setField] =
        useDebouncedFilter(request);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%' }}>
            {isMobile ?
            <Stack direction="row">
                <SearchField
                    value={search ?? ""}
                    onChange={(s) => setField("search", s)}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginLeft: 2}}
                    onClick={handleClickOpen}
                >
                    {capitalize(t("create-attachment-part"))}
                </Button>
            </Stack> :
                <>
                    <SearchField
                        value={search ?? ""}
                        onChange={(s) => setField("search", s)}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                        onClick={handleClickOpen}
                    >
                        {capitalize(t("create-attachment-part"))}
                    </Button>
                    <Dialog
                        title={t("new-attachment-part")}
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogContent>
                            <ErstelleAnbauteil onClose={handleClose}/>
                        </DialogContent>
                    </Dialog>
                </>
             }
        </Box>
    );
}

function AnbauteilResults({
                             ...request
                         }: DataRequestState<AnbauteilFilter>) {
    return <Results request={request} />;
}
function Results({
                     request,
                 }: {
    request: DataRequestState<AnbauteilFilter>;
}) {
    const [open, setOpen] = useState(false);
    const [openAssign, setOpenAssign] = useState(false);
    const [anbauteilId, setAnbauteilId] = useState<string>("");
    const navigate = useNavigate();
    const getApi = useGetApi();
    const { t } = useT("fuhrpark");

    const handleClickOpenAssign = (anbauteilRowId: GridRowId) => {
        setOpenAssign(true);
        setAnbauteilId(anbauteilRowId.toString());
    };

    const handleClickOpen = (anbauteilRowId: GridRowId) => {
        setOpen(true);
        setAnbauteilId(anbauteilRowId.toString());
    };

    const handleClose = () => {
        setOpen(false);
        setOpenAssign(false);
    };

    const ladeAnbauteile: DataLoader<AnbauteilFilter, Anbauteil> = useCallback(
        async (params) =>
            (await getApi()).anbauteile.sucheUnbenutzeFahrzeugAnbauteile({ ...params }),
        [getApi]
    );

    return (
        <>
        <DataTable
            columns={useColumns(handleClickOpen, handleClickOpenAssign)}
            request={request}
            queryKey={["anbauteile"]}
            loadData={ladeAnbauteile}
        />
        <Dialog
            title={t("new-attachment-part")}
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <AnbauteilDetails anbauteilId={anbauteilId} onClose={handleClose}/>
            </DialogContent>
        </Dialog>
        <Dialog
            title={t("new-attachment-part")}
            open={openAssign}
            onClose={handleClose}
        >
            <DialogContent>
                <WeiseAnbauteilFahrzeugZu anbauteilId={anbauteilId} onClose={handleClose}/>
            </DialogContent>
        </Dialog>
        </>
    );
}
function useColumns(handleDialogOpen: (anbauteilRowId: GridRowId) => void, handleAssignDialogOpen: (anbauteilRowId: GridRowId) => void): Array<GridColDef<Anbauteil>> {
    const { t, capitalize } = useT("fuhrpark");

    return useMemo(
        () => [
            {
                field: "geraeteBezeichnung",
                headerName: t("attachment-part-title"),
                flex: 1,
            },
            {
                field: 'editAnbauteil',
                headerName: '',
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 60,
                renderCell: (params) => (
                    <>
                        <IconButton onClick={() => handleDialogOpen(params.row.id)}>
                            <EditIcon />
                        </IconButton>
                    </>
                ),
            },
            {
                field: 'assignAnbauteil',
                headerName: '',
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 60,
                renderCell: (params) => (
                    <>
                        <IconButton onClick={() => handleAssignDialogOpen(params.row.id)}>
                            <AddBoxIcon />
                        </IconButton>
                    </>
                ),
            },
            {
                field: 'deleteAnbauteil',
                headerName: '',
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 60,
                renderCell: (params) => (
                    <>
                        <DeleteAnbauteilButton id={params.row.id}/>
                    </>
                ),
            }
        ],
        [t, capitalize]
    );
}