import { Alert } from "@mui/material";
import { useEffect, useMemo, type ReactNode } from "react";
import {
  AuthProvider as OidcAuthProvider,
  useAuth,
  type AuthProviderProps,
} from "react-oidc-context";
import GlobalLoading from "./components/GlobalLoading";
import { localStoragePrefix, oidcClientId } from "./config";
import { useSystemInformation } from "./system-information";

const locationAfterSigninKey = `${localStoragePrefix}location-after-signin`;

export function AuthProvider({ children }: { children: ReactNode }) {
  const { oidcAuthority } = useSystemInformation();
  const oidcConfig: AuthProviderProps = useMemo(
    () => ({
      authority: oidcAuthority,
      client_id: oidcClientId,
      redirect_uri: `${window.origin}/`,
      // TODO: should we really use the scope `offline_access`?
      scope: "openid profile email offline_access",
      onSigninCallback: () => {
        const href =
          window.sessionStorage.getItem(locationAfterSigninKey) ?? "/";
        window.sessionStorage.removeItem(locationAfterSigninKey);
        window.history.replaceState({}, document.title, href);
      },
    }),
    [oidcAuthority]
  );

  return (
    <OidcAuthProvider {...oidcConfig}>
      <AuthBouncer>{children}</AuthBouncer>
    </OidcAuthProvider>
  );
}

/**
 * The bouncer redirects users to the sign-in page if they are not
 * authenticated, otherwise renders its children.
 */
function AuthBouncer({ children }: { children: ReactNode }) {
  const { isLoading, error, isAuthenticated } = useAuth();
  if (isLoading) return <GlobalLoading />;
  if (error) return <Alert severity="error">{error.message}</Alert>;
  if (!isAuthenticated) return <SignIn />;
  return <>{children}</>;
}

function SignIn() {
  // We don't have a dedicated sign-in page, instead we're using the
  // OAuth 2.0 Implicit Flow to sign in the user. This means we need to
  // redirect them to the authorization server.
  const { signinRedirect } = useAuth();

  useEffect(() => {
    // Store the current URL in session storage so we can redirect back after sign-in.
    window.sessionStorage.setItem(locationAfterSigninKey, window.location.href);
    signinRedirect();
  }, [signinRedirect]);

  return <GlobalLoading />;
}
