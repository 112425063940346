// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ApiError, type Fahrzeug } from "../../api";
import { useGetFahrzeug as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useFahrzeug(
  fahrzeugId: string,
  options: Omit<
    UseQueryOptions<Fahrzeug, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): Fahrzeug {
  const getter = useGetter();
  return useQuery(["fahrzeuge", fahrzeugId], () => getter(fahrzeugId), options)
    .data!;
}
