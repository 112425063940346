// Keep all configurable values in this file!

export const localStoragePrefix = "zeller-bip:";

export const apiUrl = import.meta.env.VITE_API_URL ?? "http://localhost:8080";
export const appUrl = import.meta.env.VITE_APP_URL ?? "http://localhost:5173";

export const version = import.meta.env.VITE_VERSION ?? "dev";
export const applicationVersion = import.meta.env.APPLICATION_VERSION ?? "dev";
export const applicationVersionTime = import.meta.env.APPLICATION_VERSION_TIME ?? new Date().toISOString();

export const oidcClientId =
  import.meta.env.VITE_OIDC_CLIENT_ID ?? "zeller-bip-app";

export const drawerWidth = 280;
