// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ApiError, type AusgangsrechnungInfo } from "../../api";
import { useGetAusgangsrechnung as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useAusgangsrechnung(
  ausgangsrechnungId: string,
  options: Omit<
    UseQueryOptions<AusgangsrechnungInfo, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): AusgangsrechnungInfo {
  const getter = useGetter();
  return useQuery(
    ["ausgangsrechnungen", ausgangsrechnungId],
    () => getter(ausgangsrechnungId),
    options
  ).data!;
}
