// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import {
  UpdateZugewieseneRechnungBaustelleDaten,
  type ZugewieseneRechnungBaustelle,
} from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateZugewieseneBaustelle(): (
  rechnungsId: string,
  zugewieseneBaustellenId: string,
  requestBody: UpdateZugewieseneRechnungBaustelleDaten
) => Promise<ZugewieseneRechnungBaustelle> {
  const getAPI = useGetApi();
  return useCallback(
    async (rechnungsId, zugewieseneBaustellenId, requestBody) =>
      (await getAPI()).rechnungen.updateZugewieseneBaustelle({
        rechnungsId,
        zugewieseneBaustellenId,
        requestBody,
      }),
    [getAPI]
  );
}
