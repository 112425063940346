import { Paper, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import { useT } from "../../i18n";
import AbladestellenDatatable from "../../components/abladestellen/AbladestellenDatatable";
import React from "react";

export default function AbladestellenListe() {
    const { t } = useT("customer");
    return (
        <Layout>
            <Paper
                elevation={1}
                sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
            >
                <Typography variant="h5">Abladestellen</Typography>
                <AbladestellenDatatable />
            </Paper>
        </Layout>
    );
}
