import {Form, Formik, FormikHelpers} from "formik";
import Layout from "../../components/Layout";
import {capitalize, Chip, DialogActions, DialogContent, Paper, Typography} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import {useNavigate, useParams} from "react-router";
import SubmitButton from "../../components/SubmitButton";
import {useTranslation} from "react-i18next";
import AbfallartenFieldsContent from "../../components/abfallarten/AbfallartenFieldsContent";
import {useAbfallart} from "../../client/queries";
import {useUpdateAbfallart} from "../../client/hooks";
import {useMutation, useQueryClient} from "react-query";
import {AbfallartDaten} from "../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteAbfallartButton from "../../components/abfallarten/DeleteAbfallartButton";

export default function DetailsAbfallart() {
    const { id: materialId = "" } = useParams<{
        id: string;
    }>();
    const material = useAbfallart(materialId)
    const { t } = useTranslation("customer");
    let schema = yup.object().shape({
        key: yup.string().required(),
        name: yup.string().required(),
    });
    const navigate = useNavigate();
    const {mutateAsync} = useSaveAbfallart(materialId);
    const onSubmit = useCallback(
        async (
            values: {
                key: string,
                name: string},
            formikHelpers: FormikHelpers<{ key:string, name: string }>
        ) => {
            const abfallart = await mutateAsync(values);
            const { setSubmitting, setStatus } = formikHelpers;
            navigate(`/abfallarten`);
            setStatus(undefined);
            try {
            } catch (error: any) {
                setStatus(error.message);
            } finally {
                setSubmitting(false);
            }
        },
        [navigate, mutateAsync]
    );
    return (
        <Layout title={"Abfallarten"} back="/abfallarten" >
            <Paper elevation={1} sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2, position: 'relative' }}>
                <DeleteAbfallartButton id={materialId}></DeleteAbfallartButton>
                <Formik
                    initialValues={{
                        key: material.key ?? "",
                        name: material.name ?? ""
                    }}
                    validationSchema={schema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, isValidating, isValid, dirty }) => (
                    <>
                        <Typography variant="h5" >{
                            material.geloeschtAm === undefined ? (
                                "Abfallart"
                            ) : (
                                <>
                                    <>Abfallart</>
                                    <Chip
                                        size="small"
                                        icon={<DeleteIcon />}
                                        label={"gelöscht"}
                                        color="error"
                                    />
                                </>
                            )}</Typography>
                        <DialogContent>
                            <Form id="details-material">
                                <AbfallartenFieldsContent></AbfallartenFieldsContent>
                            </Form>
                        </DialogContent>
                        <DialogActions>
                            <SubmitButton
                                form="details-material"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                loading={isSubmitting}
                                disabled={isValidating || !isValid || !dirty}
                            >
                                {capitalize(t("save"))}
                            </SubmitButton>
                        </DialogActions>
                    </>
                    )}
                </Formik>
            </Paper>
        </Layout>
    );
}

function useSaveAbfallart(id:string) {
    const updateAbfallart = useUpdateAbfallart();
    const queryClient = useQueryClient();

    return useMutation(
        (input:AbfallartDaten) => {
            return updateAbfallart(id,input);
        },
        {
            onSuccess: async () => {
                queryClient.invalidateQueries([
                    "abfallarten",id
                ]);
            },
        }
    );
}