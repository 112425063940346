import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import KundenFieldsContent from "../../components/kunden/KundenFieldsContent";
import { useCreateKunde } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { KundeDaten } from "../../api";

export default function ErstelleKunde() {
  const { t } = useTranslation("customer");
  const { mutateAsync } = useSaveKunde();
  let schema = yup.object().shape({
    name: yup.string().required(),
    namenszusatz: yup.string(),
    kundennummer: yup.string(),
    umsatzsteuerId: yup.string(),
    postfach: yup.string(),
    plzPostfach: yup.string(),
    plz: yup.string(),
    strasse: yup.string().required(),
    ort: yup.string().required(),
    telefon: yup.string(),
    fax: yup.string(),
    email: yup.string(),
    schriftAblage: yup.string(),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        name: string;
        namenszusatz: string;
        kundennummer: string;
        umsatzsteuerId: string;
        postfach: string;
        plzPostfach: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        fax: string;
        email: string;
        schriftAblage: string;
      },
      formikHelpers: FormikHelpers<{
        name: string;
        namenszusatz: string;
        kundennummer: string;
        umsatzsteuerId: string;
        postfach: string;
        plzPostfach: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        fax: string;
        email: string;
        schriftAblage: string;
      }>
    ) => {
      const { name, strasse, plz, telefon, email, ort } = values;
      const { setSubmitting, setStatus } = formikHelpers;
      const customer = await mutateAsync({
        name: name,
        strasse: strasse,
        plz: plz,
        telefon: telefon,
        email: email,
        ort: ort,
        namenszusatz: values.namenszusatz,
        kundennummer: values.kundennummer,
        umsatzsteuerId: values.umsatzsteuerId,
        postfach: values.postfach,
        plzPostfach: values.plzPostfach,
        fax: values.fax,
        schriftAblage: values.schriftAblage,
      });
      navigate(`/kunden`);
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={t("customer-creation-form")} back="/kunden">
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Formik
          initialValues={{
            name: "",
            namenszusatz: "",
            kundennummer: "",
            umsatzsteuerId: "",
            postfach: "",
            plzPostfach: "",
            plz: "",
            strasse: "",
            ort: "",
            telefon: "",
            fax: "",
            email: "",
            schriftAblage: "",
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty }) => (
            <>
              <Typography variant="h5">{t("new-customer")}</Typography>
              <DialogContent>
                <Form id="new-customer">
                  <KundenFieldsContent />
                </Form>
              </DialogContent>
              <DialogActions>
                <SubmitButton
                  form="new-customer"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Layout>
  );
}
function useSaveKunde() {
  const createKunde = useCreateKunde();
  const queryClient = useQueryClient();

  return useMutation(
    (input: KundeDaten) => {
      return createKunde(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["customers"]);
      },
    }
  );
}
