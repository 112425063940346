// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import {
  type FuhrparkeintragAnbauteil,
  FuhrparkeintragAnbauteilDaten,
} from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateFuhrparkeintragAnbauteil(): (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  fuhrparkeintragAnbauteilId: string,
  requestBody: FuhrparkeintragAnbauteilDaten
) => Promise<FuhrparkeintragAnbauteil> {
  const getAPI = useGetApi();
  return useCallback(
    async (
      taetigkeitsberichtId,
      fuhrparkeintragId,
      fuhrparkeintragAnbauteilId,
      requestBody
    ) =>
      (await getAPI()).fuhrparkeintraganbauteile.updateFuhrparkeintragAnbauteil(
        {
          taetigkeitsberichtId,
          fuhrparkeintragId,
          fuhrparkeintragAnbauteilId,
          requestBody,
        }
      ),
    [getAPI]
  );
}
