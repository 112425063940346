/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Ausgangsrechnung } from '../models/Ausgangsrechnung';
import type { AusgangsrechnungDaten } from '../models/AusgangsrechnungDaten';
import type { AusgangsrechnungenListe } from '../models/AusgangsrechnungenListe';
import type { AusgangsrechnungInfo } from '../models/AusgangsrechnungInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AusgangsrechnungenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Ausgangsrechungen.
   * @returns AusgangsrechnungenListe Die Seite der Ausgangsrechnungen.
   * @throws ApiError
   */
  public sucheAusgangsrechnungen({
    search,
    page,
    limit,
    sort,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<AusgangsrechnungenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ausgangsrechnungen',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Ausgangsrechnung Der angelegten Ausgangsrechnung
   * @throws ApiError
   */
  public createAusgangsrechnung({
    requestBody,
  }: {
    requestBody: AusgangsrechnungDaten,
  }): CancelablePromise<Ausgangsrechnung> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/ausgangsrechnungen',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns AusgangsrechnungInfo Die Ausgangsrechnung
   * @throws ApiError
   */
  public getAusgangsrechnung({
    ausgangsrechnungId,
  }: {
    ausgangsrechnungId: string,
  }): CancelablePromise<AusgangsrechnungInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ausgangsrechnungen/{ausgangsrechnungId}',
      path: {
        'ausgangsrechnungId': ausgangsrechnungId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Ausgangsrechnung Die aktualisierte Ausgangsrechnung
   * @throws ApiError
   */
  public updateAusgangsrechnung({
    ausgangsrechnungId,
    requestBody,
  }: {
    ausgangsrechnungId: string,
    requestBody: AusgangsrechnungDaten,
  }): CancelablePromise<Ausgangsrechnung> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/ausgangsrechnungen/{ausgangsrechnungId}',
      path: {
        'ausgangsrechnungId': ausgangsrechnungId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Ausgangsrechnung Die gelöschte Ausgangsrechnung
   * @throws ApiError
   */
  public deleteAusgangsrechnung({
    ausgangsrechnungId,
  }: {
    ausgangsrechnungId: string,
  }): CancelablePromise<Ausgangsrechnung> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/ausgangsrechnungen/{ausgangsrechnungId}',
      path: {
        'ausgangsrechnungId': ausgangsrechnungId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
