/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Me } from '../models/Me';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get information about the currently logged in user.
   * @returns Me The currently logged in user
   * @throws ApiError
   */
  public me(): CancelablePromise<Me> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/me',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

}
