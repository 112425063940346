// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Fahrzeug, FahrzeugDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useCreateFahrzeug(): (
  requestBody: FahrzeugDaten
) => Promise<Fahrzeug> {
  const getAPI = useGetApi();
  return useCallback(
    async (requestBody) =>
      (await getAPI()).fahrzeuge.createFahrzeug({ requestBody }),
    [getAPI]
  );
}
