import {
  Button,
  Paper,
  Typography,
  Box,
  Grid,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import Layout from "../../components/Layout";
import { useT } from "../../i18n";
import { useCallback, useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  useAssignBaustelleZuRechnung,
  useGetZugewieseneBaustellen,
  useUpdateRechnung,
  useUpdateZugewieseneBaustelle,
} from "../../client/hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { RechnungDaten, ZugewieseneRechnungBaustelle } from "../../api";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { Select, TextField } from "formik-mui";
import LieferantenSelect from "../../components/lieferanten/LieferantenSelect";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Yup from "yup";
import { useGetAccessToken } from "../../client";
import { apiUrl } from "../../config";
import { useNavigate, useParams } from "react-router";
import { useRechnung } from "../../client/queries";
import BaustellenEintrag from "../../components/eingangsrechnungen/BaustellenEintrag";
import SubmitButton from "../../components/SubmitButton";
import DeleteRechnungenButton from "../../components/rechnungen/DeleteRechnungenButton";
export default function RechnungsFormular() {
  const { t } = useT("eingangsrechnung");
  const { id: rechnungId = "" } = useParams<{
    id: string;
  }>(); // @ts-ignore
  const { isLoading, data, error } = useGetAnhang(rechnungId);
  const rechnung = useRechnung(rechnungId);
  const navigate = useNavigate();
  const [baustellenEintraege, setBaustellenEintraege] =
    useState<ZugewieseneRechnungBaustelle[]>();
  const fetchZugewieseneBaustellen = useGetZugewieseneBaustellen();
  const { mutateAsync } = useSaveRechnung(rechnungId);
  const createBaustellenEintrag = useAssignBaustelleZuRechnung();
  const updateBaustellenEintrag = useUpdateZugewieseneBaustelle();
  useEffect(() => {
    const fetchZugewiesene = async () => {
      const baustellenData = await fetchZugewieseneBaustellen(rechnungId, {
        search: "",
        page: 0,
        limit: 20,
      });
      console.log("Baustellen items:" + baustellenData.items.length);
      setBaustellenEintraege(baustellenData.items);
      // Update Formik's baustellenEintrag field
    };
    fetchZugewiesene();
  }, [rechnungId]);
  let schema = yup.object().shape({
    belegtyp: yup.string().required(),
    rechnungsnummer: yup.string(),
    rechnungsdatum: yup.string(),
    rechnungseingang: yup.string(),
    bruttobetrag: yup.string(),
    nettobetrag: yup.string(),
    umsatzsteuer: yup.string(),
    lieferantId: yup.string(),
    kundennummer: yup.string(),
    steuer: yup.number(),
    steuernummer: yup.string(),
    umsatzsteuerId: yup.string(),
    pruefStatus: yup.string(),
    iban: yup.string(),
    geschaefspartnerKonto: yup.string(),
    skonto1InProzent: yup.string(),
    skontoInEuro: yup.string(),
    faelligkeitMitSkonto: yup.string(),
    faelligkeitOhneSkonto: yup.string(),
    baustellenEintrag: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          rechnungsId: Yup.string(),
          baustellenId: Yup.string(),
          prueferId: Yup.string(),
          pruefStatus: Yup.string(),
          betrag: Yup.string(),
          leistung: Yup.string(),
        })
      )
      .min(0, ""),
  });
  interface ZugewieseneBaustelleUpdated {
    id: string;
    rechnungsId: string | undefined;
    baustellenId: string | undefined;
    prueferId: string | undefined;
    pruefStatus: string | undefined;
    betrag: string;
    leistung: string | undefined;
  }
  const onSubmit = useCallback(
    async (
      values: {
        belegtyp: string;
        rechnungsnummer: string;
        rechnungsdatum: string;
        rechnungseingang: string;
        bruttobetrag: string;
        nettobetrag: string;
        umsatzsteuer: string;
        lieferantId: string;
        kundennummer: string;
        steuer: number;
        steuernummer: string;
        umsatzsteuerId: string;
        pruefStatus: string;
        iban: string;
        geschaefspartnerKonto: string;
        skonto1InProzent: string;
        skontoInEuro: string;
        faelligkeitMitSkonto: string;
        faelligkeitOhneSkonto: string;
        baustellenEintrag: ZugewieseneBaustelleUpdated[];
      },
      formikHelpers: FormikHelpers<{
        belegtyp: string;
        rechnungsnummer: string;
        rechnungsdatum: string;
        rechnungseingang: string;
        bruttobetrag: string;
        nettobetrag: string;
        umsatzsteuer: string;
        lieferantId: string;
        kundennummer: string;
        steuer: number;
        steuernummer: string;
        umsatzsteuerId: string;
        pruefStatus: string;
        iban: string;
        geschaefspartnerKonto: string;
        skonto1InProzent: string;
        skontoInEuro: string;
        faelligkeitMitSkonto: string;
        faelligkeitOhneSkonto: string;
        baustellenEintrag: ZugewieseneBaustelleUpdated[];
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      const rechnung = await mutateAsync({
        belegTyp: values.belegtyp,
        rechnungsNummer: values.rechnungsnummer,
        rechnungsDatum: values.rechnungsdatum
          ? values.rechnungsdatum + "T00:00:00Z"
          : values.rechnungsdatum,
        bruttoBetrag: parseFloat(
          values.bruttobetrag.replace(".", "").replace(",", ".")
        ),
        nettoBetrag: parseFloat(
          values.nettobetrag.replace(".", "").replace(",", ".")
        ),
        lieferantId: values.lieferantId,
        kundenNummer: values.kundennummer,
        steuer: values.steuer,
        steuernummer: values.steuernummer,
        umsatzsteuerId: values.umsatzsteuerId,
        iban: values.iban,
        geschaeftspartnerKonto: values.geschaefspartnerKonto,
        skontoProzent: parseFloat(values.skonto1InProzent.replace(",", ".")),
        skontoDatum: values.faelligkeitMitSkonto
          ? values.faelligkeitMitSkonto + "T00:00:00Z"
          : values.faelligkeitMitSkonto,
        faelligOhneSkonto: values.faelligkeitOhneSkonto
          ? values.faelligkeitOhneSkonto + "T00:00:00Z"
          : values.faelligkeitOhneSkonto,
      });

      await updateBaustellenEintraege(values.baustellenEintrag);
      console.log("Update Rechnung");
      navigate("/eingangsrechnungen");
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    []
  );
  async function updateBaustellenEintraege(
    eintraege: ZugewieseneBaustelleUpdated[]
  ) {
    const baustellenPromises = eintraege.map((baustelle) => {
      const formattedBetrag = parseFloat(
        String(baustelle.betrag).replace(".", "").replace(",", ".")
      );
      const baustelleData = {
        baustellenId: baustelle.baustellenId,
        prueferId: baustelle.prueferId,
        betrag: formattedBetrag,
        pruefStatus: baustelle.pruefStatus,
        leistung: baustelle.leistung,
      };
      if (baustelle.id) {
        console.log("Update Baustelle Eintrag");
        return updateBaustellenEintrag(rechnungId, baustelle.id, baustelleData);
      } else {
        console.log("Create Baustelle Eintrag");
        return createBaustellenEintrag(rechnungId, baustelleData);
      }
    });
    await Promise.all(baustellenPromises);
  }
  const handleNewLieferantClick = () => {
    const currentHost = window.location.origin;
    // Open a new tab with the desired URL
    window.open(`${currentHost}/neu-lieferant`, "_blank");

    // Optionally, navigate within the current tab if needed
    // navigate('/somepath');
  };
  const formatNumber = (value: number | undefined) => {
    // If the value is null, undefined, or NaN, return an empty string
    if (value == null || isNaN(value)) return "";

    // Format the number with exactly two decimal places
    return value
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  // @ts-ignore
  return (
    <Layout title={t("audit")} back={"/eingangsrechnungen"}>
      <Box
        sx={{
          marginTop: 2,
          padding: 3,
          marginLeft: 2,
          marginRight: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 3, height: "100vh" }}>
              <Box
                sx={{
                  marginTop: 2,
                  height: "calc(100% - 48px)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  {rechnung.geloeschtAm === undefined ? (
                    t("preview")
                  ) : (
                    <>
                      <>{t("preview")}</>
                      <Chip
                        size="small"
                        icon={<DeleteIcon />}
                        label={t("deleted")}
                        color="error"
                      />
                    </>
                  )}
                </Typography>
                <Box sx={{ flex: 1, overflow: "auto" }}>
                  {data ? (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
                      <Viewer fileUrl={URL.createObjectURL(data)} />
                    </Worker>
                  ) : (
                    <Typography variant="body2" gutterBottom>
                      {t("no-pdf")}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Formik
              initialValues={{
                belegtyp: rechnung.belegTyp ?? "",
                rechnungsnummer: rechnung.rechnungsNummer ?? "",
                rechnungsdatum: rechnung.rechnungsDatum?.split("T")[0] ?? "",
                rechnungseingang: rechnung.timestamp?.split("T")[0] ?? "",
                bruttobetrag: formatNumber(rechnung.bruttoBetrag) ?? "",
                nettobetrag: formatNumber(rechnung.nettoBetrag) ?? "",
                lieferantId: rechnung.lieferantId ?? "",
                umsatzsteuer: formatNumber(
                  (rechnung.bruttoBetrag ?? 0) - (rechnung.nettoBetrag ?? 0)
                ),
                steuer: rechnung.steuer ?? 19,
                steuernummer: rechnung.steuernummer ?? "",
                umsatzsteuerId: rechnung.umsatzsteuerId ?? "",
                pruefStatus: rechnung.pruefStatus ?? "",
                iban: rechnung.iban ?? "",
                geschaefspartnerKonto: rechnung.geschaeftspartnerKonto ?? "",
                kundennummer: rechnung.kundenNummer ?? "",
                skonto1InProzent: formatNumber(rechnung.skonto1Prozent) ?? "",
                skontoInEuro:
                  formatNumber(
                    (rechnung.bruttoBetrag ?? 0) *
                      ((rechnung.skonto1Prozent ?? 0) / 100)
                  ) ?? "",
                faelligkeitMitSkonto:
                  rechnung.skonto1Datum?.split("T")[0] ?? "",
                faelligkeitOhneSkonto:
                  rechnung.faelligOhneSkonto?.split("T")[0] ?? "",
                baustellenEintrag: baustellenEintraege
                  ? baustellenEintraege.map((eintrag) => ({
                      id: eintrag.id,
                      rechnungsId: eintrag.rechnungsId,
                      baustellenId: eintrag.baustellenId,
                      prueferId: eintrag.prueferId,
                      betrag: formatNumber(eintrag.betrag),
                      pruefStatus: eintrag.pruefStatus,
                      leistung: eintrag.leistung,
                    }))
                  : ([
                      {
                        id: "",
                        rechnungsId: "",
                        baustellenId: "",
                        prueferId: "",
                        betrag: "",
                        pruefStatus: "offen",
                        leistung: "",
                      },
                    ] as ZugewieseneBaustelleUpdated[]),
              }}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, isValidating, isValid, dirty, values }) => (
                <>
                  <Form id="details-eingangsrechnung">
                    <Paper elevation={3} sx={{ padding: 3 }}>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>{t("invoice")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            sx={{
                              display: "grid",
                              gap: 2,
                              gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
                            }}
                          >
                            <Field
                              name="belegtyp"
                              component={Select}
                              label={t("document-type") + " *"}
                            >
                              <MenuItem
                                key="rechnungseingang"
                                value="Eingangsrechnung"
                              >
                                {t("invoice")}
                              </MenuItem>
                              <MenuItem key="Gutschrift" value="Gutschrift">
                                {t("credit")}
                              </MenuItem>
                              <MenuItem key="Abbuchung" value="Abbuchung">
                                {t("debit")}
                              </MenuItem>
                            </Field>
                            <Field
                              component={TextField}
                              name="rechnungsnummer"
                              label={t("invoice-number")}
                            ></Field>
                            <Field
                              component={TextField}
                              label={t("invoice-date")}
                              name="rechnungsdatum"
                              InputLabelProps={{ shrink: true }}
                              type={"date"}
                            />
                            <Field
                              component={TextField}
                              label={t("invoice-entry")}
                              name={"rechnungseingang"}
                              type={"date"}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                          <Box
                            sx={{
                              mt: 2,
                              display: "grid",
                              gap: 2,
                              gridTemplateColumns: ["1fr", "2fr 1fr 1fr"],
                              alignItems: "center",
                            }}
                          >
                            <Field
                              component={LieferantenSelect}
                              name="lieferantId"
                              label="Lieferant"
                            ></Field>
                            <Button
                              variant={"outlined"}
                              sx={{ height: "70%" }}
                              onClick={handleNewLieferantClick}
                            >
                              <AddIcon />
                              {t("new-supplier")}
                            </Button>
                          </Box>
                          <Box
                            sx={{
                              mt: 2,
                              display: "grid",
                              gap: 2,
                              gridTemplateColumns: ["1fr", "2fr 1fr 1fr "],
                              alignItems: "center",
                            }}
                          >
                            <Field
                              component={TextField}
                              name="iban"
                              label={t("iban")}
                            ></Field>
                            <Field
                              component={TextField}
                              name="steuernummer"
                              label={t("tax-number")}
                            ></Field>
                            <Field
                              component={TextField}
                              name="umsatzsteuerId"
                              label={t("sales-tax-id")}
                            ></Field>
                            <Field
                              component={TextField}
                              name="kundennummer"
                              label={t("supplier-customer-number")}
                            ></Field>
                            <Field
                              component={TextField}
                              name="geschaefspartnerKonto"
                              label={t("business-partner-account")}
                            ></Field>
                          </Box>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>{t("invoice-amount")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            sx={{
                              display: "grid",
                              gap: 2,
                              gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
                            }}
                          >
                            <Field
                              component={TextField}
                              name="nettobetrag"
                              label={t("net-amount-euro")}
                            ></Field>
                            <Field
                              component={TextField}
                              name="steuer"
                              label={t("tax-rate")}
                            ></Field>
                            <Field
                              component={TextField}
                              name="umsatzsteuer"
                              label={t("sales-tax")}
                            ></Field>
                            <Field
                              component={TextField}
                              name="bruttobetrag"
                              label={t("gross-amount-euro")}
                            ></Field>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {t("construction-site-assignment")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <BaustellenEintrag
                            baustellenEintraege={values.baustellenEintrag}
                            rechnungId={rechnungId}
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>Rabatte</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            sx={{
                              display: "grid",
                              gap: 2,
                              gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
                            }}
                          >
                            <Field
                              component={TextField}
                              name="skonto1InProzent"
                              label={"Skonto in %"}
                            ></Field>
                            <Field
                              component={TextField}
                              name="skontoInEuro"
                              label={"Skonto in €"}
                            ></Field>
                            <Field
                              component={TextField}
                              name="faelligkeitMitSkonto"
                              label={"Fälligkeit mit Skonto"}
                              type={"date"}
                              InputLabelProps={{ shrink: true }}
                            ></Field>
                            <Field
                              component={TextField}
                              name="faelligkeitOhneSkonto"
                              label={"Fälligkeit ohne Skonto"}
                              type={"date"}
                              InputLabelProps={{ shrink: true }}
                            ></Field>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end", // Aligns button to the right
                          mt: 2, // Adds some margin to the top to separate it from the fields above
                        }}
                      >
                        <DeleteRechnungenButton id={rechnungId} />
                        <SubmitButton
                          sx={{ marginTop: 3, ml: 2 }}
                          form="details-eingangsrechnung"
                          type="submit"
                          variant="outlined"
                          color="secondary"
                          loading={isSubmitting}
                          disabled={isValidating || !isValid || !dirty}
                        >
                          {t("save")}
                        </SubmitButton>
                        {values.pruefStatus === "OFFEN" ? (
                          <SubmitButton
                            sx={{ marginTop: 3, ml: 2 }}
                            form="details-eingangsrechnung"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            loading={isSubmitting}
                            disabled={isValidating || !isValid || !dirty}
                            onClick={async () => {
                              values.baustellenEintrag.forEach(
                                (baustelle: ZugewieseneBaustelleUpdated) => {
                                  if (baustelle.pruefStatus === "offen") {
                                    if (baustelle.id) {
                                      baustelle.pruefStatus = "in Prüfung";
                                      const formattedBetrag = parseFloat(
                                        String(baustelle.betrag)
                                          .replace(".", "")
                                          .replace(",", ".")
                                      );
                                      updateBaustellenEintrag(
                                        rechnungId,
                                        baustelle.id,
                                        {
                                          baustellenId: baustelle.baustellenId,
                                          prueferId: baustelle.prueferId,
                                          leistung: baustelle.leistung,
                                          betrag: formattedBetrag,
                                          pruefStatus: "in Prüfung",
                                        }
                                      );
                                    } else {
                                      createBaustellenEintrag(rechnungId, {
                                        baustellenId: baustelle.baustellenId,
                                        prueferId: baustelle.prueferId,
                                        leistung: baustelle.leistung,
                                        betrag: parseFloat(
                                          String(baustelle.betrag)
                                            .replace(".", "")
                                            .replace(",", ".")
                                        ),
                                        pruefStatus: "in Prüfung",
                                      });
                                    }
                                  }
                                }
                              );
                              navigate("/eingangsrechnungen");
                            }}
                          >
                            {t("assign")}
                          </SubmitButton>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Paper>
                  </Form>
                </>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}

function useSaveRechnung(rechnungId: string) {
  const updateRechnung = useUpdateRechnung();
  const queryClient = useQueryClient();
  return useMutation(
    (input: RechnungDaten) => {
      return updateRechnung(rechnungId, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["rechnungen"]);
      },
    }
  );
}

function useGetAnhang(rechnungId: string, options: { enabled?: boolean } = {}) {
  const getAccessToken = useGetAccessToken();
  return useQuery(
    ["attachments", rechnungId],
    async () => {
      const response = await fetch(
        `${apiUrl}/rechnungen/${rechnungId}/anhang`,
        { headers: { authorization: `Bearer ${await getAccessToken()}` } }
      );
      const blob = await response.blob();
      return blob;
    },
    { ...options, suspense: false, cacheTime: 0 }
  );
}
