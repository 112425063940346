import {Box, Divider, Stack, Typography} from "@mui/material";
import {Field} from "formik";
import {TextField} from "formik-mui";
import {useT} from "../../i18n";

export default function BenutzerFieldsContent(){
    const { t } = useT("customer");
    return (<Stack direction="column">
        <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2}}>
            <Field
                component={TextField}
                label="PersonalNr."
                name="personalNummer"
            />
            <Field
                component={TextField}
                label="Anrede"
                name="anrede"
            />
        </Box>
        <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2,marginTop: 2}}>
            <Field
                component={TextField}
                label="Nachname *"
                name="name"
            />
            <Field
                component={TextField}
                label="Vorname"
                name="vorname"
            />

        </Box>
        <Typography sx={{paddingTop: 2}}>{t("address")}</Typography>
        <Divider sx={{marginBottom:2}} />
        <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2}}>
            <Field
                component={TextField}
                label={t("street")}
                name="strasse"
            />
        </Box>
        <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, marginTop: 2}}>
            <Field
                component={TextField}
                label={t("plz")}
                name="plz"
            />
            <Field
                component={TextField}
                label={t("city")}
                name="ort"
            />
        </Box>
        <Typography sx={{paddingTop: 2}}>{t("communication")}</Typography>
        <Divider sx={{marginBottom:2}} />
        <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2}}>
            <Field
                component={TextField}
                label={t("phone")}
                name="telefon"
            />
            <Field
                component={TextField}
                label={t("email") + " *"}
                name="email"
            />
        </Box>
        <Typography sx={{paddingTop: 2}}>Daten</Typography>
        <Divider sx={{marginBottom:2}} />
        <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2}}>
            <Field
                component={TextField}
                label={"Geburtsdatum"}
                name="geburtsDatum"
                type="date"
                InputLabelProps={{ shrink: true }}
            />
            <Field
                component={TextField}
                label={"Wochenarbeitszeit"}
                name="wochenArbeitszeit"
            />
            <Field
                component={TextField}
                label={"Pauschalverguetung in EUR"}
                name="pauschalVerguetung"
            />
        </Box>
        <Box display={"grid"} sx={{gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, marginTop: 2}}>
            <Field
                component={TextField}
                label={"Eintrittsdatum"}
                name="eintrittsDatum"
                type="date"
                InputLabelProps={{ shrink: true }}
            />
            <Field
                component={TextField}
                label={"Berechnungssatz in EUR"}
                name="berechnungsSatz"
            />
            <Field
                component={TextField}
                label={"Stundenlohn in EUR"}
                name="stundenLohn"
            />

        </Box>
    </Stack>)
}