// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type FahrzeugKosten, Timestamp } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useGetFahrzeugKostenFuerDatum(): (
  fahrzeugId: string,
  requestBody: { date: Timestamp }
) => Promise<FahrzeugKosten> {
  const getAPI = useGetApi();
  return useCallback(
    async (fahrzeugId, requestBody) =>
      (await getAPI()).fahrzeuge.getFahrzeugKostenFuerDatum({
        fahrzeugId,
        requestBody,
      }),
    [getAPI]
  );
}
