import {capitalize, Paper, Typography, SxProps, Theme} from "@mui/material";
import {useT} from "../../i18n";
import TaetigkeitsberichtDataTable from "../../components/taetigkeitsberichte/TaetigkeitsberichtDataTable";
import Layout from "../../components/Layout";

export default function Taetigkeitsberichte() {
    const {t } = useT("taetigkeitsbericht");
    return (
        <Layout>
            <Paper elevation={1} sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}>
                <Typography variant="h5" >{capitalize(t("activity-reports"))}</Typography>
                <TaetigkeitsberichtDataTable />
            </Paper>
        </Layout>
    );
}