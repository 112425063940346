import ErrorAlert from "./ErrorAlert";
import Global from "./Global";

export default function GlobalError({ error }: { error: any }) {
  return (
    <Global>
      <ErrorAlert error={error} />
    </Global>
  );
}
