// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ZugewiesenesFahrzeugAnbauteilPage } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type SucheZugewieseneFahrzeugAnbauteileQuery = {
  search?: string;
  page?: number;
  limit?: number;
  sort?: string;
};

export function useSucheZugewieseneFahrzeugAnbauteile(): (
  fahrzeugId: string,
  query: SucheZugewieseneFahrzeugAnbauteileQuery
) => Promise<ZugewiesenesFahrzeugAnbauteilPage> {
  const getAPI = useGetApi();
  return useCallback(
    async (fahrzeugId, query = {}) =>
      (await getAPI()).fahrzeuge.sucheZugewieseneFahrzeugAnbauteile({
        fahrzeugId,
        ...query,
      }),
    [getAPI]
  );
}
