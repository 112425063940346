import { Box, Stack } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../i18n";

export default function ErzeugerFieldsContent() {
  const { t } = useT("customer");
  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
      >
        <Field
          component={TextField}
          label={t("date") + " *"}
          name="datum"
          type="date"
        />
        <Field component={TextField} label={t("number") + " *"} name="nummer" />
        <Field component={TextField} label={t("name") + " *"} name="name" />
      </Box>
    </Stack>
  );
}
