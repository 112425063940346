// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Me } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useMe(): () => Promise<Me> {
  const getAPI = useGetApi();
  return useCallback(async () => (await getAPI()).auth.me(), [getAPI]);
}
