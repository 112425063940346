// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Translations } from "../../api";
import { useGetUnauthenticatedAPI } from "../client";
import { useCallback } from "react";

export function useGetTranslations(): (
  language: string,
  namespace: string
) => Promise<Translations> {
  const getAPI = useGetUnauthenticatedAPI();
  return useCallback(
    async (language, namespace) =>
      (await getAPI()).system.getTranslations({ language, namespace }),
    [getAPI]
  );
}
