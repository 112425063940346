import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import type { DataRequestState, Filter } from "./types";

export type SetFieldFn<TFilter extends Filter> = <TKey extends keyof TFilter>(
  key: TKey,
  value: TFilter[TKey]
) => void;

export type Options = {
  debounce?: number;
};

export function useDebouncedFilter<TFilter extends Filter>(
  { filter, setFilter }: DataRequestState<TFilter>,
  { debounce }: Options = {}
): [TFilter, SetFieldFn<TFilter>] {
  const [state, setState] = useState<TFilter>(filter);

  const setField: SetFieldFn<TFilter> = useCallback((key, value) => {
    setState((f) => ({ ...f, [key]: value }));
  }, []);

  // When the state changes, update the filter with a delay.
  const setFilterDebounced = useDebouncedCallback(setFilter, debounce ?? 300);
  useEffect(() => {
    setFilterDebounced(state);
  }, [state, setFilterDebounced]);

  return [state, setField];
}
