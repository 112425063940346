// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import {
  type UmsatzmengenAuswertung,
  UmsatzmengenAuswertungRequest,
} from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useGetUmsatzMengenAuswertungByDate(): (
  baustellenId: string,
  richtung: string,
  requestBody: UmsatzmengenAuswertungRequest
) => Promise<UmsatzmengenAuswertung> {
  const getAPI = useGetApi();
  return useCallback(
    async (baustellenId, richtung, requestBody) =>
      (await getAPI()).umsatz.getUmsatzMengenAuswertungByDate({
        baustellenId,
        richtung,
        requestBody,
      }),
    [getAPI]
  );
}
