import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { localStoragePrefix } from "./config";

/**
 * The expand state of a set of items. The main purpose is to store the state of
 * multiple expandable items such as an accordion or menu items.
 */
export type ExpandContext = {
  expanded: Record<string, boolean>;
  setExpanded: (item: string, expanded: boolean) => void;
};

const Context = createContext<ExpandContext | undefined>(undefined);

export type Props = PropsWithChildren<{
  /** A globally unique ID for this set of elements. */
  id: string;
  /** The initial state. If empty, all items are initially collapsed. */
  initial?: Array<string>;
  /** Whether the state should be persisted to local storage so it remains after a page reload. */
  store?: boolean;
}>;

export function ExpandContextProvider({
  children,
  id,
  initial,
  store = false,
}: Props) {
  const [expanded, setExpandedInternal] = useState<Record<string, boolean>>(
    store ? load(id) : {}
  );

  const storeState = useCallback(
    (state: Record<string, boolean>): Record<string, boolean> => {
      if (store) {
        window.localStorage.setItem(
          `${localStoragePrefix}expand:${id}`,
          JSON.stringify(state)
        );
      }
      return state;
    },
    [store, id]
  );

  const setExpanded = useCallback(
    (item: string, expanded: boolean) =>
      setExpandedInternal((current) =>
        storeState({ ...current, [item]: expanded })
      ),
    [storeState]
  );

  useEffect(() => {
    if (initial) {
      const state = Object.fromEntries(initial.map((s) => [s, true]));
      setExpandedInternal(state);
      storeState(state);
    }
  }, [initial, storeState]);

  const context: ExpandContext = { expanded, setExpanded };

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

function load(id: string): Record<string, boolean> {
  const stored = window.localStorage.getItem(
    `${localStoragePrefix}expand:${id}`
  );
  return stored ? JSON.parse(stored) : {};
}

export function useExpandContext(): ExpandContext {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(
      "useExpandContext must be used within a ExpandContextProvider"
    );
  }
  return context;
}

export type Expand = {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  toggleExpanded: () => void;
};

/** Get the expand state for a single item. */
export function useExpand(item: string): Expand {
  const { expanded, setExpanded } = useExpandContext();
  return {
    expanded: expanded[item] ?? false,
    setExpanded: (ex) => setExpanded(item, ex),
    toggleExpanded: () => setExpanded(item, !expanded[item]),
  };
}
