// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Ausgangsrechnung, AusgangsrechnungDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateAusgangsrechnung(): (
  ausgangsrechnungId: string,
  requestBody: AusgangsrechnungDaten
) => Promise<Ausgangsrechnung> {
  const getAPI = useGetApi();
  return useCallback(
    async (ausgangsrechnungId, requestBody) =>
      (await getAPI()).ausgangsrechnungen.updateAusgangsrechnung({
        ausgangsrechnungId,
        requestBody,
      }),
    [getAPI]
  );
}
