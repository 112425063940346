import { useCallback } from "react";
import { useTranslation as useI18nTranslation } from "react-i18next";

export type Verb =
  | "add"
  | "assign"
  | "create"
  | "delete"
  | "edit"
  | "no"
  | "remove";

/**
 * Render a label for an action on a type of object, eg. "create user"
 * or "delete item". The parameter `type` will be internationalized itself.
 * Note that the returned string is not capitalized.
 */
export type DoIt = (verb: Verb, type: string) => string;

/**
 * Verbs for which the target should be translated in plural form,
 * eg. "no users".
 */
const pluralVerbs: Array<Verb> = ["no"];

export function useDoIt(): DoIt {
  const { t } = useI18nTranslation();

  return useCallback(
    (verb, type) =>
      t(`${verb}-it`, {
        type: t(type, { count: pluralVerbs.includes(verb) ? 2 : 1 }),
      }),
    [t]
  );
}
