// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Material, MaterialDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useCreateMaterial(): (
  requestBody: MaterialDaten
) => Promise<Material> {
  const getAPI = useGetApi();
  return useCallback(
    async (requestBody) =>
      (await getAPI()).material.createMaterial({ requestBody }),
    [getAPI]
  );
}
