import { Logout, Settings } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useCapitalize } from "../i18n";
import { useUser } from "../user";
import AccessTokenButton from "./AccessTokenButton";
import LanguageSwitcher from "./LanguageSwitcher";
import LinkBehavior from "./LinkBehavior";
import UserAvatar from "./UserAvatar";

export default function NavUser() {
  const { t } = useTranslation("user");
  const capitalize = useCapitalize();
  const me = useUser();
  const popupState = usePopupState({ variant: "popover", popupId: "user" });
  const { signoutRedirect } = useAuth();

  return (
    <>
      <IconButton {...bindTrigger(popupState)} edge="end" size="small">
        <UserAvatar user={me} />
      </IconButton>
      <Popover
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        {...bindPopover(popupState)}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ pt: 2, pl: 2 }}>
            <UserAvatar user={me} size={58} />
          </Box>
          <List>
            <ListItem>
              <ListItemText
                primary={me.name ?? me.email ?? me.id}
                secondary={me.name ? me.email ?? me.id : me.id}
                primaryTypographyProps={{ fontWeight: 700 }}
              />
            </ListItem>
          </List>
        </Box>
        <Divider />
        <List dense>
          <ListItemButton component={LinkBehavior} href="/user/settings">
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary={capitalize(t("settings"))} />
          </ListItemButton>
          {import.meta.env.DEV && <AccessTokenButton />}
          <ListItemButton
            onClick={() =>
              signoutRedirect({ post_logout_redirect_uri: window.origin })
            }
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary={capitalize(t("sign-out"))} />
          </ListItemButton>
        </List>
        <Divider />
        <LanguageSwitcher />
      </Popover>
    </>
  );
}
