import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ApiError, type Error } from "../api";
import { InternationalizableError } from "../errors";

export default function ErrorAlert(props: { error: any }) {
  return (
    <Alert severity="error">
      <ErrorMessage {...props} />
    </Alert>
  );
}

function ErrorMessage({ error }: { error: any }) {
  const { t } = useTranslation("errors");

  if (error instanceof ApiError) {
    const e = error.body as Error;
    if (e.code) return <>{t(e.code)}</>;
  }

  if (error instanceof InternationalizableError) {
    const e = error as InternationalizableError;
    if (e.code) return <>{t(e.code)}</>;
  }

  return <>{error.message}</>;
}
