import { createContext, useContext, type ReactNode } from "react";
import type { SystemInformation } from "./api";
import { useSystemInformation as useSystemInformationHook } from "./client/queries";

const Context = createContext<SystemInformation | undefined>(undefined);

export function SystemInformationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const value = useSystemInformationHook({ staleTime: 5 * 60 * 1000 });
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useSystemInformation(): SystemInformation {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      "useSystemInformation must be used within a SystemInformationProvider"
    );
  }
  return context;
}
