import "@fontsource/roboto";
import {
  createTheme,
  CssBaseline,
  GlobalStyles,
  type PaletteColorOptions,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import {orange, red, yellow} from "@mui/material/colors";
import { deDE, enUS, type Localization } from "@mui/material/locale";
import { type ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";

/** The secondary color for all themes is always the same. */
const secondary =  {
        light: '#ff7961',
        main: '#c7333d',
        dark: '#ff7961',
        contrastText: '#FFFFFF',
};

const localizations: Record<string, Localization> = {
  de: deDE,
  en: enUS,
};

function useLocalization() {
  const { i18n } = useTranslation();
  return localizations[i18n.language];
}

function useDefineTheme(primary: PaletteColorOptions) {
  const localization = useLocalization();

  return useMemo(
    () =>
      responsiveFontSizes(
        createTheme(
          {
            palette: {
              primary,
              secondary,
              background: {
                default: "#ffffff",
              },
            },
          },
          localization
        )
      ),
    [primary, localization]
  );
}

/** The root theme provider that applies the default theme and CSS reset. */
export function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <ThemeWrapper primary={red}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          "#root": {
            minHeight: "100vh",
            overflow: "scroll-y",
            display: "flex",
            flexDirection: "column",
          },
        }}
      />
      {children}
    </ThemeWrapper>
  );
}

/** With this wrapper you can apply another primary color to any part of the application. */
export function ThemeWrapper({
  children,
  primary,
}: {
  children?: ReactNode;
  primary: PaletteColorOptions;
}) {
  const theme = useDefineTheme(primary);

  return (
    <MuiThemeProvider theme={theme}>{children ?? <Outlet />}</MuiThemeProvider>
  );
}
